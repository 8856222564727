import './App.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import '@progress/kendo-theme-default/dist/all.css';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import {  AuthProvider } from './context/AuthContext';
import { FetchProvider } from './context/FetchContext';
import Privateroute from './Privateroute';




function App() {

  return (
    <>
   
     <AuthProvider>
      <FetchProvider>
      <Toaster toastOptions={{ duration: 2000 }} />
     <Privateroute/> 
    </FetchProvider>
    </AuthProvider>
    </>
        
  );
}

export default App;
