import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CButton, CDropdownToggle } from "@coreui/react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import "./accordianradio.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, Checkbox, Grid } from "@mui/material";
import InputComponent from "../inputfiled/Input_type_text";
import DeleteIcon from "@mui/icons-material/Delete";
import Selectproduct from "./Selectproduct";
import { useContext } from "react";
import { FetchContext } from "../../context/FetchContext";
import { useState } from "react";
import { useEffect } from "react";
import LeadtoQuestion from "./LeadtoQuestion";
import SelectproductSingle from "../common/selectproduct/SelectProductSingle";
import SelectResultWeight from "../common/selectproduct/SelectResultWeight";

export default function AccordianRanger({
  setimgansdata,
  imgansdata,
  validation,
  setIsSaveDisable,
  ProductData,
  resultLogic,
  loadingScrollableApi,
  hasMore,
  getProduct,
  allquestion,
  imgquedata,
  allQuelist,
  resultData
}) {
  const [expanded, setExpanded] = React.useState(false);
  const [clickurl, setclickurl] = React.useState([]);
  const handleClick = (event, index) => {
    let newFormValues = [...imgansdata];

    const _clicked = [...clickurl];
    _clicked[index] = event.target.checked;
    setclickurl(_clicked);
    setIsSaveDisable(false);
    if (event.target.checked) {
      newFormValues[index].url_valid = true;
    } else {
      newFormValues[index].url_valid = false;
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const validateURL = (input) => {
    if (input) {
      const urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-zA-Z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-zA-Z\\d_]*)?$",
        "i" // fragment locator
      );
      let url = urlPattern.test(input);
      return urlPattern.test(input);
    } else {
      return true;
    }
  };
  let handleInputChange = (i, e) => {
    setIsSaveDisable(false);
    let newFormValues = [...imgansdata];
    if (e.target.name === "title" && e.target.value === "") {
      newFormValues[i].valid = true;
    } else if (e.target.name === "product_point" && e.target.value < 0) {
      newFormValues[i].valid = true;
    } else {
      newFormValues[i].valid = false;
    }
    if (clickurl.length === i || clickurl.length === 0) {
      newFormValues[i].url_valid = false;
    }
    newFormValues[i][e.target.name] = e.target.value;
    setimgansdata(newFormValues);
    setIsSaveDisable(false);
  };

  let handleUrlChange = (i, e) => {
    let newFormValues = [...imgansdata];

    if (!validateURL(e.target.value) || e.target.value === "") {
      newFormValues[i].url_valid = true;
    } else {
      newFormValues[i].url_valid = false;
      if (newFormValues[i].title === "") {
        newFormValues[i].valid = true;
      }
    }
    newFormValues[i][e.target.name] = e.target.value;

    setimgansdata(newFormValues);
    setIsSaveDisable(false);
  };

  let addFormFields = () => {
    setIsSaveDisable(false);
    setimgansdata([
      ...imgansdata,
      {
        title: "",
        select_products: [],
        product_point: "",
        leads_to_id: "",
        custom_redirect_url: false,
        url: "",
        valid: true,
        url_valid: true,
      },
    ]);
  };

  let removeFormFields = (i) => {
    setIsSaveDisable(false);
    let newFormValues = [...imgansdata];
    newFormValues.splice(i, 1);
    setimgansdata(newFormValues);
  };

  let handleSubmit = (event) => {
    setIsSaveDisable(false);
    event.preventDefault();
    alert(JSON.stringify(imgansdata));
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        {imgansdata.map((element, index) => (
          <Accordion
            className="acordian_answer"
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Grid className="d-flex col-12 ">
                <Grid item className="col-4">
                  <DragIndicatorIcon />
                </Grid>
                <Grid
                  item
                  className="col-4 radio_answer_option_label text-center"
                >
                  {element?.answer?.title
                    ? element?.answer?.title
                    : element.title
                      ? element.title
                      : "option"}
                </Grid>
                <Grid item className="text-end col-4 down_arrow"></Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <div className="mx-2" style={{ marginTop: "-10px" }}>
                <InputComponent
                  label_name={"Title"}
                  name={"title"}
                  onChange={(e) => handleInputChange(index, e)}
                />
              </div>
              {element.valid || validation ? (
                <p className="text-[red] mx-2 mt-[-8px] text-sm">
                  {" "}
                  Title can't be blank{" "}
                </p>
              ) : (
                ""
              )}
              {allquestion.quiz?.logic_jump &&
                !imgquedata.multiselect_answer && (
                  <div className="mt-4">
                    <LeadtoQuestion
                      imgquedata={imgquedata}
                      element={element}
                      index={index}
                      allQuelist={allQuelist}
                      setIsSaveDisable={setIsSaveDisable}
                      imgansdata={imgansdata}
                      setimgansdata={setimgansdata}
                    />
                  </div>
                )}
              {resultLogic === "Points" && (
                <div>
                  <InputComponent
                    placeholder={"Enter Point"}
                    value={element.product_point || ""}
                    label_name={"Product Point"}
                    name={"product_point"}
                    min={"0"}
                    onChange={(e) => {
                      handleInputChange(index, e);
                    }}
                    type="number"
                  />
                </div>
              )}
              {resultLogic === "Single" && (
                <div className="select_product_componant mt-4">
                  <SelectproductSingle
                    setimgansdata={setimgansdata}
                    imgansdata={imgansdata}
                    ProductData={ProductData}
                    element={element}
                    index={index}
                    setIsSaveDisable={setIsSaveDisable}
                    loadingScrollableApi={loadingScrollableApi}
                    hasMore={hasMore}
                    getProduct={getProduct}
                    resultLogic={resultLogic}
                  />
                </div>
              )}
              {resultLogic === "Product Weight" && (
                <div className="select_product_componant mt-4">
                  <Selectproduct
                    setimgansdata={setimgansdata}
                    imgansdata={imgansdata}
                    ProductData={ProductData}
                    element={element}
                    index={index}
                    setIsSaveDisable={setIsSaveDisable}
                    loadingScrollableApi={loadingScrollableApi}
                    hasMore={hasMore}
                    getProduct={getProduct}
                  />
                </div>
              )}

              {resultLogic === "Result Weight" && (
                <div className="select_product_componant mt-4">
                  <SelectResultWeight
                    setimgansdata={setimgansdata}
                    imgansdata={imgansdata}
                    resultData={resultData}
                    element={element}
                    index={index}
                    setIsSaveDisable={setIsSaveDisable}
                    loadingScrollableApi={loadingScrollableApi}
                    hasMore={hasMore}
                  />
                </div>
              )}

              <div className="d-flex mt-4">
                <Checkbox
                  name="custom_redirect_url"
                  className="p-0"
                  onClick={(e) => handleClick(e, index)}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  onChange={(e, val) => {
                    let newFormValues = [...imgansdata];
                    newFormValues[index][e.target.name] = val;
                    setimgansdata(newFormValues);
                  }}
                ></Checkbox>
                <Typography
                  color="text.secondary"
                  className="mt-2 mx-2 logic_jump"
                >
                  Custom redirect URL
                </Typography>
              </div>
              <div className="mx-6 ml-8 opacity-70">
                <Typography
                  color="text.secondary"
                  className="mt-1"
                  style={{ letterSpacing: "1px" }}
                >
                  if a user chooses this option,skip the <br /> Results page and
                  instead send them to <br /> custom URL
                </Typography>
              </div>
              <div
                className={
                  (clickurl.length > index || !clickurl[index] === "") &&
                    clickurl[index] === true
                    ? "custom_url_clicked"
                    : "mx-2 custom_url"
                }
                style={{ marginTop: "-15px" }}
              >
                <InputComponent
                  placeholder={"URL"}
                  name={"url"}
                  onChange={(e) => handleUrlChange(index, e)}
                />
                {element.url_valid || validation ? (
                  <p className="text-[red] mx-2 mt-[-8px] text-sm">
                    {" "}
                    please enter valid url{" "}
                  </p>
                ) : (
                  ""
                )}
              </div>
              {imgansdata.length > 1 && (
                <div className="col-12 main_optdelete  text-end">
                  <Typography sx={{ fontSize: 14 }} gutterBottom>
                    <Button
                      className="delete_option_btn"
                      size="lg"
                      onClick={() => removeFormFields(index)}
                    >
                      {" "}
                      <DeleteIcon className="" />
                      Delete option
                    </Button>
                  </Typography>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
        <CButton
          size="lg"
          className="button_component w-100 "
          style={{ marginTop: "30px" }}
          onClick={() => addFormFields()}
          autoFocus
        >
          Add option
        </CButton>
      </form>
    </div>
  );
}
