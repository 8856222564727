import React, { createContext, useContext} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { AuthContext } from './AuthContext';
import { toast } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';



const FetchContext = createContext();
const { Provider } = FetchContext;

function FetchProvider({ children }) {
  const { state,dispatch } = useContext(AuthContext);


  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  });

  authAxios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${state.token}`;
      config.headers.shop = state.loginadmin.shop;
      config.headers.shop_id = state.loginadmin.shop_id;
      // config.headers['x-api-key'] = process.env.REACT_APP_X_API_KEY;
      return config;
    },
    (error) => Promise.reject(error)
  );

  authAxios.interceptors.response.use(
    (response) =>
      {
      const message = response.data?.message?.message;
      if (response?.data?.message === "Access Denied" ||message === "jwt expired" || message === "invalid token"||message === "jwt malformed"||response.data?.message?.name === "JsonWebTokenError") {
        toast.error(message === "jwt expired"?"Token expired":"Invaild Token");
        dispatch({ type: "LOGOUT" });
      }
      return response;
    },
    (error) => {
      const code = error && error.response ? error.response.status : 0;
      console.log("error===>",code);
      if (code === 401 || code === 403) {
        console.log('error code', code);
      }
      return Promise.reject(error);
    }
  );

  return (
    <Provider
      value={{
        authAxios
      }}
    >
      {children}
    </Provider>
  );
}

export { FetchContext, FetchProvider };

FetchProvider.propTypes = {
  children: PropTypes.any
};
