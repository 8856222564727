import produce from 'immer';
 
import { LOGIN, ALL_QUIZ, QUESTION,LOGOUT} from '../constatnts';

let app = localStorage.getItem("QUIZ-APP");
app = JSON.parse(app);

export const initialState = {
  token: app?.token ?? '',
  message: '',
  success: app?.statusCode,
  loginadmin: app?.loginadmin,
  isAuthenticated: app?.isAuthenticated ?? false,
  allquiz:app?.allquiz ?? [],
  question:app?.question ?? []
};


export const AuthReducer =  (state = initialState, action = {}) => 
produce(state, (draft) => {
    switch (action.type) {
      case LOGIN: {
        const { message, token, statusCode, loginadmin } = action.payload;
        localStorage.setItem("QUIZ-APP", JSON.stringify({ token, loginadmin,isAuthenticated:true,}));
        draft.token = token;
        draft.message = message;
        draft.success = statusCode;
        draft.loginadmin = loginadmin;
        draft.isAuthenticated = true;
        draft.allquiz = []
        break;
      }
      case LOGOUT: {
        draft.token = '';
        draft.message = '';
        draft.success = '';
        draft.user = '';
        draft.isAuthenticated = false;
        draft.allquiz = []
        localStorage.clear()
        break;
      }
      case ALL_QUIZ: {
        const { allquiz } = action.payload
        localStorage.setItem("QUIZ-APP", JSON.stringify({...draft,isAuthenticated:true,allquiz}));
        draft.allquiz = allquiz
        break;
      }
      case QUESTION: {
        const { question } = action.payload
        localStorage.setItem("QUIZ-APP", JSON.stringify({...draft,isAuthenticated:true,question}));
        draft.question = question
        break;
      }
      default:
        return state;
    }
 
  });
