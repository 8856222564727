import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { CButton } from "@coreui/react";
import InfoIcon from "@mui/icons-material/Info";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { styled } from "@mui/material/styles";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";

const RightRadioCard = ({
  imgquedata,
  imgansdata,
  question_add_rimage,
  allquestion,
  question_show_rimage,
}) => {
  console.log("imgquedata------------------>", imgquedata);

  let text_align = imgquedata?.text_alignment;
  let textAlign =
    text_align === "left" ? "start" : text_align === "right" ? "end" : "center";

  const containerStyle = {
    position: "relative",
    width: "100%",
    // height: '100vh',
    backgroundImage: `url(${question_show_rimage ? question_show_rimage : ""})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: `${imgquedata?.background_color}`,
    opacity: `${imgquedata.background_opacity}%`,
  };
  return (
    <>
      <Grid item xs={12} sm={12} lg={8} md={12} spacing={3} className="mt-4">
        <Card
          sx={imgquedata.image_position === "background" && containerStyle}
          className="right_quiz_card"
        >
          {imgquedata.image_position === "background" && (
            <div style={overlayStyle} className="backDiv"></div>
          )}
          <CardContent>
            <Grid className="radio_card_top ">
              {!imgquedata.hide_progress_bar && (
                <Typography
                  variant="subtitle1"
                  className="question_length"
                  component="div"
                >
                  {allquestion?.result?.length +
                    1 +
                    " of " +
                    (allquestion?.result?.length + 1)}
                </Typography>
              )}
              {(imgquedata.image_position === "top" ||
                imgquedata.image_position === "left") &&
                question_show_rimage.length !== 0 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={question_show_rimage}
                      width={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}
              <Typography sx={{ mb: 1 }} className="right_imgque_heading">
                {imgquedata.heading}
              </Typography>
              <Typography sx={{ mb: 1.5 }} className="right_imgque_subheading">
                {imgquedata.subheading}
              </Typography>
              {imgquedata.image_position === "center" &&
                question_show_rimage.length !== 0 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={question_show_rimage}
                      width={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}

              <div
                className="option_main_contain"
                style={{
                  margin:
                    imgquedata.question_layout_style === "Wide" ||
                    imgquedata.question_layout_style === "Default"
                      ? "30px 160px 15px 195px"
                      : imgquedata.layout_display === "Vertical"
                      ? "30px 280px"
                      : "30px 60px 30px 60px",
                }}
              >
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    sx={{ display: "flex" }}
                  >
                    {imgansdata.map((item, index) => {
                      return (
                        <Grid
                          item
                          wrap="nowrap"
                          className={
                            imgquedata.question_layout_style === "Narrow"
                              ? "pb-1"
                              : ""
                          }
                          xs={
                            imgquedata.layout_display === "Vertical"
                              ? imgquedata.question_layout_style === "Narrow"
                                ? 6
                                : 12
                              : imgquedata.question_layout_style !== "Narrow"
                              ? 6
                              : 3
                          }
                          sx={{ display: "flex" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {/* ******************************************************************************WIDE LAYOUT START***************************** */}
                            {imgquedata.question_layout_style === "Wide" && (
                              <FormControlLabel
                                className="radio_que_grop_box"
                                value={
                                  item?.answer?.title
                                    ? item?.answer?.title
                                    : item.title
                                    ? item.title
                                    : "value"
                                }
                                control={
                                  <Radio
                                    sx={{
                                      color: "black",
                                      "&.Mui-checked": {
                                        color: "black",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  item?.answer?.title
                                    ? item?.answer?.title
                                    : item.title
                                    ? item.title
                                    : "value"
                                }
                              />
                            )}

                            {/* ******************************************************************************WIDE LAYOUT END***************************** */}
                            {/* ******************************************************************************NARROW LAYOUT START***************************** */}
                            {imgquedata.question_layout_style === "Narrow" && (
                              <FormControlLabel
                                className="radio_que_grop_box_narrow"
                                sx={{
                                  width:
                                    imgquedata.question_layout_style !== "Wide"
                                      ? "185px"
                                      : "150px",
                                }}
                                labelPlacement="top"
                                value={
                                  item?.answer?.title
                                    ? item?.answer?.title
                                    : item.title
                                    ? item.title
                                    : "value"
                                }
                                control={
                                  <Radio
                                    sx={{
                                      color: "black",
                                      "&.Mui-checked": {
                                        color: "black",
                                      },
                                      display: "flex !important",
                                      justifyContent: "start !important",
                                    }}
                                  />
                                }
                                label={
                                  <div className="narrow_title_radio">
                                    <p>
                                      {item?.answer?.title
                                        ? item?.answer?.title
                                        : item.title
                                        ? item.title
                                        : "value"}
                                    </p>
                                  </div>
                                }
                              />
                            )}

                            {/* ******************************************************************************NARROW LAYOUT END***************************** */}
                            {/* ******************************************************************************DEFAULT LAYOUT START***************************** */}
                            {imgquedata.question_layout_style === "Default" && (
                              <FormControlLabel
                                className="radio_que_grop_box_default"
                                value={
                                  item?.answer?.title
                                    ? item?.answer?.title
                                    : item.title
                                    ? item.title
                                    : "value"
                                }
                                control={
                                  <Radio
                                    sx={{
                                      color: "black",
                                      "&.Mui-checked": {
                                        color: "black",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <div className="narrow_title_radio">
                                    <p>
                                      {item?.answer?.title
                                        ? item?.answer?.title
                                        : item.title
                                        ? item.title
                                        : "value"}
                                    </p>
                                  </div>
                                }
                              />
                            )}
                          </div>
                          {/* ******************************************************************************DEFAULT LAYOUT END***************************** */}
                        </Grid>
                      );
                    })}
                  </Grid>
                </RadioGroup>
              </div>

              {imgquedata.image_position === "right" &&
                question_show_rimage.length !== 0 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={question_show_rimage}
                      width={
                        imgquedata.image_width === 0
                          ? "40px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        imgquedata.image_width === 0
                          ? "40px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}

              <div className="info_icon mt-12">
                {imgquedata.tooltip_heading ? (
                  <>
                    <Container sx={{ paddingBottom: 2 }}>
                      <Tooltip
                        arrow
                        sx={{
                          backgroundColor: "transparent",
                          ":hover": "none",
                        }}
                        title={
                          <Grid>
                            <h5 style={{ textAlign: "center" }}>
                              {imgquedata?.tooltip_heading}
                            </h5>
                            <p>{imgquedata?.tooltip_description}</p>
                          </Grid>
                        }
                      >
                        <IconButton>
                          <ReportRoundedIcon
                            sx={{ fontSize: "50px !important" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Container>
                  </>
                ) : (
                  ""
                )}
              </div>

              <Grid container className="" justifyContent="center">
                <Grid
                  className="back"
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <WestIcon /> <span className="mx-2"> BACK </span>
                </Grid>
                {imgquedata.skip_que && (
                  <Grid
                    className="back"
                    xs
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <span className="mx-2"> SKIP </span>
                  </Grid>
                )}

                <Grid
                  className="next"
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <span className="mx-2 "> NEXT </span> <EastIcon />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default RightRadioCard;
