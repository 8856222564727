import * as React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Checkbox, Grid } from "@mui/material";
import InputComponent from "../inputfiled/Input_type_text";
import Inputtypefile from "../input_file/Input_type_file";
import Range from "../ranger/Range";
import {
  CButton,
  CContainer,
  CDropdownItem,
  CDropdownMenu,
  CFormFloating,
  CFormLabel,
  CRow,
} from "@coreui/react";
import { CardContent, Divider } from "@material-ui/core";
import Select from "../dropdown/Select";
import Slider from "@mui/material/Slider";
import Selectproduct from "../answer_option/Selectproduct";
import { FetchContext } from "../../context/FetchContext";
import { useParams } from "react-router-dom";
import { useState } from "react";

const LeftResult = ({
  resultsettingdata,
  setresultsettingdata,
  setresult_add_an_iimage,
  setresult_show_iimage,
  result_add_an_iimage,
  validation,
  setvalidation,
  result_show_iimage,
  setIsSaveDisable,
  resultProduct,
  setResultProduct,
  validateURL,
  quiz,
}) => {
  const [ProductData, setProductData] = useState([]);
  const shop_id = "gid://shopify/Shop/83321454872";
  const { authAxios } = React.useContext(FetchContext);
  const { id } = useParams();
  const [resultLogic, setResultLogic] = useState("");

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loadingScrollableApi, setLoadingScrollableApi] = useState(false);

  const getProduct = async () => {
    setLoadingScrollableApi(true);
    try {
      authAxios
        .post(`${process.env.REACT_APP_API_URL}/product/product_get`, {
          shop_id: shop_id,
          page: page,
          limit: 5,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            if (ProductData.length == 0) {
              setProductData(response.data.data);
            } else {
              setProductData((prevData) => [
                ...prevData,
                ...response.data.data,
              ]);
            }
            setLoadingScrollableApi(false);
            setHasMore(
              response.data.pagination?.currentPage !==
                response.data.pagination?.totalPages
            );
            setPage((prevPage) => prevPage + 1);
          }
        });
    } catch (error) {
      setLoadingScrollableApi(false);
      console.error("Error fetching data:", error);
    }
  };

  // function getProduct() {
  //   authAxios
  //     .post(`${process.env.REACT_APP_API_URL}/product/product_get`, {
  //       shop_id: shop_id,
  //       page: page,
  //       limit: 3
  //     })
  //     .then((res) => {
  //       setProductData(res.data.data);
  //     })
  //     .catch((err) => {
  //       if (err) {
  //         console.log(err);
  //       }
  //     });
  // }

  function getQuizData() {
    authAxios
      .post(`${process.env.REACT_APP_API_URL}/question/show_question/${id}`, id)
      .then((res) => {
        if (res.data.quiz.result_json !== null) {
          setResultLogic(res.data.quiz.result_json);
        }
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }

  React.useEffect(() => {
    getProduct();
    getQuizData();
  }, []);

  const handleremove = (i) => {
    setresult_add_an_iimage({ result_add_an_iimage: [] });
    setresult_show_iimage("");
    setIsSaveDisable(false);
    setresultsettingdata((prevState) => ({
      ...prevState,
      background_color: "#ffffff",
      background_opacity: 0,
      // image_position:"",
      result_image_obj: "",
      image_position: "center",
    }));
  };
  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        lg={
          !resultsettingdata.is_fallback &&
          quiz?.result_logic === "Basic(Default)"
            ? 3
            : 4
        }
        md={12}
        spacing={1}
        className="mt-4"
      >
        <Grid>
          <span
            style={{
              color: "#93999c",
              fontSize: "18px",
              letterSpacing: "-1px",
            }}
          >
            RESULT FORMATING
          </span>
          <Card className="left_second_rcard">
            <CardContent className="infoq_cardcontent">
              <div className="mx-2">
                <InputComponent
                  label_name={"Name"}
                  value={resultsettingdata?.result_name}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      result_name: e.target.value,
                    });
                    setvalidation({ ...validation, result_name: false });
                    setIsSaveDisable(false);
                  }}
                />
              </div>
              {validation.result_name ? (
                <div className="text-[red] mx-2 mt-[-8px]">
                  {" "}
                  Result name can't be blank{" "}
                </div>
              ) : (
                ""
              )}
              <div className="mx-2">
                <InputComponent
                  label_name={"Heading"}
                  value={resultsettingdata?.heading}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      heading: e.target.value,
                    });
                    // setvalidation(false);
                    setvalidation({ ...validation, heading: false });
                    setIsSaveDisable(false);
                  }}
                />
              </div>
              {validation.heading ? (
                <div className="text-[red] mx-2 mt-[-8px]">
                  {" "}
                  Result heading can't be blank{" "}
                </div>
              ) : (
                ""
              )}
              <div className="mx-2">
                <InputComponent
                  label_name={"Sub heading"}
                  value={resultsettingdata?.subheading}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      subheading: e.target.value,
                    });
                    setIsSaveDisable(false);
                  }}
                />
              </div>
              <div className="mx-2">
                <InputComponent
                  label_name={"Description"}
                  value={resultsettingdata?.placeholder}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      placeholder: e.target.value,
                    });
                    setIsSaveDisable(false);
                  }}
                />
              </div>
              <div className="mx-2">
                <InputComponent
                  label_name={"Button title"}
                  value={resultsettingdata?.button_text}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      button_text: e.target.value,
                    });
                    setIsSaveDisable(false);
                  }}
                />
              </div>
              <div className="mx-2">
                <InputComponent
                  label_name={"URL"}
                  value={resultsettingdata?.button_url}
                  onChange={(e) => {
                    if (!validateURL(e.target.value)) {
                      setvalidation({ url: true });
                    } else {
                      setvalidation({ ...validation, url: false });
                      setIsSaveDisable(false);
                    }
                    setresultsettingdata({
                      ...resultsettingdata,
                      button_url: e.target.value,
                    });
                  }}
                />
              </div>
              {validation.url ? (
                <div className="text-[red] mx-2 mt-[-8px]">
                  {" "}
                  Button URL is Invaild{" "}
                </div>
              ) : (
                ""
              )}
              {result_add_an_iimage === undefined ||
              result_add_an_iimage?.length === 0 ||
              !result_show_iimage ? (
                // {result_add_an_iimage(?.length === 0||undefined) || !result_show_iimage ? (
                <div className="mx-2 mt-4">
                  <Inputtypefile
                    name={result_add_an_iimage}
                    image_text="Add an image"
                    onChange={(e) => {
                      if (e.target.files.length !== 0) {
                        setresult_show_iimage(
                          URL.createObjectURL(e.target.files[0])
                        );
                        setresult_add_an_iimage(e.target.files[0]);
                      }
                      setIsSaveDisable(false);
                    }}
                  />
                </div>
              ) : (
                <>
                  <Grid className="d-flex mt-4" xs={12} container>
                    <Grid item className="pl-0 px-2" xs={8}>
                      <Inputtypefile
                        image_text="Change an image"
                        onChange={(e) => {
                          if (e.target.files.length !== 0) {
                            setresult_show_iimage(
                              URL.createObjectURL(e.target.files[0])
                            );
                            setresult_add_an_iimage(e.target.files[0]);
                          }
                          setIsSaveDisable(false);
                        }}
                      />
                    </Grid>
                    <Grid item className="pl-0 px-2" xs={4}>
                      <CButton
                        className="preview remove "
                        onClick={handleremove}
                      >
                        {" "}
                        Remove
                      </CButton>
                    </Grid>
                  </Grid>

                  <div className="mx-2 mt-4 ">
                    <Select
                      dropdown_text={"Image position"}
                      values={["center", "left", "right", "background"]}
                      onChange={(e) => {
                        setresultsettingdata({
                          ...resultsettingdata,
                          image_position: e.target.value,
                        });
                        setIsSaveDisable(false);
                      }}
                      value={resultsettingdata?.image_position}
                    />
                  </div>
                  {(resultsettingdata?.image_position === "center" ||
                    resultsettingdata?.image_position === "top") && (
                    <div className="mx-2 mt-4 ">
                      <div style={{ fontSize: "18px" }}> Image width(px)</div>
                      <div className="image_range">
                        <Range
                          value={parseInt(resultsettingdata.image_width)}
                          onChange={(e) => {
                            setresultsettingdata({
                              ...resultsettingdata,
                              image_width: e.target.value,
                            });
                            setIsSaveDisable(false);
                          }}
                          max={true}
                        />
                      </div>
                    </div>
                  )}
                  {resultsettingdata?.image_position === "background" && (
                    <>
                      <div
                        className="d-flex col-6 overlay_text mx-2 "
                        style={{ alignItems: "center" }}
                      >
                        <input
                          style={{ width: "50px", height: "50px" }}
                          type="color"
                          className="color_button w-52 h-14 mt-1 me-2"
                          onChange={(e) => {
                            setresultsettingdata({
                              ...resultsettingdata,
                              background_color: e.target.value,
                            });
                            setIsSaveDisable(false);
                          }}
                          defaultValue={resultsettingdata?.background_color}
                        ></input>
                        <div className="me-2" style={{ fontSize: "18px" }}>
                          Overlay color
                        </div>
                      </div>
                      <div className="mx-2 mt-4 ">
                        <div style={{ fontSize: "18px" }}> Overlay opacity</div>
                        <div className="image_range">
                          <Range
                            value={parseInt(
                              resultsettingdata?.background_opacity
                            )}
                            onChange={(e) => {
                              setresultsettingdata({
                                ...resultsettingdata,
                                background_opacity: e.target.value,
                              });
                              setIsSaveDisable(false);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid className="mt-4">
          <span
            style={{
              color: "#93999c",
              fontSize: "18px",
              letterSpacing: "-1px",
            }}
          >
            SETTINGS
          </span>
          <Card className="left_second_rcard">
            <CardContent className="infoq_cardcontent">
              <div>
                <CFormLabel
                  htmlFor="floatingInput "
                  className="mt-4 ms-1"
                  style={{ fontSize: "18px" }}
                >
                  Products{" "}
                </CFormLabel>
                <div className="select_product_componant">
                  <Selectproduct
                    // setimgansdata={setimgansdata}
                    // imgansdata={imgansdata}
                    ProductData={ProductData}
                    // element={element}
                    // index={index}
                    hasMore={hasMore}
                    getProduct={getProduct}
                    setHasMore={setHasMore}
                    setIsSaveDisable={setIsSaveDisable}
                    loadingScrollableApi={loadingScrollableApi}
                    setResultProduct={setResultProduct}
                    resultProduct={resultProduct}
                  />
                </div>
              </div>
              {resultLogic.result_logic === "Points" &&
                resultsettingdata.is_fallback !== true && (
                  <>
                    <CFormLabel
                      htmlFor="floatingInput "
                      className="mx-2 mt-4"
                      style={{ fontSize: "18px" }}
                    >
                      Points
                    </CFormLabel>
                    <CFormFloating
                      className="p-1"
                      style={{
                        border: " 1px solid #B1B7C1",
                        borderRadius: "6px",
                      }}
                    >
                      <div className="mx-2 d-flex">
                        <div style={{ marginRight: "20px" }}>
                          <InputComponent
                            label_name={" From"}
                            value={resultsettingdata.from}
                            onChange={(e) => {
                              setresultsettingdata({
                                ...resultsettingdata,
                                from: e.target.value,
                              });
                              // setvalidation(false);
                              setIsSaveDisable(false);
                            }}
                            min="0"
                            type="number"
                          />
                        </div>
                        <InputComponent
                          label_name={" To"}
                          value={resultsettingdata?.to}
                          onChange={(e) => {
                            setresultsettingdata({
                              ...resultsettingdata,
                              to: e.target.value,
                            });
                            // setvalidation(false);
                            setIsSaveDisable(false);
                          }}
                          min="0"
                          type="number"
                        />
                      </div>
                    </CFormFloating>
                  </>
                )}
              <div className="mx-2 mt-4 ">
                <Select
                  dropdown_text={"Product Image position"}
                  values={["auto", "stretch"]}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      product_image_position: e.target.value,
                    });
                    setIsSaveDisable(false);
                  }}
                  value={resultsettingdata?.product_image_position}
                />
              </div>
              <div className="mx-2 mt-4 ">
                <div className="mx-2 mt-4 ">
                  <div style={{ fontSize: "18px" }}> Image Height(px)</div>
                  <div className="image_range">
                    <Grid item xs>
                      <Slider
                        value={resultsettingdata?.product_image_height}
                        onChange={(e) => {
                          setresultsettingdata({
                            ...resultsettingdata,
                            product_image_height: e.target.value,
                          });
                          setIsSaveDisable(false);
                        }}
                        aria-labelledby="input-slider"
                        max={400}
                        min={200}
                      />
                    </Grid>
                  </div>
                </div>
              </div>

              <div className="d-flex ">
                <Checkbox
                  checked={
                    resultsettingdata?.add_bag_button_width == true
                      ? true
                      : false
                  }
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      add_bag_button_width: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Make "Add to bag" button full width
                </Typography>
              </div>
              {/* <div className="d-flex ">
                <Checkbox
                  checked={
                    resultsettingdata?.show_quantity_picker == true
                      ? true
                      : false
                  }
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      show_quantity_picker: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Show quantity picker
                </Typography>
              </div> */}
              {/* <div className="d-flex ">
                <Checkbox
                  checked={
                    resultsettingdata?.show_description == true ? true : false
                  }
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      show_description: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Show description
                </Typography>
              </div>
              <div className="d-flex ">
                <Checkbox
                  checked={
                    resultsettingdata?.limit_description == true ? true : false
                  }
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      limit_description: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Limit description to 3 lines
                </Typography>
              </div> */}
              {resultsettingdata?.show_product_type == false && (
                <div className="d-flex ">
                  <Checkbox
                    checked={
                      resultsettingdata?.show_vendor == true ? true : false
                    }
                    className="mx-1 "
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                    onChange={(e) => {
                      setresultsettingdata({
                        ...resultsettingdata,
                        show_vendor: e.target.checked,
                      });
                      setIsSaveDisable(false);
                    }}
                  ></Checkbox>
                  <Typography
                    color="text.secondary"
                    className="mt-3 logic_jump"
                  >
                    Show vendor
                  </Typography>
                </div>
              )}
              {resultsettingdata?.show_vendor == false && (
                <div className="d-flex ">
                  <Checkbox
                    checked={
                      resultsettingdata?.show_product_type == true
                        ? true
                        : false
                    }
                    className="mx-1 "
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                    onChange={(e) => {
                      setresultsettingdata({
                        ...resultsettingdata,
                        show_product_type: e.target.checked,
                      });
                      setIsSaveDisable(false);
                    }}
                  ></Checkbox>
                  <Typography
                    color="text.secondary"
                    className="mt-3 logic_jump"
                  >
                    Show type
                  </Typography>
                </div>
              )}
              {/* <div className="d-flex ">
                <Checkbox
                  checked={
                    resultsettingdata?.show_money_saved == true ? true : false
                  }
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      show_money_saved: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Show money saved
                </Typography>
              </div> */}
              <div className="d-flex ">
                <Checkbox
                  checked={
                    resultsettingdata?.show_product_title == true ? true : false
                  }
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      show_product_title: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Show product title only
                </Typography>
              </div>
              <div className="d-flex ">
                <Checkbox
                  checked={
                    resultsettingdata?.product_featured_image == true
                      ? true
                      : false
                  }
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      product_featured_image: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Show product featured image instead of variant image
                </Typography>
              </div>
              {/* <div className="d-flex ">
                <Checkbox
                  checked={
                    resultsettingdata?.product_second_image == true
                      ? true
                      : false
                  }
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      product_second_image: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Show product second image instead of variant image
                </Typography>
              </div> */}
              <div className="d-flex ">
                <Checkbox
                  checked={
                    resultsettingdata?.show_all_product_image == true
                      ? true
                      : false
                  }
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      show_all_product_image: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Show all product images
                </Typography>
              </div>
              {/* <div className="d-flex ">
                <Checkbox
                  checked={
                    resultsettingdata?.show_variant_name == true ? true : false
                  }
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      show_variant_name: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Show variant name instead of product title
                </Typography>
              </div> */}
              {/* <div className="d-flex ">
                <Checkbox
                  checked={
                    resultsettingdata?.variants_box == true ? true : false
                  }
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      variants_box: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Show variants as boxes
                </Typography>
              </div> */}
              {/* <div className="d-flex ">
                <Checkbox
                  checked={
                    resultsettingdata?.separate_product == true ? true : false
                  }
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      separate_product: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Show variants as separate product items
                </Typography>
              </div> */}
              <div className="d-flex ">
                <Checkbox
                  checked={
                    resultsettingdata?.hide_add_to_bag == true ? true : false
                  }
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      hide_add_to_bag: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Hide Add to Bag
                </Typography>
              </div>
              <div className="d-flex ">
                <Checkbox
                  checked={
                    resultsettingdata?.hide_variants == true ? true : false
                  }
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      hide_variants: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Hide variants
                </Typography>
              </div>
              <div className="d-flex ">
                <Checkbox
                  checked={resultsettingdata?.hide_price == true ? true : false}
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      hide_price: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Hide price
                </Typography>
              </div>
              {/* <div className="d-flex ">
                <Checkbox
                  checked={
                    resultsettingdata?.hide_link_product_img == true
                      ? true
                      : false
                  }
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setresultsettingdata({
                      ...resultsettingdata,
                      hide_link_product_img: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Hide links from product image and title
                </Typography>
              </div> */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default LeftResult;
