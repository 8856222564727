import { CFormInput, CFormLabel } from "@coreui/react";
import { DialogContent } from "@mui/material";
import React from "react";

const InputComponent = ({
  label_name,
  onChange,
  placeholder,
  value,
  name,
  validation,
  type,
  min,
}) => {
  return (
    <div>
      <CFormLabel
        htmlFor="inputText"
        className="mt-1 col-form-label input_fontsize"
      >
        {label_name}
      </CFormLabel>
      <CFormInput
        style={{ fontSize: "18px" }}
        type={type ? type : "text"}
        name={name}
        size="lg"
        value={value}
        defaultValue={value}
        className="mb-3 dialog_input "
        placeholder={placeholder}
        aria-label=" input example"
        onChange={onChange}
        invalid={value ? validation : false}
        min={min ? min : ""}
      />
    </div>
  );
};

export default InputComponent;
