import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  Container,
  Grid,
  IconButton,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { CButton, CFormLabel } from "@coreui/react";
import InfoIcon from "@mui/icons-material/Info";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { styled } from "@mui/material/styles";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";

import { FileUploader } from "react-drag-drop-files";
import { useParams } from "react-router-dom";

const EditRightFileupload = ({
  imgquedata,
  question_add_an_iimage,
  allquestion,
  question_show_iimage,
  setimgquedata,
}) => {
  console.log("imgquedata---------->", imgquedata);

  let image_url =
    question_show_iimage.length !== 0
      ? question_show_iimage
      : `${process.env.REACT_APP_API_URL}/question/${imgquedata?.question_add_an_iimage}`;
  console.log("image url----------->", image_url);

  const { id } = useParams();
  const question_index = allquestion?.result?.findIndex(
    (item) => item._id === id
  );
  const [File, setFile] = React.useState([{ file: "" }]);
  const [error, seterror] = React.useState({ err: "" });
  const [fileArray, setfileArray] = React.useState({});
  const [singlepreview, setsinglepreview] = React.useState([]);

  React.useEffect(() => {
    setfileArray({});
    setsinglepreview([]);
  }, [imgquedata.multiselect_answer, imgquedata.question_layout_style]);

  const handleChange = async (file) => {
    setFile([{ file: file }]);
    seterror({ err: "" });
    if (imgquedata.multiselect_answer !== false && file && file.length <= 3) {
      setfileArray({});
      for (const [key, value] of Object.entries(file)) {
        if (imgquedata.question_layout_style === "image") {
          fileArray[key] = URL.createObjectURL(value);
        } else {
          fileArray[key] = value.name;
        }
        setfileArray(fileArray);
      }
    } else {
      setfileArray({});
    }
    if (imgquedata.multiselect_answer === false) {
      if (imgquedata.question_layout_style === "image") {
        setsinglepreview(URL.createObjectURL(file));
      } else {
        setsinglepreview(file.name);
      }
    }
  };

  let backgroundImg;
  if (
    question_add_an_iimage?.length === 0 &&
    imgquedata?.question_add_an_iimage != 0
  ) {
    if (imgquedata.question_add_an_iimage) {
      backgroundImg = `url(${
        process.env.REACT_APP_API_URL
      }/question/${encodeURIComponent(imgquedata.question_add_an_iimage)})`;
    }
  } else if (question_show_iimage) {
    backgroundImg = `url(${question_show_iimage})`;
  } else {
    backgroundImg = "";
  }

  const containerStyle = {
    position: "relative",
    width: "100%",
    // height: '100vh',
    backgroundImage: backgroundImg,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: `${imgquedata?.background_color}`,
    opacity: `${imgquedata.background_opacity}%`,
  };
  return (
    <>
      <Grid item xs={12} sm={12} lg={8} md={12} spacing={3} className="mt-4">
        <Card
          sx={imgquedata.image_position === "background" && containerStyle}
          className="right_quiz_card"
        >
          {imgquedata.image_position === "background" && (
            <div style={overlayStyle} className="backDiv"></div>
          )}
          <CardContent>
            <Grid className="image_card_top">
              {!imgquedata.hide_progress_bar && (
                <Typography
                  variant="h6"
                  className="question_length"
                  component="div"
                >
                  {question_index + 1 + " of " + allquestion?.result?.length}
                </Typography>
              )}
              {(imgquedata.image_position === "top" ||
                imgquedata.image_position === "left") &&
                (question_show_iimage.length !== 0 ||
                  imgquedata?.question_add_an_iimage) && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {console.log("imside condition**************************")}
                    <img
                      src={image_url}
                      width={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}
              <Typography sx={{ mb: 1 }} className="right_imgque_heading">
                {imgquedata.heading}
              </Typography>
              <Typography sx={{ mb: 1.5 }} className="right_imgque_subheading">
                {imgquedata.subheading}
              </Typography>
              {imgquedata.image_position === "center" &&
                (question_show_iimage.length !== 0 ||
                  imgquedata?.question_add_an_iimage) && (
                  <div
                    className="mt-2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      src={image_url}
                      width={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}

              <div className="option_main_contain">
                <div
                  className={
                    imgquedata?.placeholder
                      ? " mt-4 file-content file-main "
                      : "mt-4 file-main "
                  }
                >
                  <FileUploader
                    handleChange={handleChange}
                    name="file"
                    multiple={
                      imgquedata.multiselect_answer === true ? true : false
                    }
                    maxSize={2}
                    children={
                      imgquedata?.placeholder ? (
                        <div className="file-content_error">
                          {imgquedata?.placeholder}{" "}
                        </div>
                      ) : (
                        <div className="file-content_error">
                          {" "}
                          {"Drag & drop, or click to select files."}{" "}
                        </div>
                      )
                    }
                    types={
                      imgquedata.question_layout_style === "image"
                        ? ["JPG", "PNG", "GIF"]
                        : ["PDF", "TXT"]
                    }
                    onSizeError={(err) => seterror({ ...error, err: err })}
                  />
                  <div className="mt-3 ">
                    <span className="upload_err d-flex justify-center text-[red]">
                      {error.err || File[0]?.file?.length >= 4
                        ? imgquedata.upload_error === ""
                          ? "You can upload up to 3 files (5mb max size per file)"
                          : imgquedata.upload_error
                        : ""}
                    </span>
                  </div>
                </div>
                {!error.err ? (
                  <div
                    className={
                      imgquedata.question_layout_style === "image"
                        ? "d-flex justify-center mt-3"
                        : "mt-3"
                    }
                  >
                    {imgquedata.multiselect_answer === false &&
                    singlepreview.length !== 0 ? (
                      imgquedata.question_layout_style === "image" ? (
                        <div className="h-[150px] w-[150px] border-2">
                          <img src={singlepreview} alt="preview" />
                        </div>
                      ) : (
                        <div className="text-center">{singlepreview}</div>
                      )
                    ) : (
                      <>
                        {Object.keys(fileArray).length <= 3 &&
                          Object.values(fileArray).map((value) =>
                            imgquedata.question_layout_style === "image" ? (
                              <div className="h-[150px] w-[150px] fgfgf border-2 m-1">
                                <img src={value} alt="preview" />
                              </div>
                            ) : (
                              <div className="text-center p-2">{value}</div>
                            )
                          )}
                      </>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>

              {imgquedata.image_position === "right" &&
                (question_show_iimage.length !== 0 ||
                  imgquedata?.question_add_an_iimage) && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={image_url}
                      width={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}
              {/* 
              {imgquedata.image_position !== "top" && (
                <>
                  <Typography sx={{ mb: 1.5 }} className="right_imgque_heading">
                    {imgquedata.heading}
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    className="right_imgque_subheading"
                  >
                    {imgquedata.subheading}
                  </Typography>
                </>
              )}
              {imgquedata.image_position !== "background" && (
                <div
                  className={
                    imgquedata.image_position === "center" ||
                    imgquedata.image_position === "top"
                      ? "center_position"
                      : imgquedata.image_position === "right"
                      ? "right_position"
                      : "right_imgmain_image"
                  }
                >
                  {question_show_iimage &&
                  question_add_an_iimage?.length !== 0 ? (
                    imgquedata.image_position == "center" ||
                    imgquedata.image_position === "top" ? (
                      <img
                        src={question_show_iimage}
                        width={`${790 - imgquedata.image_width}px`}
                        height={`${790 - imgquedata.image_width}px`}
                      />
                    ) : (
                      <img
                        src={question_show_iimage}
                        width={`790px`}
                        height={`790px`}
                      />
                    )
                  ) : question_add_an_iimage?.length === 0 &&
                    imgquedata?.question_add_an_iimage != 0 ? (
                    imgquedata.image_position === "center" ||
                    imgquedata.image_position === "top" ? (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/question/${imgquedata?.question_add_an_iimage}`}
                        width={`${790 - imgquedata.image_width}px`}
                        height={`${790 - imgquedata.image_width}px`}
                      />
                    ) : (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/question/${imgquedata?.question_add_an_iimage}`}
                        width={`790px`}
                        height={`790px`}
                      />
                    )
                  ) : question_add_an_iimage.length === 0 &&
                    question_show_iimage.length === 0 ? (
                    ""
                  ) : (
                    ""
                  )}
                </div>
              )}
              {imgquedata.image_position === "top" && (
                <>
                  <Typography sx={{ mb: 1.5 }} className="right_imgque_heading">
                    {imgquedata.heading}
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    className="right_imgque_subheading"
                  >
                    {imgquedata.subheading}
                  </Typography>
                </>
              )}
              <div
                className={
                  imgquedata?.placeholder
                    ? " mt-4 file-content file-main "
                    : "mt-4 file-main "
                }
              >
                <FileUploader
                  handleChange={handleChange}
                  name="file"
                  multiple={
                    imgquedata.multiselect_answer === true ? true : false
                  }
                  maxSize={2}
                  children={
                    imgquedata?.placeholder ? (
                      <div className="file-content_error">
                        {imgquedata?.placeholder}{" "}
                      </div>
                    ) : (
                      <div className="file-content_error">
                        {" "}
                        {"Drag & drop, or click to select files."}{" "}
                      </div>
                    )
                  }
                  types={
                    imgquedata.question_layout_style === "image"
                      ? ["JPG", "PNG", "GIF"]
                      : ["PDF", "TXT"]
                  }
                  onSizeError={(err) => seterror({ ...error, err: err })}
                />
                <div className="mt-3">
                  <span className="upload_err d-flex justify-center text-[red]">
                    {error.err || File[0]?.file?.length >= 4
                      ? imgquedata.upload_error === ""
                        ? "You can upload up to 3 files (5mb max size per file)"
                        : imgquedata.upload_error
                      : ""}
                  </span>
                </div>
              </div>
              {!error.err ? (
                <div
                  className={
                    imgquedata.question_layout_style === "image"
                      ? "d-flex justify-center mt-3"
                      : "mt-3"
                  }
                >
                  {imgquedata.multiselect_answer === false &&
                  singlepreview.length !== 0 ? (
                    imgquedata.question_layout_style === "image" ? (
                      <div className="h-[150px] w-[150px] border-2">
                        <img src={singlepreview} alt="preview" />
                      </div>
                    ) : (
                      <div className="text-center">{singlepreview}</div>
                    )
                  ) : (
                    <>
                      {Object.keys(fileArray).length <= 3 &&
                        Object.values(fileArray).map((value) =>
                          imgquedata.question_layout_style === "image" ? (
                            <div className="h-[150px] w-[150px] fgfgf border-2 m-1">
                              <img src={value} alt="preview" />
                            </div>
                          ) : (
                            <div className="text-center  p-2">{value}</div>
                          )
                        )}
                    </>
                  )}
                </div>
              ) : (
                ""
              )} */}

              <div className="info_icon mt-12">
                {imgquedata.tooltip_heading ? (
                  <>
                    <Container sx={{ paddingBottom: 2 }}>
                      <Tooltip
                        arrow
                        sx={{
                          backgroundColor: "transparent",
                          ":hover": "none",
                        }}
                        title={
                          <Grid>
                            <h5 style={{ textAlign: "center" }}>
                              {imgquedata?.tooltip_heading}
                            </h5>
                            <p>{imgquedata?.tooltip_description}</p>
                          </Grid>
                        }
                      >
                        <IconButton>
                          <ReportRoundedIcon
                            sx={{ fontSize: "50px !important" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Container>
                  </>
                ) : (
                  ""
                )}
              </div>

              <Grid container className="" justifyContent="center">
                <Grid
                  className="back"
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <WestIcon /> <span className="mx-2"> BACK </span>
                </Grid>
                {imgquedata.skip_que && (
                  <Grid
                    className="back"
                    xs
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <span className="mx-2"> SKIP </span>
                  </Grid>
                )}

                <Grid
                  className="next"
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <span className="mx-2 "> NEXT </span> <EastIcon />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default EditRightFileupload;
