import { Chip, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Buttton from "../Button/Buttton";
import { FetchContext } from "../../context/FetchContext";

const CreateQuestionTitle = ({
  link,
  quiz_name,
  is_status,
  loading,
  isSaveDisable,
  questionsave,
  preview,
  quiz_id,
  questions,
  is_publish,
}) => {
  const { authAxios } = useContext(FetchContext);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (questions?.result?.length !== 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [quiz_id, questions]);
  return (
    <div className="d-flex">
      <Grid className="intro_back " xs={6}>
        <div className="mx-4 d-flex">
          <Link to={link}>
            <KeyboardBackspaceRoundedIcon className="intro_backbutton" />{" "}
          </Link>
          <div className="mx-4 intro_quizname mt-2">{quiz_name}</div>
          <div className="mt-1">
            {is_publish ? (
              <Chip className="live_chip" label="Live" />
            ) : (
              <Chip className="unlive_chip" label="Unpublished" />
            )}
          </div>
        </div>
      </Grid>
      <Grid className="save_button mr-6 " xs={6}>
        <Grid
          className={`mr-10 mt-2 ${
            isDisabled !== true ? "disabled text-secondary" : "text-dark"
          }`}
          onClick={(e) => isDisabled == true && preview()}
        >
          <VisibilityIcon />{" "}
          <span className="preview_save mx-1"> preview </span>
        </Grid>
        {questionsave !== undefined && (
          <Buttton
            handleClick={questionsave}
            className="savebutton"
            title={"Save"}
            disabled={isSaveDisable}
            loading={loading}
          />
        )}
      </Grid>
    </div>
  );
};

export default CreateQuestionTitle;
