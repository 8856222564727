import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@mui/material/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import {
  Backdrop,
  CircularProgress,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { FetchContext } from "../../context/FetchContext";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { useEffect, useState } from "react";

const ITEM_HEIGHT = 60;

export default function CardResult({ quiz, id, setQuestion }) {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  const { authAxios } = useContext(FetchContext);
  const [loading, setloading] = React.useState(false);
  const [resultData, setResultData] = React.useState([]);
  const [resultLogic, setResultLogic] = React.useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [qustionId, setQuestionId] = useState();
  console.log("resultData-------------->",resultData);
  
  const handleDeletePopupClose = () => {
    setDeleteModalOpen(false);
  };
  function handleDeletePopupOpen(e, _id) {
    setDeleteModalOpen(true);
    setQuestionId(_id);
  }
  function getQuizData() {
    authAxios
      .post(`${process.env.REACT_APP_API_URL}/question/show_question/${id}`, id)
      .then((res) => {
        if (res.data.quiz.result_json !== null) {
          console.log(
            "res.data.quiz.result_json------>",
            res.data.quiz.result_json
          );
          setQuestion(res.data);
          // setresultsettingdata(res.data.quiz.result_json);
          setResultLogic(res.data.quiz.result_json);
        }
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }
  function getResultData() {
    setloading(true);
    authAxios
      .get(`${process.env.REACT_APP_API_URL}/result/show_results/${id}`)
      .then((res) => {
        console.log("res-------->", res);
        setResultData(res.data?.result);
        setloading(false);
      })
      .catch((err) => {
        if (err) {
          setloading(false);
        }
      });
  }
  useEffect(() => {
    getResultData();
    getQuizData();
  }, [id]);

  const handleDeleteResult = (_id) => {
    setloading(true);
    authAxios
      .delete(
        `${process.env.REACT_APP_API_URL}/result/delete_result/${qustionId}`
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          getResultData();
          setloading(false);
        }
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          setloading(false);
        }
      });
    setDeleteModalOpen(false);
  };

  const add_result = (e) => {
    navigate(`/quiz/mainresult/${id}/${id}`);
  };
  const editFormResult = (item) => {
    console.log("item", item);
    navigate(`/quiz/mainresult/${id}/${item._id}`);
  };

  return (
    <>
      <div>
        <Modal
          open={deleteModalOpen}
          onClose={handleDeletePopupClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="main_modal_div">
            <div
              id="modal-modal-title"
              className="product_modal_header py-0 px-2"
            >
              <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                Delete
              </Typography>
              <Typography>
                <IconButton
                  sx={{ padding: "0px" }}
                  onClick={handleDeletePopupClose}
                >
                  <CloseIcon />
                </IconButton>
              </Typography>
            </div>
            <Box className="">
              <div className="product_modal_body">
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2, padding: " 10px 20px" }}
                >
                  Are you sure want to delete?
                </Typography>
                <div
                  style={{
                    padding: "10px 0px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                ></div>
              </div>
            </Box>
            <div
              className="product_modal_footer"
              style={{
                padding: "10px 10px",

                gap: "15px",
              }}
            >
              <Button
                onClick={handleDeletePopupClose}
                className="delete_modal_no"
                variant="contained"
              >
                No
              </Button>
              <Button
                onClick={handleDeleteResult}
                className="product_modal_save"
                variant="contained"
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      {loading ? (
        <div sx={{ display: "flex" }}>
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="success" />
          </Backdrop>
        </div>
      ) : (
        <Grid container spacing={6}>
          {resultData?.map((item, index) =>
            resultLogic.result_logic !== "Points" &&
            resultLogic.result_logic !== "Basic(Default)" && resultLogic.result_logic !== "Result Weight"? (
              item.is_fallback === true && (
                <Grid
                  item
                  sm={6}
                  lg={3}
                  xs={12}
                  md={4}
                  className="intro_allcard "
                >
                  <Card key={index} className="card_intro ">
                    <CardContent className="m-2 p-3 pt-4">
                      <Typography variant="h6">
                        {index === 0 ? "Fallback Result" : "Results " + index}
                        <span
                          style={{ textAlign: "end" }}
                          className=" float-right"
                        >
                          <EditIcon
                            className="icons mx-1"
                            onClick={() => editFormResult(item)}
                          />
                          {item?.is_fallback !== true && (
                            <DeleteIcon
                              className="icons"
                              onClick={(e) =>
                                handleDeletePopupOpen(e, item._id)
                              }
                            />
                          )}
                        </span>
                      </Typography>
                      <Typography
                        variant="body1"
                        className="question_card_heading"
                      >
                        {item.result_name}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )
            ) : (
              <Grid
                item
                sm={6}
                lg={3}
                xs={12}
                md={4}
                className="intro_allcard "
              >
                <Card key={index} className="card_intro ">
                  <CardContent className="m-2 p-3 pt-4">
                    <Typography variant="h6">
                      {index === 0 ? "Fallback Result" : "Results " + index}
                      <span
                        style={{ textAlign: "end" }}
                        className=" float-right"
                      >
                        <EditIcon
                          className="icons mx-1"
                          onClick={() => editFormResult(item)}
                        />
                        {item?.is_fallback !== true && (
                          <DeleteIcon
                            className="icons"
                            onClick={(e) => handleDeletePopupOpen(e, item._id)}
                          />
                        )}
                      </span>
                    </Typography>
                    <Typography
                      variant="body1"
                      className="question_card_heading"
                    >
                      {item.result_name}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )
          )}
          {(resultLogic.result_logic === "Points" ||
            resultLogic.result_logic === "Basic(Default)" || resultLogic.result_logic === "Result Weight") && (
            <Grid item sm={6} lg={2} xs={12} md={4}>
              <Card
                onClick={(e) => add_result(e)}
                className="card_intro plus_card"
              >
                <CardContent>
                  <Typography
                    className="d-flex justify-center"
                    sx={{ mt: 0.5 }}
                    variant="h1"
                  >
                    <AddIcon className="question_plus" />
                  </Typography>
                  <Typography
                    className="d-flex justify-center add_question"
                    sx={{ mt: 1, height: "50px" }}
                    component="div"
                  >
                    Add result
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}
