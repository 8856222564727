import * as React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Checkbox, Grid, Slider } from "@mui/material";
import InputComponent from "../../../inputfiled/Input_type_text";
import Inputtypefile from "../../../input_file/Input_type_file";
import Range from "../../../ranger/Range";
import { CButton, CDropdownItem, CDropdownMenu } from "@coreui/react";
import { CardContent, Divider } from "@material-ui/core";
import Select from "../../../dropdown/Select";
import AccordianTextBox from "../../../answer_option/AccordianTextBox";
import { useContext } from "react";
import { FetchContext } from "../../../../context/FetchContext";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import LeadtoQuestion from "../../../answer_option/LeadtoQuestion";

const LeftTextBoxCard = ({
  imgquedata,
  setimgquedata,
  imgansdata,
  setimgansdata,
  setquestion_add_an_iimage,
  setquestion_show_iimage,
  question_show_iimage,
  question_add_an_iimage,
  setvalidation,
  validation,
  setIsSaveDisable,
  resultLogic,
  allquestion,
  allQuelist,
}) => {
  const question_type = [
    "Text box",
    "Image with text",
    "Radio buttons",
    "Range slider",
    "Input",
    "select",
    "info",
    "File upload",
  ];
  const shop_id = "gid://shopify/Shop/83321454872";
  const { authAxios } = useContext(FetchContext);
  const [ProductData, setProductData] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loadingScrollableApi, setLoadingScrollableApi] = useState(false);
  const [resultData, setResultData] = React.useState([]);
  const { id } = useParams();

  const getProduct = async () => {
    setLoadingScrollableApi(true);
    try {
      authAxios
        .post(`${process.env.REACT_APP_API_URL}/product/product_get`, {
          shop_id: shop_id,
          page: page,
          limit: 3,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            if (ProductData.length == 0) {
              setProductData(response.data.data);
            } else {
              setProductData((prevData) => [
                ...prevData,
                ...response.data.data,
              ]);
            }
            setLoadingScrollableApi(false);
            setHasMore(
              response.data.pagination?.currentPage !==
                response.data.pagination?.totalPages
            );
            setPage((prevPage) => prevPage + 1);
          }
        });
    } catch (error) {
      setLoadingScrollableApi(false);
      console.error("Error fetching data:", error);
    }
  };
  function getResultData() {
    authAxios
      .get(`${process.env.REACT_APP_API_URL}/result/show_results/${id}`)
      .then((res) => {
        setResultData(res.data?.result);
      });
  }

  useEffect(() => {
    getProduct();
    getResultData();
  }, []);

  const handleremove = (i) => {
    setIsSaveDisable(false);
    setquestion_add_an_iimage({ question_add_an_iimage: [] });
    setquestion_show_iimage("");
    setimgquedata((prevState) => ({
      ...prevState,
      background_color: "#ffffff",
      background_opacity: 0,
      image_position: "center",
    }));
  };
  return (
    <>
      <Grid item xs={12} sm={12} lg={4} md={12} spacing={3} className="mt-4">
        <Grid className="">
          <span
            style={{
              color: "#93999c",
              fontSize: "18px",
              letterSpacing: "-1px",
            }}
          >
            QUESTION FORMATING
          </span>
          <Card className="left_second_rcard">
            <CardContent className="infoq_cardcontent">
              <div className="mx-2">
                <InputComponent
                  label_name={"Heading"}
                  onChange={(e) => {
                    setimgquedata({ ...imgquedata, heading: e.target.value });
                    setvalidation(false);
                    setIsSaveDisable(false);
                  }}
                />
              </div>
              {validation ? (
                <div className="text-[red] mx-2 mt-[-8px]">
                  {" "}
                  Question heading can't be blank{" "}
                </div>
              ) : (
                ""
              )}

              <div className="mx-2">
                <InputComponent
                  label_name={"Sub heading"}
                  onChange={(e) => {
                    setimgquedata({
                      ...imgquedata,
                      subheading: e.target.value,
                    });
                    setIsSaveDisable(false);
                  }}
                />
              </div>

              {question_add_an_iimage.length === 0 || !question_show_iimage ? (
                <div className="mx-2 mt-4">
                  <Inputtypefile
                    name={question_add_an_iimage}
                    image_text="Add an image"
                    onChange={(e) => {
                      if (e.target.files.length !== 0) {
                        setquestion_show_iimage(
                          URL.createObjectURL(e.target.files[0])
                        );
                        setquestion_add_an_iimage(e.target.files[0]);
                      }
                      setIsSaveDisable(false);
                    }}
                  />
                </div>
              ) : (
                <>
                  <Grid className="d-flex mt-4" xs={12} container>
                    <Grid item className="pl-0 px-2" xs={8}>
                      <Inputtypefile
                        image_text="Change an image"
                        onChange={(e) => {
                          if (e.target.files.length !== 0) {
                            setquestion_show_iimage(
                              URL.createObjectURL(e.target.files[0])
                            );
                            setquestion_add_an_iimage(e.target.files[0]);
                          }
                          setIsSaveDisable(false);
                        }}
                      />
                    </Grid>
                    <Grid item className="pl-0 px-2" xs={4}>
                      <CButton
                        className="preview remove "
                        onClick={handleremove}
                      >
                        {" "}
                        Remove
                      </CButton>
                    </Grid>
                  </Grid>

                  <div className="mx-2 mt-4 ">
                    <Select
                      dropdown_text={"Image position"}
                      values={["center", "left", "right", "background", "top"]}
                      onChange={(e) => {
                        setimgquedata({
                          ...imgquedata,
                          image_position: e.target.value,
                        });
                        setIsSaveDisable(false);
                      }}
                    />
                  </div>
                  {(imgquedata.image_position === "center" ||
                    imgquedata.image_position === "top") && (
                    <div className="mx-2 mt-4 ">
                      <div style={{ fontSize: "18px" }}> Image width(px)</div>
                      <div className="image_range">
                        <Range
                          value={imgquedata.image_width}
                          onChange={(e) => {
                            setimgquedata({
                              ...imgquedata,
                              image_width: e.target.value,
                            });
                            setIsSaveDisable(false);
                          }}
                          max={true}
                        />
                      </div>
                    </div>
                  )}
                  {imgquedata.image_position === "background" && (
                    <>
                      <div
                        className="d-flex col-6 overlay_text mx-2 "
                        style={{ alignItems: "center" }}
                      >
                        <input
                          style={{ width: "50px", height: "50px" }}
                          type="color"
                          className="color_button w-52 h-14 mt-1 me-2"
                          onChange={(e) => {
                            setimgquedata({
                              ...imgquedata,
                              background_color: e.target.value,
                            });
                            setIsSaveDisable(false);
                          }}
                          defaultValue={imgquedata?.background_color}
                        ></input>
                        <div className="me-2" style={{ fontSize: "18px" }}>
                          Overlay color
                        </div>
                      </div>
                      <div className="mx-2 mt-4 ">
                        <div style={{ fontSize: "18px" }}> Overlay opacity</div>
                        <div className="image_range">
                          <Range
                            value={imgquedata.background_opacity}
                            onChange={(e) => {
                              setimgquedata({
                                ...imgquedata,
                                background_opacity: e.target.value,
                              });
                              setIsSaveDisable(false);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              <Divider
                style={{ background: "gray" }}
                className="mt-4 "
              ></Divider>
              <div className="mx-2 mt-2">
                <InputComponent
                  label_name={"Tooltip heading"}
                  onChange={(e) => {
                    setimgquedata({
                      ...imgquedata,
                      tooltip_heading: e.target.value,
                    });
                    setIsSaveDisable(false);
                  }}
                />
              </div>
              <div className="mx-2">
                <InputComponent
                  label_name={"Tooltip description"}
                  onChange={(e) => {
                    setimgquedata({
                      ...imgquedata,
                      tooltip_description: e.target.value,
                    });
                    setIsSaveDisable(false);
                  }}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>

        <Grid className="mt-4">
          <span
            style={{
              color: "#93999c",
              fontSize: "18px",
              letterSpacing: "-1px",
            }}
          >
            QUESTION SETTINGS
          </span>
          <Card className="left_second_rcard">
            <CardContent className="infoq_cardcontent">
              <div className="mx-2 ">
                <InputComponent
                  label_name={"Marketing property name (optional)"}
                  onChange={(e) => {
                    setimgquedata({
                      ...imgquedata,
                      property_name: e.target.value,
                    });
                    setIsSaveDisable(false);
                  }}
                />
              </div>
              <div className="d-flex mt-3">
                <Checkbox
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setimgquedata({
                      ...imgquedata,
                      multiselect_answer: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-2 logic_jump">
                  Enable user to select more then one answer
                </Typography>
              </div>
              {imgquedata.multiselect_answer == true &&
                imgansdata.length > 2 && (
                  <div className="m-2 ps-2">
                    <Typography
                      color="text.secondary"
                      className="mt-2 logic_jump"
                    >
                      Limit of selected answers
                    </Typography>
                    <Slider
                      value={
                        imgquedata.answer_limit ? imgquedata.answer_limit : 2
                      }
                      onChange={(e) => {
                        setIsSaveDisable(false);
                        setimgquedata({
                          ...imgquedata,
                          answer_limit: e.target.value,
                        });
                      }}
                      aria-label="Temperature"
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={2}
                      max={imgansdata.length}
                    />
                  </div>
                )}
              {(imgquedata.multiselect_answer == true ||
                imgquedata.skip_que === true) &&
                allquestion.quiz.logic_jump && (
                  <div className="mx-2 mt-4">
                    <LeadtoQuestion
                      setimgquedata={setimgquedata}
                      imgquedata={imgquedata}
                      allQuelist={allQuelist}
                      setIsSaveDisable={setIsSaveDisable}
                      imgansdata={imgansdata}
                      setimgansdata={setimgansdata}
                    />
                  </div>
                )}
              <div className="d-flex ">
                <Checkbox
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setimgquedata({
                      ...imgquedata,
                      skip_que: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-2 logic_jump">
                  Enable ability to skip this questions
                </Typography>
              </div>
              <div className="d-flex ">
                <Checkbox
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setimgquedata({
                      ...imgquedata,
                      hide_progress_bar: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-2 logic_jump">
                  Hide progress bar
                </Typography>
              </div>
              <div className="d-flex ">
                <Checkbox
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setimgquedata({
                      ...imgquedata,
                      dont_show: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Don't show this question while creating the answer paths
                </Typography>
              </div>
              <div className="mx-6 ml-14 opacity-70">
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Enable this option only if this question doesn't affect the
                  recommendations
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid className="mt-4">
          <span
            style={{
              color: "#93999c",
              fontSize: "18px",
              letterSpacing: "-1px",
            }}
          >
            ANSWERS
          </span>
          <Card sx={{ minWidth: 175 }} className="left_radio_card mt-2">
            <CardContent className="infoq_cardcontent">
              <div className="mx-2 ">
                <AccordianTextBox
                  imgquedata={imgquedata}
                  allquestion={allquestion}
                  allQuelist={allQuelist}
                  values={question_type}
                  imgansdata={imgansdata}
                  setimgansdata={setimgansdata}
                  validation={validation}
                  setIsSaveDisable={setIsSaveDisable}
                  ProductData={ProductData}
                  resultLogic={resultLogic}
                  loadingScrollableApi={loadingScrollableApi}
                  hasMore={hasMore}
                  getProduct={getProduct}
                  resultData={resultData}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default LeftTextBoxCard;
