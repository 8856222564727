import React, { useContext, useEffect, useState } from "react";
import Sidenav from "../Sidenav";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import { Backdrop, Chip, CircularProgress, Grid } from "@material-ui/core";
import Buttton from "../Button/Buttton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link, useNavigate, useParams } from "react-router-dom";
import Pagestepper from "./Pagestepper";
import CardIntro from "./CardIntro";
import Logiccard from "./Logiccard";
import { AuthContext } from "../../context/AuthContext";
import { FetchContext } from "../../context/FetchContext";
import CreateQuestionTitle from "../Title/Title";

const Intropage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { authAxios } = useContext(FetchContext);
  const { state } = useContext(AuthContext);
  const quiz = state?.allquiz?.filter((item) => item?._id === id)[0];
  const [quizquestion, setquizquestion] = React.useState([]);
  const [loading, setloading] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      authAxios
        .post(`${process.env.REACT_APP_API_URL}/question/show_question/${id}`)
        .then((res) => {
          console.log("dataaaa---->", res.data);
          setquizquestion(res.data);
          setloading(false);
        })
        .catch((err) => {
          if (err) {
            setloading(false);
          }
        });
    }, 2000);
  }, [id]);

  const preview = () => {
    if (quiz?.disable_intro === true) {
      navigate(`/preview/enable_intropage/${id}`);
    } else {
      navigate(`/preview/${id}`);
    }
  };

  return (
    <Sidenav className="container-fluid">
      {loading ? (
        <div sx={{ display: "flex" }}>
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="success" />
          </Backdrop>
        </div>
      ) : (
        <>
          <CreateQuestionTitle
            link={`/quizzes/allquiz`}
            quiz_name={quiz?.quiz_name}
            is_publish={quiz?.is_publish}
            preview={preview}
            quiz_id={id}
            questions={quizquestion}
          />
          <div className="mt-7 mx-4">
            <Pagestepper />
          </div>
          <div className="mt-12 mx-4 ">
            <CardIntro
              id={id}
              quiz={quizquestion?.quiz}
              quizquestion={quizquestion}
              setquizquestion={setquizquestion}
            />
          </div>
          {/* <div className="mt-12 mx-4">
            <Logiccard quizquestion={quizquestion} />
          </div> */}
        </>
      )}
    </Sidenav>
  );
};

export default Intropage;
