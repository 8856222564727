import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    IconButton,
    InputBase,
    Modal,
    TextField,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState, useEffect } from "react";

const SelectResultWeight = ({
    isEdit,
    imgansdata,
    setimgansdata,
    element,
    index,
    resultProduct,
    setIsSaveDisable,
    loadingScrollableApi,
    resultData
}) => {
    console.log("resultData------>", resultData);
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredResults = resultData.filter((item) => {
        const resultNameMatch = item.result_name.toLowerCase().includes(searchQuery.toLowerCase());
        const productTitleMatch = item.select_products.some((productItem) =>
            productItem.product.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
        return resultNameMatch || productTitleMatch;
    });
    // const result_option = element?.answer?.select_products ? element?.answer?.select_products : resultProduct
    let option_product = []
    if (resultProduct) {
        option_product = resultProduct
    } else {
        option_product = element?.answer?.select_products ?? [];
    }

    const [productopen, setProductOpen] = useState(false);
    const [selectedResult, setSelectedResult] = useState([]);

    const handleProductPopupClose = () => {
        setProductOpen(false)

    };
    useEffect(() => {
        if (isEdit) {
            const resultIds = imgansdata
                .map(item => item.answer.select_result_id)
                .filter(id => id); // Filter out empty or null IDs
            console.log("resultIds---->", resultIds);
            setSelectedResult(resultIds);
        }
    }, []);

    const handleSetResultData = (resultId) => {
        console.log("Result 5------>", resultId);
        setIsSaveDisable(false)
        let newFormValues = [...imgansdata];

        if (isEdit) {
            newFormValues[index].answer.select_result_id = resultId;
        } else {
            newFormValues[index].select_result_id = resultId;
        }
        console.log("newFormValues--------->", newFormValues);
        setimgansdata(newFormValues);
        // setSelectedResult(resultId);
        setSelectedResult(prevState => {
            const newSelectedResult = [...prevState];
            newSelectedResult[index] = resultId;
            return newSelectedResult;
        });
        setProductOpen(false)
    }

    function handleProductPopupOpen(e) {
        setProductOpen(true);
    }

    return (
        <>
            <div className="mt-1 w-full">
                <Typography>Result</Typography>

                <Typography className="mt-2 mb-1">Result ID: {selectedResult.length > 0 && selectedResult[index] !== undefined ? selectedResult[index] : "Not selected"}</Typography>
                <Button
                    className="add_product_btn"
                    variant="outlined"
                    fullWidth
                    onClick={(e) => handleProductPopupOpen(e)}
                    sx={{
                        textTransform: "none",
                        color: "black",
                        border: "1px solid #B1B7C1",
                        fontWeight: "bold",
                        fontSize: "18px",
                    }}
                >
                    {selectedResult.length > 0 && selectedResult[index] !== undefined ? "Change Result" : "Add Result"}
                </Button>
                <div>

                    <Modal
                        open={productopen}
                        onClose={handleProductPopupClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"

                    >
                        <div className="result_modal_result_weight" style={{ width: "900px !important" }}>
                            <Typography
                                id="modal-modal-title"
                                className="product_modal_header"
                            >
                                <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                                    {selectedResult !== "" ? "Change Result" : "Add Result"}
                                </Typography>
                                <Typography>
                                    <IconButton
                                        sx={{ padding: "0px" }}
                                        onClick={handleProductPopupClose}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Typography>
                            </Typography>
                            <Box className="product_modal_box"
                                style={{ height: "45vh", overflow: loadingScrollableApi && "hidden", }}>
                                <div className="product_modal_body">
                                    {/* <Typography
                                        id="modal-modal-description"
                                        sx={{ mt: 2, padding: " 10px 20px" }}
                                    >
                                        Duis mollis, est non commodo luctus, nisi erat porttitor
                                        ligula.
                                    </Typography> */}
                                    <Paper
                                        component="form"
                                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', margin: "8px 8px 5px 8px" }}
                                    >
                                        <IconButton sx={{ p: '10px' }} aria-label="menu">
                                            <SearchIcon />
                                        </IconButton>
                                        <InputBase
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="Search results by name or product title"
                                            inputProps={{ 'aria-label': 'Search results by name or product title' }}
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                    </Paper>

                                    <TableContainer component={Paper} >
                                        <Table sx={{ minWidth: 750 }} aria-label="simple table" >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center" sx={{ fontWeight: "600" }}>Result#</TableCell>
                                                    <TableCell align="center" sx={{ fontWeight: "600" }}>Result name</TableCell>
                                                    <TableCell align="center" sx={{ fontWeight: "600" }}>Result title</TableCell>
                                                    <TableCell align="center" sx={{ fontWeight: "600" }}>Result type</TableCell>
                                                    <TableCell align="center" sx={{ fontWeight: "600" }}>Action&nbsp;</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredResults.map((result, i) => {
                                                    return (
                                                        result.is_fallback == false &&
                                                        <TableRow
                                                            // key={row.name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="center">
                                                                {i < 10 ? i < 1 ? "01" : "0" + i : i}
                                                            </TableCell>
                                                            <TableCell align="center">{result.result_name}</TableCell>
                                                            <TableCell align="center">{result.heading}</TableCell>
                                                            <TableCell align="center">products</TableCell>
                                                            <TableCell align="center">
                                                                <Button
                                                                    variant="outlined"
                                                                    onClick={() => handleSetResultData(result._id, i)}
                                                                    disabled={selectedResult[index] == result._id}
                                                                >
                                                                    {selectedResult[index] !== result._id ? "Select" : "Selected"}
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                        {
                                            (filteredResults.length < 1 || (filteredResults.length ==1 && filteredResults[0]?.is_fallback == true)) &&
                                            <Typography sx={{ textAlign: "center", padding: "127px" }} >No Result Found</Typography>
                                        }
                                    </TableContainer>
                                </div>
                            </Box>
                            <div
                                className="product_modal_footer"
                                style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    padding: "10px 10px",
                                    alignItems: "center",
                                    borderTop: "1px solid #B1B7C1",
                                }}
                            >
                                <Button
                                    onClick={handleProductPopupClose}
                                    className="product_modal_save"
                                    variant="contained"
                                >
                                    Close
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>

        </>
    );
};

export default SelectResultWeight;
