import FileUploadQuestion from "./FileUploadQuestion";
import ImageWithText from "./ImageWithText";
import InfoQuestion from "./InfoQuestion";
import InputQuestion from "./InputQuestion";
import RadioQuestion from "./RadioQuestion";
import RangeSlider from "./RangeSlider";
import SelectQuestion from "./SelectQuestion";
import TextBox from "./TextBox";
import CloseIcon from "@mui/icons-material/Close";
import { Card } from "@material-ui/core";

import React, { useEffect, useState, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { FetchContext } from "../../context/FetchContext";

const PreviewQuestion = () => {
  const {
    state: {
      question: { result, quiz },
    },
  } = useContext(AuthContext);

  console.log("quiz--------->", quiz);

  const { authAxios, dispatch } = useContext(FetchContext);
  const result_json_data = quiz.result_json;
  const { number } = useParams();
  const questionNumber = parseInt(number);
  const [redirectURL, setRedirectURL] = useState("");
  const [redirectPrevURL, setRedirectPrevURL] = useState("");
  const [quesfilldata, setQueFillData] = useState([]);
  const initialState =
    JSON.parse(localStorage.getItem("quizLogicJumpData")) || [];
  const [quiz_logic_jump_data, setQuiz_logic_jump_data] =
    useState(initialState);
  const [questionIndex, setQuestionIndex] = useState("");
  const nextQuestion = result[questionNumber + 1];
  const prevQuestion =
    questionNumber === 0 ? undefined : result[questionNumber - 1];
  const topText = `${questionNumber + 1} of ${result.length}`;
  useEffect(() => {
    localStorage.setItem(
      "quizLogicJumpData",
      JSON.stringify(quiz_logic_jump_data)
    );
  }, [quiz_logic_jump_data]);

  useEffect(() => {
    if (quiz.logic_jump === false) {
      if (nextQuestion) {
        setRedirectURL(`/question/${nextQuestion._id}/${questionNumber + 1}`);
        if (prevQuestion !== undefined) {
          setRedirectPrevURL(
            `/question/${prevQuestion._id}/${questionNumber - 1}`
          );
        } else {
          setRedirectPrevURL("");
        }
      } else {
        setRedirectURL("/subscribe");
        if (prevQuestion !== undefined) {
          setRedirectPrevURL(
            `/question/${prevQuestion._id}/${questionNumber - 1}`
          );
        } else {
          setRedirectPrevURL("");
        }
      }
    } else {
      if (quiz_logic_jump_data.length > 0) {
        let currentQuestionId = JSON.parse(
          localStorage.getItem("quizLogicJumpData")
        )[questionNumber];
        if (currentQuestionId === "result_page") {
          setRedirectURL("/subscribe");
        } else {
          // Check if currentQuestionId is empty and find the next question after the last valid ID
          if (!currentQuestionId) {
            if (nextQuestion?._id === undefined) {
              setRedirectURL("/subscribe");
            } else {
              setRedirectURL(
                `/question/${nextQuestion?._id}/${questionNumber + 1}`
              );
            }
          }
          if (result) {
            const currentQuestion = result?.find(
              (q) => q._id === currentQuestionId
            );
            if (currentQuestion) {
              const currentIndex = result?.indexOf(currentQuestion);
              setRedirectURL(
                `/question/${currentQuestion._id}/${currentIndex}`
              );
            }
          }
        }
      }
    }
  }, [questionNumber, quiz_logic_jump_data]);

  const isBack =
    questionNumber === 0 &&
    JSON.parse(localStorage.getItem("back_action"))?.length === 0
      ? false
      : true;
  return (
    <>
      <Card>
        <div className="d-flex justify-end p-2 mx-3">
          <Link to="/quizzes/allquiz">
            <CloseIcon
              className="m-2 font-semibold text-black"
              style={{ fontSize: "35px" }}
            />
          </Link>
        </div>
      </Card>
      <div
        style={{ overflow: "visible" }}
        className={
          result[questionNumber]?.question_types === "Image with text"
            ? "mt-[0px] mb-[0px]"
            : "mt-[0px] mb-[0px]"
        }
      >
        {
          {
            "Radio buttons": (
              <RadioQuestion
                data={result[questionNumber]}
                redirectPrevURL={redirectPrevURL}
                questionNumber={questionNumber}
                redirectURL={redirectURL}
                topText={topText}
                isBack={isBack}
                result_json_data={result_json_data}
                setQuiz_logic_jump_data={setQuiz_logic_jump_data}
                setQuestionIndex={setQuestionIndex}
                questionIndex={questionIndex}
                quiz={quiz}
                result={result}
              />
            ),
            "Image with text": (
              <ImageWithText
                setQueFillData={setQueFillData}
                quesfilldata={quesfilldata}
                data={result[questionNumber]}
                redirectPrevURL={redirectPrevURL}
                questionNumber={questionNumber}
                redirectURL={redirectURL}
                topText={topText}
                isBack={isBack}
                result_json_data={result_json_data}
                setQuiz_logic_jump_data={setQuiz_logic_jump_data}
                setQuestionIndex={setQuestionIndex}
                questionIndex={questionIndex}
                quiz={quiz}
                result={result}
              />
            ),
            "Text box": (
              <TextBox
                data={result[questionNumber]}
                redirectPrevURL={redirectPrevURL}
                questionNumber={questionNumber}
                redirectURL={redirectURL}
                topText={topText}
                isBack={isBack}
                result_json_data={result_json_data}
                setQuiz_logic_jump_data={setQuiz_logic_jump_data}
                quiz={quiz}
                result={result}
              />
            ),
            Input: (
              <InputQuestion
                data={result[questionNumber]}
                redirectPrevURL={redirectPrevURL}
                questionNumber={questionNumber}
                redirectURL={redirectURL}
                topText={topText}
                isBack={isBack}
                result_json_data={result_json_data}
                setQuiz_logic_jump_data={setQuiz_logic_jump_data}
                quiz={quiz}
                result={result}
              />
            ),
            "Range slider": (
              <RangeSlider
                data={result[questionNumber]}
                redirectPrevURL={redirectPrevURL}
                questionNumber={questionNumber}
                redirectURL={redirectURL}
                topText={topText}
                isBack={isBack}
                result_json_data={result_json_data}
                setQuiz_logic_jump_data={setQuiz_logic_jump_data}
                quiz={quiz}
                result={result}
              />
            ),
            select: (
              <SelectQuestion
                data={result[questionNumber]}
                redirectPrevURL={redirectPrevURL}
                questionNumber={questionNumber}
                redirectURL={redirectURL}
                topText={topText}
                isBack={isBack}
                result_json_data={result_json_data}
                setQuiz_logic_jump_data={setQuiz_logic_jump_data}
                quiz={quiz}
                result={result}
              />
            ),
            info: (
              <InfoQuestion
                data={result[questionNumber]}
                redirectPrevURL={redirectPrevURL}
                questionNumber={questionNumber}
                redirectURL={redirectURL}
                topText={topText}
                isBack={isBack}
                result_json_data={result_json_data}
                setQuiz_logic_jump_data={setQuiz_logic_jump_data}
                quiz={quiz}
                result={result}
              />
            ),
            "File upload": (
              <FileUploadQuestion
                data={result[questionNumber]}
                redirectPrevURL={redirectPrevURL}
                questionNumber={questionNumber}
                redirectURL={redirectURL}
                topText={topText}
                isBack={isBack}
                result_json_data={result_json_data}
                setQuiz_logic_jump_data={setQuiz_logic_jump_data}
                quiz={quiz}
                result={result}
              />
            ),
          }[result[questionNumber]?.question_types]
        }
      </div>
    </>
  );
};

export default PreviewQuestion;
