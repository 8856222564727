import React, { useContext } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Questiontype from "./component/question/question_type/Questiontype";
import IntroQuizPage from "./component/Quizzes/introquizpage/IntroQuizPage";
import RadioTypeQuestion from "./component/question/AddQuestion/radiotypeQuestion/MainpageRadio";
import Mainpageimage from "./component/question/AddQuestion/ImageWithText/Mainpageimage";
import Result from "./component/result/Result";
import MainResult from "./component/result/MainResult";
import PreviewIntroPage from "./component/preview/Preview_Intropage";
import Allquizes from "./component/Quizzes/Allquizes";
import Dashboard from "./component/dashboard/Dashboard";
import Cardmenu from "./component/Quizzes/Cardmenu";
import Intropage from "./component/intropage/Intromainpage";
import { AuthContext } from "./context/AuthContext";
import Authshopify from "./component/authshopify/Authshopify";
import PreviewQuestion from "./component/preview/PreviewQuestion";
import EditIntroQuizPage from "./component/EditAllComponent/introquizpage/EditIntroQuizPage";
import EditImageTypeQuestion from "./component/EditAllComponent/question/EditImageQuestion/EditImageTypeQuestion";
import EditRadioTypeQuestion from "./component/EditAllComponent/question/EditradioQuestion/EditRadioTypeQuestion";
import MainTextBox from "./component/question/AddQuestion/textBox/MainTextBox";
import EditTextBox from "./component/EditAllComponent/question/EditTextBox/EditTextBox";
import MainInputType from "./component/question/AddQuestion/InputType/MainInputType";
import EditInput from "./component/EditAllComponent/question/EditInput/EditInput";
import MainPageRangeSlider from "./component/question/AddQuestion/rangeSlider/MainPageRangeSlider";
import EditRangeSlider from "./component/EditAllComponent/question/EditrangerQuestion/EditRangeSlider";
import MainSelectCard from "./component/question/AddQuestion/SelectType/MainSelectCard";
import EditSelectCard from "./component/EditAllComponent/question/EditSelect/EditSelectCard";
import MainInfoType from "./component/question/AddQuestion/InfoType/MainInfoType";
import EditInfo from "./component/EditAllComponent/question/EditInfo/EditInfo";
import FileUpload from "./component/question/AddQuestion/FileUpload/FileUpload";
import EditFileUpload from "./component/EditAllComponent/question/EditFileUpload/EditFileUpload";
import Subscribe from "./component/preview/Subscribe";
import RegisterForm from "./component/loginsystem/RegisterForm";
import Loginform from "./component/loginsystem/Loginform";
import ForgoteForm from "./component/loginsystem/ForgoteForm";
import ResetPassword from "./component/loginsystem/ResetPassword";
import Analytic from "./component/Analytic/Analytic";
import ResultLayout from "./component/preview/ResultLayout";
import IntroResultPage from "./component/result/IntroResultPage";
import Settings from "./component/settings/Settings";
import PreviewEnableIntropage from "./component/preview/PreviewEnableIntroPage";
import Publish from "./component/publish/Publish";
import Billing from "./component/Billing/Billing";
import Sidenav from "./component/Sidenav";

const Privateroute = () => {
  const { state, dispatch } = useContext(AuthContext);
  const searchParams = new URLSearchParams(window.location.search);
  const shop = searchParams.get('shop');
  const routepath = window.location.pathname.split("/");
  if (routepath[1] == "authentication") {
    dispatch({ type: "LOGOUT" });
  }

  return (
    <>
      <Router>
        <Routes>        
          {state.isAuthenticated ? (
            <>
            
              {shop !== null &&
                <Route path="/" element={<Loginform />} />
              }
              <Route path="/quizzes/allquiz" element={<Allquizes />} />
              <Route path="/Analytic/analytic" element={<Analytic />} />
              <Route path="/quizzes/intropage/:id" element={<Intropage />} />
              {/* <Route
                path="/quizzes/Intro_quizpage/:id"
                element={<IntroQuizPage />}
              /> */}
              <Route path="/billing" element={<Billing />} />
              <Route
                path="/edit/quizzes/Intro_quizpage/:id"
                element={<EditIntroQuizPage />}
              />
              <Route path="/questiontype/:id" element={<Questiontype />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route
                path="/quiz/Radio_question/add/:id"
                element={<RadioTypeQuestion />}
              />
              <Route
                path="/edit/quiz/Radio_question/:id"
                element={<EditRadioTypeQuestion />}
              />
              <Route
                path="/quiz/text_box_question/add/:id"
                element={<MainTextBox />}
              />
              <Route
                path="/edit/quiz/text_box_question/:id"
                element={<EditTextBox />}
              />
              <Route
                path="/quiz/input_question/add/:id"
                element={<MainInputType />}
              />
              <Route
                path="/edit/quiz/input_question/:id"
                element={<EditInput />}
              />
              <Route
                path="/quiz/image_question/add/:id"
                element={<Mainpageimage />}
              />
              <Route
                path="/edit/quiz/image_question/:id"
                element={<EditImageTypeQuestion />}
              />
              <Route
                path="/quiz/range_question/add/:id"
                element={<MainPageRangeSlider />}
              />
              <Route
                path="/edit/quiz/range_question/:id"
                element={<EditRangeSlider />}
              />
              <Route
                path="/quiz/select_question/add/:id"
                element={<MainSelectCard />}
              />
              <Route
                path="/edit/quiz/select_question/:id"
                element={<EditSelectCard />}
              />
              <Route
                path="/quiz/info_question/add/:id"
                element={<MainInfoType />}
              />
              <Route
                path="/edit/quiz/info_question/:id"
                element={<EditInfo />}
              />
              <Route
                path="/quiz/file_upload_question/add/:id"
                element={<FileUpload />}
              />
              <Route
                path="/edit/quiz/file_upload_question/:id"
                element={<EditFileUpload />}
              />
              <Route path="/quiz/card_menu" element={<Cardmenu />} />
              <Route
                path="/quiz/resultlayout/:id/:submission_id"
                element={<ResultLayout />}
              />
              <Route
                path="/quiz/mainresult/:id/:result_id"
                element={<MainResult />}
              />
              <Route
                path="/quiz/introresultpage/:id"
                element={<IntroResultPage />}
              />

              <Route path="/preview/:id" element={<PreviewIntroPage />} />
              <Route
                path="/preview/enable_intropage/:id"
                element={<PreviewEnableIntropage />}
              />
              <Route
                exact
                path="/question/:id/:number"
                element={<PreviewQuestion />}
              />
              <Route exact path="/subscribe" element={<Subscribe />} />
              <Route exact path="/settings/:id" element={<Settings />} />
              <Route exact path="/publish/:id" element={<Publish />} />

              <Route exact path="/*" element={<Dashboard />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Loginform />} />
              <Route path="/register" element={<RegisterForm />} />
              <Route path="/authentication" element={<Authshopify />} />
              <Route path="/forgot-pass" element={<ForgoteForm />} />
              <Route path="/reset-password/:id" element={<ResetPassword />} />
              <Route exact path="/*" element={<Authshopify />} />
            </>
          )}
        </Routes>
      </Router>
    </>
  );
};

export default Privateroute;
