import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import "./radio.css";
const radio_component = ({ value, onChange }) => {
  console.log("valueeeeeeeeeeee",value);
  const radio_value = value ? value : "Image"
  console.log("radio_value-------------------->",radio_value);
  
  return (
    <FormControl>
      <RadioGroup
        style={{ marginLeft: "-10px" }}
        value={value ? value : "Image"}
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={value ? value : "Color"}
        name="radio-buttons-group"
        onChange={onChange}
      >
        <FormControlLabel
          value="Image"
          className="radio_label"
          control={<Radio />}
          label="Image"
        />
        <FormControlLabel
          value="Color"
          className="radio_label"
          control={<Radio />}
          label="Color"
        />
      </RadioGroup>
    </FormControl>
  );
};
export default radio_component;
