import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FormControlLabel, Grid, Radio, Tooltip } from "@mui/material";
import { CButton, CFormLabel } from "@coreui/react";
import InfoIcon from "@mui/icons-material/Info";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { useParams } from "react-router-dom";

const EditRightInfoCard = ({
  imgquedata,
  question_add_an_iimage,
  question_show_iimage,
  allquestion,
}) => {
  let image_url =
    question_show_iimage.length !== 0
      ? question_show_iimage
      : `${process.env.REACT_APP_API_URL}/question/${imgquedata?.question_add_an_iimage}`;
  console.log("image_url", image_url);

  const { id } = useParams();
  const question_index = allquestion?.result?.findIndex(
    (item) => item._id === id
  );
  let backgroundImg;
  if (
    question_add_an_iimage?.length === 0 &&
    imgquedata?.question_add_an_iimage != 0
  ) {
    if (imgquedata.question_add_an_iimage) {
      backgroundImg = `url(${
        process.env.REACT_APP_API_URL
      }/question/${encodeURIComponent(imgquedata.question_add_an_iimage)})`;
    }
  } else if (question_show_iimage) {
    backgroundImg = `url(${question_show_iimage})`;
  } else {
    backgroundImg = "";
  }

  const containerStyle = {
    position: "relative",
    width: "100%",
    // height: '100vh',
    backgroundImage: backgroundImg,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: `${imgquedata?.background_color}`,
    opacity: `${imgquedata.background_opacity}%`,
  };
  return (
    <>
      <Grid item xs={12} sm={12} lg={8} md={12} spacing={3} className="mt-4">
        <Card
          sx={imgquedata.image_position === "background" && containerStyle}
          className="right_quiz_card"
        >
          {imgquedata.image_position === "background" && (
            <div style={overlayStyle} className="backDiv"></div>
          )}
          <CardContent>
            <Grid className="image_card_top">
              {!imgquedata.hide_progress_bar && (
                <Typography
                  variant="h6"
                  className="question_length"
                  component="div"
                >
                  {question_index + 1 + " of " + allquestion?.result?.length}
                </Typography>
              )}
              {(imgquedata.image_position === "top" ||
                imgquedata.image_position === "left") &&
                (question_show_iimage.length !== 0 ||
                  imgquedata?.question_add_an_iimage) && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={image_url}
                      width={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}
              <Typography sx={{ mb: 1 }} className="right_imgque_heading">
                {imgquedata.heading}
              </Typography>
              <Typography sx={{ mb: 1.5 }} className="right_imgque_subheading">
                {imgquedata.subheading}
              </Typography>
              {imgquedata.image_position === "center" &&
                (question_show_iimage.length !== 0 ||
                  imgquedata?.question_add_an_iimage) && (
                  <div
                    className="mt-2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      src={image_url}
                      width={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}

              <div
                className="option_main_contain"
                style={{
                  margin:
                    imgquedata.box_size == "small"
                      ? "15px 140px"
                      : "15px 270px",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  sx={{ display: "flex" }}
                >
                  <div className={"d-flex mt-4 center_small_image_box"}>
                    {imgquedata?.placeholder ? (
                      <CButton className="info_button  mt-4">
                        {imgquedata?.placeholder}
                      </CButton>
                    ) : null}
                  </div>
                  {imgquedata?.placeholder == "" && (
                    <Grid className="col-12 ml-24 mt-16 d-flex">
                      <Grid className="col-6 back text-center">
                        <WestIcon /> <span className="mx-2"> BACK </span>
                      </Grid>
                      <Grid className="col-3 next">
                        <span className="mx-2 "> NEXT </span> <EastIcon />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </div>

              {imgquedata.image_position === "right" &&
                (question_show_iimage.length !== 0 ||
                  imgquedata?.question_add_an_iimage) && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={image_url}
                      width={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "40px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "40px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}

              {/* 
              {imgquedata.image_position !== "top" && (
                <>
                  <Typography sx={{ mb: 1.5 }} className="right_imgque_heading">
                    {imgquedata.heading}
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    className="right_imgque_subheading"
                  >
                    {imgquedata.subheading}
                  </Typography>
                </>
              )}
              {imgquedata.image_position !== "background" && (
                <div
                  className={
                    imgquedata.image_position === "center" ||
                    imgquedata.image_position === "top"
                      ? "center_position"
                      : imgquedata.image_position === "right"
                      ? "right_position"
                      : "right_imgmain_image"
                  }
                >
                  {question_show_iimage &&
                  question_add_an_iimage?.length !== 0 ? (
                    imgquedata.image_position == "center" ||
                    imgquedata.image_position === "top" ? (
                      <img
                        src={question_show_iimage}
                        width={`${790 - imgquedata.image_width}px`}
                        height={`${790 - imgquedata.image_width}px`}
                      />
                    ) : (
                      <img
                        src={question_show_iimage}
                        width={`790px`}
                        height={`790px`}
                      />
                    )
                  ) : question_add_an_iimage?.length === 0 &&
                    imgquedata?.question_add_an_iimage != 0 ? (
                    imgquedata.image_position === "center" ||
                    imgquedata.image_position === "top" ? (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/question/${imgquedata?.question_add_an_iimage}`}
                        width={`${790 - imgquedata.image_width}px`}
                        height={`${790 - imgquedata.image_width}px`}
                      />
                    ) : (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/question/${imgquedata?.question_add_an_iimage}`}
                        width={`790px`}
                        height={`790px`}
                      />
                    )
                  ) : question_add_an_iimage.length === 0 &&
                    question_show_iimage.length === 0 ? (
                    ""
                  ) : (
                    ""
                  )}
                </div>
              )}
              {imgquedata.image_position === "top" && (
                <>
                  <Typography sx={{ mb: 1.5 }} className="right_imgque_heading">
                    {imgquedata.heading}
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    className="right_imgque_subheading"
                  >
                    {imgquedata.subheading}
                  </Typography>
                </>
              )}
              <div className={"d-flex mt-4 center_small_image_box"}>
                {imgquedata?.placeholder ? (
                  <CButton className="info_button  mt-4">
                    {imgquedata?.placeholder}
                  </CButton>
                ) : null}
              </div>
              {imgquedata?.placeholder === "" && (
                <Grid className="col-12 ml-24 mt-16 d-flex">
                  <Grid className="col-6 back text-center">
                    <WestIcon /> <span className="mx-2"> BACK </span>
                  </Grid>
                  <Grid className="col-3 next">
                    <span className="mx-2 "> NEXT </span> <EastIcon />
                  </Grid>
                </Grid>
              )}

               */}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default EditRightInfoCard;
