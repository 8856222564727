import { Box, Chip, Grid } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Buttton from "../../../Button/Buttton";
import Sidenav from "../../../Sidenav";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Pagestepper from "../../../intropage/Pagestepper";
import Questionsecondcard from "../Questionsecondcard";
import { FetchContext } from "../../../../context/FetchContext";
import { AuthContext } from "../../../../context/AuthContext";
import LeftRangeCard from "./LeftRangeCard";
import RightRangeCard from "./RightRangeCard";
import { toast } from "react-hot-toast";
import CreateQuestionTitle from "../../../Title/Title";

const MainPageRangeSlider = () => {
  const { id } = useParams();
  const { authAxios } = useContext(FetchContext);
  const { state, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const quiz = state?.allquiz?.filter((item) => item._id === id)[0];
  const [question_add_rimage, setquestion_add_rimage] = useState([]);
  const [allquestion, setallquestion] = React.useState([]);
  const [question_show_rimage, setquestion_show_rimage] = React.useState([]);
  const [validation, setvalidation] = React.useState(false);
  const [isSaveDisable, setIsSaveDisable] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [resultLogic, setResultLogic] = useState();
  const [allQuelist, setAllQueList] = useState([]);

  const [imgquedata, setimgquedata] = React.useState({
    heading: "",
    question_types: "Range slider",
    subheading: "",
    image_width: "",
    image_position: "center",
    tooltip_heading: "",
    tooltip_description: "",
    multiselect_answer: false,
    skip_que: false,
    dont_show: false,
    hide_progress_bar: false,
    property_name: "",
    answer_limit: 2,
    leads_to_id: "",
    background_opacity: 0,
    background_color: "#ffffff",
  });

  const preview = () => {
    if (quiz.disable_intro  === true) {
        navigate(`/preview/enable_intropage/${id}`);
      } else {
        navigate(`/preview/${id}`);
      }  };

  const [imgansdata, setimgansdata] = React.useState([
    {
      title: "",
      select_products: [],
      product_point: "",
      leads_to_id: "",
      custom_redirect_url: false,
      url: "",
      valid: true,
      url_valid: true,
      select_result_id:""
    },
    {
      title: "",
      select_products: [],
      product_point: "",
      leads_to_id: "",
      custom_redirect_url: false,
      url: "",
      valid: true,
      url_valid: true,
      select_result_id:""
    },
  ]);

  React.useEffect(() => {
    authAxios
      .post(`${process.env.REACT_APP_API_URL}/question/show_question/${id}`, id)
      .then((res) => {
        setResultLogic(res.data.quiz.result_json.result_logic);
        setallquestion(res.data);
        const allQuestion_list = res.data.result;
        const result = allQuestion_list.map((item, index) => ({
          label: `Question ${index + 1}. ${item.heading}`,
          id: item._id,
        }));
        setAllQueList(result);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }, [id]);

  const questionsave = () => {
    if (imgansdata.length > 0) {
      let updatedImgquedata = { ...imgquedata };
      if (imgquedata.answer_limit > imgansdata.length) {
        updatedImgquedata.answer_limit = imgansdata.length;
      }
      setimgquedata(updatedImgquedata);
      const formData = new FormData();
      formData.append("imgquedata", JSON.stringify(imgquedata));
      formData.append("imgansdata", JSON.stringify(imgansdata));
      formData.append("question_add_rimage", question_add_rimage); //append the values with key, value pair

      if (imgquedata.heading === "") {
        toast.error("Question heading can't be blank ");
        setvalidation(true);
      } else{

        if (
          imgansdata.filter((item, index) => item.valid === true).length !== 0
        ) {
          toast.error("Title can't be blank ");
        } else if (
          imgansdata.filter((item, index) => item.url_valid === true).length !== 0
        ) {
          toast.error("Url can't be blank ");
        }
        // console.log("saved");
        else {
          setLoading(true);
          authAxios
            .post(
              `${process.env.REACT_APP_API_URL}/question/create_question_answer/${id}`,
              formData
            )
            .then((res) => {
              if (res.data.msg === "success") {
                setLoading(false);
                setimgquedata(res.data);
                navigate(`/quizzes/intropage/${id}`);
              }
            })
            .catch((err) => {
              if (err) {
                setLoading(false);
                toast.error(err);
                console.log(err);
              }
            });
        }
      }
    } else {
      toast.error("Please add at least one answer or field");
    }
  };

  return (
    <>
      <Sidenav>
        <CreateQuestionTitle
          link={`/questiontype/${id}`}
          quiz_name={quiz?.quiz_name}
          is_publish={quiz?.is_publish}
          preview={preview}
          questionsave={questionsave}
          isSaveDisable={isSaveDisable}
          loading={loading}
          questions={allquestion}
        />

        <div className="mt-4 mx-4">
          <Pagestepper />
        </div>
        <div className="mt-4 mx-4">
          <Questionsecondcard
            allquestion={allquestion}
            setallquestion={setallquestion}
          />
        </div>
        <Box className="d-flex mx-4">
          <Grid container spacing={3}>
            <LeftRangeCard
              imgquedata={imgquedata}
              setimgquedata={setimgquedata}
              setquestion_show_rimage={setquestion_show_rimage}
              imgansdata={imgansdata}
              setimgansdata={setimgansdata}
              question_add_rimage={question_add_rimage}
              setquestion_add_rimage={setquestion_add_rimage}
              validation={validation}
              setvalidation={setvalidation}
              question_show_rimage={question_show_rimage}
              setIsSaveDisable={setIsSaveDisable}
              resultLogic={resultLogic}
              allquestion={allquestion}
              allQuelist={allQuelist}
            />
            <RightRangeCard
              imgquedata={imgquedata}
              question_show_rimage={question_show_rimage}
              imgansdata={imgansdata}
              question_add_rimage={question_add_rimage}
              allquestion={allquestion}
            />
          </Grid>
        </Box>
      </Sidenav>
    </>
  );
};

export default MainPageRangeSlider;
