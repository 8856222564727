import * as React from "react";
import * as ReactDOM from "react-dom";
import { Stepper } from "@progress/kendo-react-layout";
import { CCard } from "@coreui/react";
import { Card } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";

const items = [
  {
    label: "Questions",
  },
  {
    label: "Results",
  },
  {
    label: "Settings",
  },
  {
    label: "Publish",
  },
  {
    label: "Personalization",
  },
];

const Pagestepper = ({ result, setting, quiz, publish }) => {
  var { id } = useParams();
  if (quiz?._id !== undefined) {
    id = quiz?._id;
  }
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (result) {
      setValue(1);
    }
    if (setting) {
      setValue(2);
    }
    if (publish) {
      setValue(3);
    }
  }, [result, setting]);

  const handleChange = (e) => {
    setValue(e.value);
    if (e.value === 0) {
      navigate(`/quizzes/intropage/${id}`);
    }
    if (e.value === 1) {
      navigate(`/quiz/introresultpage/${id}`);
      // navigate(`/quiz/mainresult/${id}`);
    }
    if (e.value === 2) {
      navigate(`/settings/${id}`);
      // navigate(`/quiz/mainresult/${id}`);
    }
    if (e.value === 3) {
      navigate(`/publish/${id}`);
      // navigate(`/quiz/mainresult/${id}`);
    }
  };

  return (
    <>
      <Card style={{ borderRadius: "10px" }}>
        <Stepper
          className="stepper p-4"
          value={value}
          onChange={handleChange}
          items={items}
        />
      </Card>
    </>
  );
};

export default Pagestepper;
