import {
  Checkbox,
  Container,
  FormHelperText,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import React, { useState } from "react";

const AnswerLayout = ({
  data,
  value,
  setValue,
  nextBtn,
  setNextBtn,
  emailError,
  setEmailError,
  phoneError,
  setPhoneError,
  checked,
  setChecked,
}) => {

  const handleAgreeChecked = (event) => {
    setChecked(event.target.checked);
    if (data?.required_submitting_checkbox == true && value.length !== 0) {
      setNextBtn(!nextBtn);
    }
  };
  const handleAnsValue = (e) => {
    console.log("eeeee", e);
    setValue(e);

    if (data?.required_submitting_checkbox == true) {
      if (e.length !== 0 && checked) {
        setEmailError("");
        setPhoneError("");
        setNextBtn(false);
      } else {
        setNextBtn(true);
      }
    } else {
      if (e.length !== 0) {
        setEmailError("");
        setPhoneError("");
        setNextBtn(false);
      } else {
        setNextBtn(true);
      }
    }
  };
  return (
    <>
      <Container>
        {
          {
            Text: (
              <>
                {!data?.email_validation && !data?.number_validation && (
                  <TextField
                    variant="outlined"
                    value={value}
                    onChange={(e) => handleAnsValue(e.target.value)}
                    type={"text"}
                    className="email-input"
                    placeholder={data?.placeholder}
                  />
                )}
                {data?.email_validation === true && (
                  <TextField
                    error={emailError}
                    value={value}
                    onChange={(e) => handleAnsValue(e.target.value)}
                    type={"email"}
                    helperText={emailError || ""}
                    className="email-input"
                    placeholder={data?.placeholder}
                  />
                )}
                {data?.number_validation === true && (
                  <>
                    <div style={{textAlign:"center"}}>
                      {console.log("phoneError--------->", phoneError)}
                      <TextField
                        error={phoneError}
                        value={value}
                        onChange={(e) => handleAnsValue(e.target.value)}
                        type={"number"}
                        inputProps={{ maxLength: 5 }}
                        className="email-input"
                        placeholder={data?.placeholder}
                      />
                      {
                        <FormHelperText sx={{textAlign:"center",color:"red"}} id="my-helper-text">{phoneError}</FormHelperText>
                      }
                    </div>
                  </>
                )}
                {data?.add_gdpr_checkbox && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Checkbox checked={checked} onChange={handleAgreeChecked} />
                    <Typography
                      color="text.secondary"
                      className="mt-2 logic_jump"
                    >
                      {data?.required_agree_text == ""
                        ? "I agree to receive personalized marketing emails"
                        : data?.required_agree_text}
                    </Typography>
                  </div>
                )}
              </>
            ),
            "Text Area": (
              <TextareaAutosize
                value={value}
                onChange={(e) => handleAnsValue(e.target.value)}
                minRows={5}
                placeholder={data?.placeholder}
                className="textarea_que_preview"
              // style={{ width: "80%", padding: "10px 15px" }}
              />
            ),
            Date: (
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={value ? dayjs(value) : dayjs(new Date())}
                    placeholder={data?.placeholder}
                    onChange={(e) => handleAnsValue(e)}
                  />
                </LocalizationProvider>
              </>
            ),
          }[data?.question_layout_style]
        }
      </Container>
    </>
  );
};

export default AnswerLayout;
