import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";
import AnswerLayout from "./AnswerLayout";
import dayjs from "dayjs";

const InputQuestion = ({
  data,
  questionNumber,
  redirectPrevURL,
  redirectURL,
  topText,
  isBack,
  result_json_data,
  setQuiz_logic_jump_data,
  quiz,
  result,
}) => {
  console.log("data---->", data);

  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [nextBtn, setNextBtn] = useState(true);
  const [value, setValue] = useState("");
  console.log("value of number", value.length);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    setQuiz_logic_jump_data((prevData) => {
      const newData = [...prevData];
      newData[questionNumber] = data.leads_to_id;
      return newData;
    });
  }, [data]);

  const validateNumber = (number) => {
    return !isNaN(number) && Number.isFinite(parseFloat(number));
  };

  function localSorageData() {
    var valueArr = JSON.parse(localStorage.getItem("quizData"));
    if (valueArr && valueArr[questionNumber]?.answerId) {
      console.log(
        "valueArr[questionNumber]?.answerId",
        valueArr[questionNumber]?.answerId
      );
      setValue(valueArr[questionNumber].answerId);
      setChecked(true);
      setNextBtn(false);
    }
  }
  const handleRedirect = (dir) => {
    if (localStorage.getItem("quizData")) {
      var valueArr = JSON.parse(localStorage.getItem("quizData"));
    } else {
      var valueArr = {};
    }
    valueArr[`${questionNumber}`] = {
      questionId: data._id,
      answerId: value,
      answerIds: null,
      resultWeightIds: null,
      result_logic: result_json_data.result_logic,
      product_weight: null,
      product_point: null,
      quizId: data.quiz_id,
    };

    if (data.email_validation) {
      if (validateEmail(value)) {
        localStorage.setItem("quizData", JSON.stringify(valueArr));
        setValue("");
        setNextBtn(true);
        navigate(redirectURL);
      } else {
        setEmailError("Please enter a valid email address.");
        navigate(redirectPrevURL);
      }
    } else if (data.number_validation) {
      if (validateNumber(value) && value.length <= 12 && value.length >= 7) {
        localStorage.setItem("quizData", JSON.stringify(valueArr));
        setValue("");
        setNextBtn(true);
        navigate(redirectURL);
      } else {
        setPhoneError(
          "Please enter a valid number with a length between 7 and 12 characters."
        );
      }
    } else {
      localStorage.setItem("quizData", JSON.stringify(valueArr));
      setValue("");
    }
    if (dir) {
      if (data.number_validation && (value.length > 12 || value.length < 7)) {
        setPhoneError(
          "Please enter a valid number with a length between 7 and 12 characters."
        );
      } else {
        if (data.multiselect_answer === true) {
          let backActionArr = localStorage.getItem("back_action")
            ? JSON.parse(localStorage.getItem("back_action"))
            : [];

          if (data.leads_to_id) {
            backActionArr.push(data.leads_to_id);
          } else {
            if (result.length - 1 > questionNumber) {
              backActionArr.push(result[questionNumber + 1]?._id);
            }
          }

          localStorage.setItem("back_action", JSON.stringify(backActionArr));
        } else {
          let backActionArr = localStorage.getItem("back_action")
            ? JSON.parse(localStorage.getItem("back_action"))
            : [];

          if (result.length - 1 > questionNumber) {
            backActionArr.push(result[questionNumber + 1]?._id);
          }

          localStorage.setItem("back_action", JSON.stringify(backActionArr));
        }
        navigate(redirectURL);
      }
    } else {
      if (quiz.logic_jump === true) {
        const back_action_arr = JSON.parse(localStorage.getItem("back_action"));
        const question_id = back_action_arr.pop();
        const secondLastId =
          back_action_arr.length !== 0
            ? back_action_arr[back_action_arr.length - 1]
            : result[0]?._id;
        localStorage.setItem("back_action", JSON.stringify(back_action_arr));
        const prevQuestionIndex = result.findIndex(
          (question) => question._id === secondLastId
        );
        const prevQuestionObject = result.find(
          (question) => question._id === secondLastId
        );

        navigate(`/question/${prevQuestionObject?._id}/${prevQuestionIndex}`);
      } else {
        navigate(redirectPrevURL);
      }
    }
  };
  function handleSkipRedirect(dir) {
    if (localStorage.getItem("quizData")) {
      var valueArr = JSON.parse(localStorage.getItem("quizData"));
    } else {
      var valueArr = {};
    }
    valueArr[`${questionNumber}`] = {
      questionId: data._id,
      answerId: null,
      answerIds: null,
      resultWeightIds: null,
      result_logic: result_json_data.result_logic,
      product_weight: null,
      product_point: null,
      quizId: data.quiz_id,
    };
    localStorage.setItem("quizData", JSON.stringify(valueArr));
    setValue("");
    if (dir) {
      let backActionArr = localStorage.getItem("back_action")
        ? JSON.parse(localStorage.getItem("back_action"))
        : [];

      if (data.leads_to_id) {
        backActionArr.push(data.leads_to_id);
      } else {
        if (result.length - 1 > questionNumber) {
          backActionArr.push(result[questionNumber + 1]?._id);
        }
      }
      localStorage.setItem("back_action", JSON.stringify(backActionArr));
      const skipQuestionIndex = result.findIndex(
        (question) => question._id === data.leads_to_id
      );
      const skipQuestionObject = result.find(
        (question) => question._id === data.leads_to_id
      );
      if (quiz.logic_jump === true) {
        if (data.leads_to_id === "result_page") {
          navigate("/subscribe");
        } else if (!data.leads_to_id) {
          if (result.length - 1 > questionNumber) {
            navigate(
              `/question/${result[questionNumber + 1]?._id}/${
                questionNumber + 1
              }`
            );
          } else {
            navigate("/subscribe");
          }
        } else {
          navigate(`/question/${skipQuestionObject?._id}/${skipQuestionIndex}`);
        }
      } else {
        navigate(redirectURL);
      }
    } else {
      navigate(redirectPrevURL);
    }
  }

  const ManageQuestions = (value) => {
    return (
      <>
        <Grid container justifyContent="center" mb={2}>
          {isBack ? (
            <Grid item xs>
              <Button
                onClick={() => handleRedirect(false)}
                className="btn-secondary"
                startIcon={<ArrowBackIcon />}
              >
                <b>Back</b>
              </Button>
            </Grid>
          ) : null}
          {data.skip_que && (
            <Grid item xs>
              <Button
                onClick={() => handleSkipRedirect(true)}
                className="btn-secondary"
              >
                <b>Skip</b>
              </Button>
            </Grid>
          )}
          {nextBtn ? (
            <Grid item xs>
              <Button disabled endIcon={<ArrowForwardIcon />}>
                <b>Next</b>
              </Button>
            </Grid>
          ) : (
            <Grid item xs>
              <Button
                onClick={() => handleRedirect(true)}
                className="btn-secondary"
                endIcon={<ArrowForwardIcon />}
              >
                <b>Next</b>
              </Button>
            </Grid>
          )}
        </Grid>
      </>
    );
  };
  const TooltipComponent = () => {
    return (
      <>
        {data.tooltip_heading !== null && (
          <Container sx={{ paddingBottom: 10 }}>
            <Tooltip
              arrow
              sx={{ backgroundColor: "none" }}
              title={
                <Grid>
                  <h6 style={{ textAlign: "center" }}>
                    {data.tooltip_heading}
                  </h6>
                  <p>{data.tooltip_description}</p>
                </Grid>
              }
            >
              <IconButton>
                <ReportRoundedIcon sx={{ height: 50, width: 50 }} />
              </IconButton>
            </Tooltip>
          </Container>
        )}
      </>
    );
  };
  useEffect(() => {
    localSorageData();
  }, [questionNumber]);
  return (
    <>
      {data.image_position == "right" && (
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6} sm={12} sx={{ width: "100%" }}>
            <Grid
              className="radioContainer"
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "0px !important",
                height: "100%",
              }}
            >
              <Grid style={{ width: "100%" }}>
                <Grid sx={{ marginInline: "5px" }}>
                  <Typography variant="body1" color="inherit">
                    {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
                  </Typography>
                  <Typography mt={3} variant="h4" color="inherit">
                    {data.heading}
                  </Typography>
                  <Typography mt={3} variant="h6" color="inherit">
                    {data.subheading}
                  </Typography>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid
                    item
                    xs={12}
                    sm={7}
                    md={10}
                    lg={7}
                    sx={{ paddingBlock: "20px" }}
                  >
                    <AnswerLayout
                      data={data}
                      emailError={emailError}
                      setEmailError={setEmailError}
                      phoneError={phoneError}
                      setPhoneError={setPhoneError}
                      nextBtn={nextBtn}
                      setNextBtn={setNextBtn}
                      value={value}
                      setValue={setValue}
                      setChecked={setChecked}
                      checked={checked}
                    />
                  </Grid>
                </Grid>
                <TooltipComponent />
                <ManageQuestions />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sm={12}
            // sx={{
            //   minHeight: "100vh",
            //   display: "flex",
            //   alignItems: "center",
            // }}
          >
            <div className="left-structure-img">
              <img
                src={`${process.env.REACT_APP_API_URL}/question/${data?.question_add_an_iimage}`}
                alt=""
                srcset=""
              />
            </div>
          </Grid>
        </Grid>
      )}
      {data.image_position == "left" && (
        <Grid container justifyContent="center">
          <Grid
            item
            xs={12}
            md={6}
            sm={12}
            // sx={{
            //   minHeight: "100vh",
            //   display: "flex",
            //   alignItems: "center",
            // }}
          >
            <div className="left-structure-img">
              <img
                src={`${process.env.REACT_APP_API_URL}/question/${data?.question_add_an_iimage}`}
                alt=""
                srcset=""
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} sm={12} sx={{ width: "100%" }}>
            <Grid
              className="radioContainer"
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "0px !important",
                height: "100%",
              }}
            >
              <Grid sx={{ width: "100%" }}>
                <Grid sx={{ marginInline: "5px" }}>
                  <Typography variant="body1" color="inherit">
                    {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
                  </Typography>
                  <Typography mt={3} variant="h4" color="inherit">
                    {data.heading}
                  </Typography>
                  <Typography mt={3} variant="h6" color="inherit">
                    {data.subheading}
                  </Typography>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid
                    item
                    xs={12}
                    sm={7}
                    md={10}
                    lg={7}
                    sx={{ paddingBlock: "20px" }}
                  >
                    <AnswerLayout
                      data={data}
                      emailError={emailError}
                      setEmailError={setEmailError}
                      phoneError={phoneError}
                      setPhoneError={setPhoneError}
                      nextBtn={nextBtn}
                      setNextBtn={setNextBtn}
                      value={value}
                      setValue={setValue}
                      setChecked={setChecked}
                      checked={checked}
                    />
                  </Grid>
                </Grid>
                <TooltipComponent />
                <ManageQuestions />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {data.image_position == "center" && (
        <Grid className="radioContainer" sx={{ marginTop: "0px !important" }}>
          <Grid sx={{ width: "100%" }}>
            <Grid>
              <Typography variant="body1" color="inherit">
                {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
              </Typography>
              <Typography mt={3} variant="h4" color="inherit">
                {data.heading}
              </Typography>
              <Typography mt={3} variant="h6" color="inherit">
                {data.subheading}
              </Typography>
              <div className="d-flex justify-center mt-3">
                <img
                  src={`${process.env.REACT_APP_API_URL}/question/${data?.question_add_an_iimage}`}
                  height={790 - parseInt(data.image_width)}
                  width={790 - parseInt(data.image_width)}
                  alt=""
                  srcset=""
                />
              </div>
            </Grid>
            <Grid container justifyContent="center">
              <Grid
                item
                xs={12}
                sm={7}
                md={7}
                lg={7}
                sx={{ paddingBlock: "20px" }}
              >
                <AnswerLayout
                  data={data}
                  emailError={emailError}
                  setEmailError={setEmailError}
                  phoneError={phoneError}
                  setPhoneError={setPhoneError}
                  nextBtn={nextBtn}
                  setNextBtn={setNextBtn}
                  value={value}
                  setValue={setValue}
                  setChecked={setChecked}
                  checked={checked}
                />
              </Grid>
            </Grid>
            <TooltipComponent />
            <ManageQuestions />
          </Grid>
        </Grid>
      )}
      {data.image_position == "background" && (
        <div
          className="background-image"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_API_URL
            }/question/${encodeURIComponent(data?.question_add_an_iimage)})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "100vh",
            zIndex: "1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "auto",
          }}
        >
          <div
            className="overlay"
            style={{
              position: "absolute",
              inset: "0",
              width: "100%",
              height: "100%",
              zIndex: "2",
              top: "50px",
            }}
          ></div>

          <div
            className="background-image"
            style={{
              position: "absolute",
              top: "50px",
              left: "0",
              width: "100%",
              height: "100%",
              zIndex: "3",
              opacity: `${data.background_opacity}%`,
              backgroundColor: `${data.background_color}`,
            }}
          ></div>
          <Grid
            className="radioContainer center_part_radioContainer background-section-content"
            sx={{ marginTop: "0px !important" }}
          >
            <Grid sx={{ width: "100%", opacity: "1", zIndex: "3" }}>
              <Grid sx={{ marginInline: "5px" }}>
                <Typography variant="body1" color="inherit">
                  {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
                </Typography>
                <Typography mt={3} variant="h4" color="inherit">
                  {data.heading}
                </Typography>
                <Typography mt={3} variant="h6" color="inherit">
                  {data.subheading}
                </Typography>
              </Grid>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={7}
                  lg={7}
                  sx={{ paddingBlock: "20px" }}
                >
                  <AnswerLayout
                    data={data}
                    emailError={emailError}
                    setEmailError={setEmailError}
                    phoneError={phoneError}
                    setPhoneError={setPhoneError}
                    nextBtn={nextBtn}
                    setNextBtn={setNextBtn}
                    value={value}
                    setValue={setValue}
                    setChecked={setChecked}
                    checked={checked}
                  />
                </Grid>
              </Grid>
              <TooltipComponent />
              <ManageQuestions />
            </Grid>
          </Grid>
        </div>
      )}
      {data.image_position == "top" && (
        <Grid className="radioContainer" sx={{ marginTop: "0px !important" }}>
          <Grid sx={{ width: "100%" }}>
            <Typography mt={2} mb={2} variant="body1" color="inherit">
              {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
            </Typography>
            <div className="d-flex justify-center">
              <img
                src={`${process.env.REACT_APP_API_URL}/question/${data?.question_add_an_iimage}`}
                height={790 - parseInt(data.image_width)}
                width={790 - parseInt(data.image_width)}
                alt=""
                srcset=""
              />
            </div>
            <Grid>
              <Typography mt={3} variant="h4" color="inherit">
                {data.heading}
              </Typography>
              <Typography variant="h6" color="inherit">
                {data.subheading}
              </Typography>
            </Grid>
            <Grid container justifyContent="center">
              <Grid
                item
                xs={12}
                sm={7}
                md={7}
                lg={7}
                sx={{ paddingBlock: "20px" }}
              >
                <AnswerLayout
                  data={data}
                  emailError={emailError}
                  setEmailError={setEmailError}
                  phoneError={phoneError}
                  setPhoneError={setPhoneError}
                  nextBtn={nextBtn}
                  setNextBtn={setNextBtn}
                  value={value}
                  setValue={setValue}
                  setChecked={setChecked}
                  checked={checked}
                />
              </Grid>
            </Grid>
            <TooltipComponent />
            <ManageQuestions />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default InputQuestion;
