import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";

export default function Range({ onChange, value,max }) {
  return (
    <Box sx={{ width: 200 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            value={value ? value : 0}
            onChange={onChange}
            aria-labelledby="input-slider"
            max={max ? 600 :100}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
