import React, { useEffect, useState, useContext } from "react";
import Pagestepper from "../intropage/Pagestepper";
import Sidenav from "../Sidenav";
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  Slide,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
import CodeIcon from "@mui/icons-material/Code";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { FetchContext } from "../../context/FetchContext";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { useRef } from "react";
import CreateQuestionTitle from "../Title/Title";
import { AuthContext } from "../../context/AuthContext";

const Publish = () => {
  const { state } = useContext(AuthContext)

  console.log("state==================>", state.allquiz);

  const navigate = useNavigate();
  const preRef = useRef(null);
  const { id } = useParams();
  const { authAxios } = useContext(FetchContext);
  const [loading, setLoading] = useState(true);
  const [quizData, setQuizData] = useState();
  const [isPublish, setPublish] = useState(false);

  const [publishBannerClose, setPublishBannerClose] = useState(true);
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const [embedData, setEmbedData] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  console.log("embedData", embedData);
  const matchQuiz = state.allquiz.find(item => item._id === quizData?._id);
  console.log("matchQuiz---------------------------------------------->", matchQuiz?.questions.length
);
  
  const handleCopy = () => {
    if (preRef.current) {
      const textToCopy = preRef.current.innerText;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setOpenSnackbar(true);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
    setOpenSnackbar(true);
  };
  const handlecopiedClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handlePublishPopupClose = () => {
    setPublishModalOpen(false);
    setPublish(isPublish);
  };

  function handlePublishPopupOpen(event) {
    setPublishModalOpen(true);
    if (!event.target.checked) {
      setPublishBannerClose(true);
    }
  }

  const handlePublishChange = (event) => {
    setPublishModalOpen(false);
    setPublish(!isPublish);
    getPublishData(!isPublish);
  };
  function getPublishData(publish) {
    authAxios
      .post(`${process.env.REACT_APP_API_URL}/publish/publish_quiz`, {
        quizId: id,
        is_publish: publish,
      })
      .then((res) => {
        console.log("res-------->", res);

        setEmbedData(res.data);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }
  function getQuizData() {
    authAxios
      .post(`${process.env.REACT_APP_API_URL}/question/show_question/${id}`)
      .then((res) => {
        console.log("quiz data-----===============--------------->", res.data);
        setPublish(res.data.quiz.is_publish);
        setQuizData(res.data.quiz);
        setLoading(false);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }
  const preview = () => {
    navigate(`/preview/${id}`);
  };
  useEffect(() => {
    setPublishBannerClose(true);
  }, []);

  useEffect(() => {
    getQuizData();
    getPublishData();
  }, [id]);
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={handlecopiedClose}
        message="Copied!"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handlecopiedClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {loading && (
        <div sx={{ display: "flex" }}>
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="success" />
          </Backdrop>
        </div>
      )}
      <div>
        <Modal
          open={publishModalOpen}
          onClose={handlePublishPopupClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="main_modal_div">
            <div
              id="modal-modal-title"
              className="product_modal_header py-2 px-3"
            >
              <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                Publish
              </Typography>
              <Typography>
                <IconButton
                  sx={{ padding: "0px" }}
                  onClick={handlePublishPopupClose}
                >
                  <CloseIcon />
                </IconButton>
              </Typography>
            </div>
            <Box className="">
              <div className="product_modal_body">
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2, padding: " 10px 20px" }}
                >
                  Are you sure want to{" "}
                  <b>{isPublish ? "unpublish" : "publish"}</b>?
                </Typography>
                <div className="publish_modal_body_content"></div>
              </div>
            </Box>
            <div className="product_modal_footer publish_confirm_modal">
              <Button
                onClick={handlePublishPopupClose}
                className="delete_modal_no"
                variant="contained"
              >
                No
              </Button>
              <Button
                onClick={handlePublishChange}
                className="product_modal_save"
                variant="contained"
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
      </div>

      <Sidenav className="container-fluid">
        <CreateQuestionTitle
          link={`/quizzes/allquiz`}
          quiz_name={quizData?.quiz_name}
          is_status={quizData?.is_status}
          preview={preview}
          is_publish={isPublish}
        // questions={question}
        />
        <div className="mt-7 mx-4">
          <Pagestepper publish="publish" />
        </div>
        <div className="mt-12 mx-4">
          <span className="publish_main_title">PUBLISH</span>
          <div className="main_part">
            <Grid className="mt-4" sx={4} lg={4}>
              <Card className="left_second_rcard">
                <div className="main_part mt-3">
                  <div className="active_menu"></div>
                  <div
                    className="left_side_menu p-2 ml-2 mr-5"
                    style={{ backgroundColor: "#F2F2F2" }}
                  >
                    <Typography variant="body1">
                      <CodeIcon color="action" className="code_icon" />
                    </Typography>
                    <Typography variant="body1">
                      Embed with code snippet
                    </Typography>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid className="mt-4" sx={8} lg={8}>
              <div className="right_part_card">
                <Card className="right_card">
                  <CardContent className="infoq_cardcontent">
                    <div className="right_part_draft_card">
                      <Typography variant="body1">
                        This quiz is currently in <b>draft</b>
                      </Typography>
                      <Switch
                        checked={isPublish}
                        onChange={handlePublishPopupOpen}
                        inputProps={{ "aria-label": "controlled" }}
                        disabled={matchQuiz?.questions.length !== 0 ? true : false}
                      />
                    </div>
                    {matchQuiz?.questions.length !== 0 &&
                      <div>
                        <FormHelperText sx={{textAlign:"center", color:"red"}} id="my-helper-text">Atleast One Question Add</FormHelperText>
                      </div>
                    }
                   
                    {!isPublish ? (
                      <div className="alert_warning_banner">
                        <Alert severity="warning" sx={{ borderRadius: "10px" }}>
                          <AlertTitle>
                            <b>Your quiz is in draft</b>
                          </AlertTitle>
                          Publish your quiz to make it visible to your audience.
                        </Alert>
                      </div>
                    ) : (
                      <>
                        {publishBannerClose && (
                          <div className="">
                            <Alert
                              severity="info"
                              sx={{ borderRadius: "10px" }}
                              onClose={() => {
                                setPublishBannerClose(false);
                              }}
                            >
                              <AlertTitle>
                                <b>
                                  Your quiz is ready to be configured on your
                                  store.
                                </b>
                              </AlertTitle>
                              Head over to your online store to add your quiz to
                              your desired page.
                            </Alert>
                          </div>
                        )}
                      </>
                    )}
                  </CardContent>
                </Card>
              </div>
              {!isPublish && (
                <div className="right_part_card">
                  <Card className="right_card">
                    <div className="draft_info_banner">
                      <Alert severity="info" sx={{ borderRadius: "10px" }}>
                        <b>
                          These settings will be available after the quiz is
                          published.
                        </b>
                      </Alert>
                    </div>
                  </Card>
                </div>
              )}
              {isPublish && (
                <div className="right_part_card">
                  <Card className="right_card">
                    <div className="snippet_card">
                      <Typography variant="body1">
                        <b>Embed with code snippet</b>
                      </Typography>
                      <Divider sx={{ margin: "10px 0px" }} />
                      <div className="snippet_contain">
                        <p className="snippet_text">
                          <b>1. Copy the code below</b>
                        </p>
                        <div className="snippet_code_contain">
                          <pre ref={preRef}>{embedData}</pre>
                        </div>
                        <Button
                          fullWidth
                          className="copy_button mt-2"
                          variant="contained"
                          startIcon={
                            <ContentPasteIcon
                              sx={{ fontSize: "18px !important" }}
                            />
                          }
                          onClick={handleCopy}
                        >
                          Copy
                        </Button>

                        <Typography variant="body2" className="mt-2">
                          2. Paste code to any Liquid file in your theme’s code.
                          Make sure to remove this code after app uninstall.
                        </Typography>
                        <Typography variant="body2" className="mt-2">
                          3. Save and preview changes.
                        </Typography>
                      </div>
                    </div>
                  </Card>
                </div>
              )}
            </Grid>
          </div>
        </div>
      </Sidenav>
    </>
  );
};

export default Publish;
