import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

const RangeSlider = ({
  data,
  questionNumber,
  redirectPrevURL,
  redirectURL,
  topText,
  isBack,
  result_json_data,
  setQuiz_logic_jump_data,
  quiz,
  result,
}) => {
  console.log("data----->", data);
  const [value, setValue] = useState("");
  const [active, setActive] = useState([]);
  const [multiValue, setMultiValue] = useState([]);
  const [productWeight, setProductWeight] = useState([]);
  console.log("productWeight--->", productWeight);
  const [productPoint, setProductPoint] = useState(0);
  const [answerIds, setAnswerIds] = useState([]);
  const [resultWeightIds, setResultWeightIds] = useState([]);
  // console.log("answerIds", answerIds);
  console.log("resultWeightIds----->", resultWeightIds);
  const navigate = useNavigate();

  useEffect(() => {
    if (data.multiselect_answer === true) {
      setQuiz_logic_jump_data((prevData) => {
        const newData = [...prevData];
        newData[questionNumber] = data.leads_to_id;
        return newData;
      });
    }
  }, [data]);
  function localSorageData() {
    var valueArr = JSON.parse(localStorage.getItem("quizData"));
    if (valueArr && valueArr[questionNumber]?.answerId) {
      if (data.multiselect_answer) {
        const indices = valueArr[questionNumber]?.answerId
          .map((item) =>
            data.answers.findIndex((answer) => answer.title === item)
          )
          .filter((index) => index !== -1);
        setActive(indices);
        setMultiValue(valueArr[questionNumber].answerId);
      } else {
        const indices = data.answers.reduce((acc, step, idx) => {
          if (step.title === valueArr[questionNumber].answerId) {
            acc.push(idx);
          }
          return acc;
        }, []);
        setValue(valueArr[questionNumber].answerId);
        console.log("indices", indices);
        setActive(indices);
      }
    }
    if (valueArr && valueArr[questionNumber]?.answerIds) {
      setAnswerIds(valueArr[questionNumber]?.answerIds);
    }
    if (valueArr && valueArr[questionNumber]?.product_weight) {
      setProductWeight(valueArr[questionNumber].product_weight);
    }
    if (valueArr && valueArr[questionNumber]?.product_point) {
      setProductPoint(valueArr[questionNumber].product_point);
    }
    if (valueArr && valueArr[questionNumber]?.resultWeightIds) {
      setResultWeightIds(valueArr[questionNumber]?.resultWeightIds);
    }
  }

  function handleAnsValue(item, index) {
    if (data.multiselect_answer === false) {
      setQuiz_logic_jump_data((prevData) => {
        const newData = [...prevData];
        newData[questionNumber] = item.leads_to_id;
        return newData;
      });
    }
    const newAnswerId = [...answerIds];
    console.log("multiValue", multiValue);
    const newMultiValuearr = [...multiValue];
    let newAnsWeightarr = [...productWeight];
    if (
      !active.includes(index) &&
      item.custom_redirect_url &&
      multiValue.length < data.answer_limit
    ) {
      window.open(item.url, "_blank");
    }
    if (item.product_point) {
      if (!active.includes(index)) {
        const lastValue = productPoint || 0;
        const newSum = lastValue + +item.product_point;
        setProductPoint(newSum);
      } else {
        const lastValue = productPoint || 0;
        const newSum = lastValue + -item.product_point;
        setProductPoint(newSum);
      }
    }
    let targetData = item.select_products;
    let allVariantsTemp = [];
    let maxVariantWeightsTemp = null;
    let maxWeight = -Infinity;
    let findRemove_index = -1;
    targetData.forEach((product) => {
      product.variants.forEach((variant) => {
        // let variantCopy = { ...variant };
        // variantCopy.select_option = item.title;
        let variantCopy = {
          ...variant,
          select_option: item.title,
          answer_id: item._id,
        };
        if (
          !newAnsWeightarr.some((v) => v.select_option === item.title) &&
          multiValue.length < data.answer_limit
        ) {
          allVariantsTemp.push(variantCopy);
        } else {
          findRemove_index = newAnsWeightarr.findIndex(
            (v) => v.select_option === item.title
          );
        }
      });
    });
    // allVariantsTemp.forEach((variant) => {
    //   const weight = parseInt(variant.variant_weight); // Convert variant_weight to integer for comparison
    //   if (weight > maxWeight) {
    //     maxWeight = weight;
    //     maxVariantWeightsTemp = variant;
    //   }
    // });
    // if (maxVariantWeightsTemp !== null) {
    //   setProductWeight([...productWeight, maxVariantWeightsTemp]);
    // }
    if (findRemove_index !== -1) {
      newAnsWeightarr = productWeight.splice(findRemove_index, 1);
    }
    if (!active.includes(index)) {
      setProductWeight([...productWeight, ...allVariantsTemp]);
    } else {
      let removeVariantArray = [];
      item.select_products.forEach((product) => {
        product.variants.forEach((variant) => {
          removeVariantArray.push({ ...variant, answer_id: item._id });
          // removeVariantArray.push(variant);
        });
      });
      const filteredProductWeight = productWeight.filter((pw) => {
        const shouldRemove = removeVariantArray.some(
          (rv) => rv.answer_id === pw.answer_id
        );
        return !shouldRemove;
      });
      setProductWeight(filteredProductWeight);
    }
    const valu_index = newMultiValuearr.indexOf(item.title);
    const newResultWeightIds = [...resultWeightIds];
    let select_id = "";
    if (item.select_result_id !== undefined) {
      select_id = item.select_result_id !== null ? item.select_result_id : "";
    } else {
      select_id = "";
    }
    console.log("item.select_result_id", item.select_result_id);

    const weightIdIndex = newResultWeightIds.indexOf(select_id);
    if (data.multiselect_answer) {
      if (!active.includes(index) && newAnswerId.length < data.answer_limit) {
        if (!newAnswerId.includes(item._id)) {
          newAnswerId.push(item._id);
        }
        newResultWeightIds.push(select_id);
      } else {
        const index = newAnswerId.indexOf(item._id);
        if (index > -1) {
          newAnswerId.splice(index, 1);
          newResultWeightIds.splice(weightIdIndex, 1);
        }
      }
      if (valu_index > -1) {
        newMultiValuearr.splice(valu_index, 1);
        setActive(active?.filter((i) => i !== index));
      } else {
        if (
          newMultiValuearr.length < data.answer_limit &&
          !item.custom_redirect_url
        ) {
          newMultiValuearr.push(item.title);
          setActive([...active, index]);
        } else {
          console.log(`Maximum of ${data.answer_limit} selections reached.`);
        }
      }
      setMultiValue(newMultiValuearr);
      setAnswerIds(newAnswerId);
      setResultWeightIds(newResultWeightIds);
    } else {
      setAnswerIds([item._id]);
      setResultWeightIds([select_id]);
      if (item.custom_redirect_url) {
        setValue("");
        setActive();
      } else {
        setValue(item.title);
        setActive([index]);
      }
    }
  }

  const handleRedirect = (dir) => {
    if (localStorage.getItem("quizData")) {
      var valueArr = JSON.parse(localStorage.getItem("quizData"));
    } else {
      var valueArr = {};
    }
    valueArr[`${questionNumber}`] = {
      questionId: data._id,
      answerId: data.multiselect_answer ? multiValue : value,
      answerIds: answerIds,
      resultWeightIds: resultWeightIds,
      result_logic: result_json_data.result_logic,
      product_weight:
        result_json_data.result_logic === "Product Weight"
          ? productWeight.length
            ? productWeight
            : null
          : null,
      product_point:
        result_json_data.result_logic === "Points"
          ? productPoint !== 0
            ? productPoint
            : null
          : null,
      product_single:
        result_json_data.result_logic === "Single"
          ? productWeight.length
            ? productWeight
            : null
          : null,
      quizId: data.quiz_id,
    };
    localStorage.setItem("quizData", JSON.stringify(valueArr));
    setValue("");
    setMultiValue("");
    setActive("");
    if (dir) {
      if (data.multiselect_answer === true) {
        let backActionArr = localStorage.getItem("back_action")
          ? JSON.parse(localStorage.getItem("back_action"))
          : [];

        if (data.leads_to_id) {
          backActionArr.push(data.leads_to_id);
        } else {
          if (result.length - 1 > questionNumber) {
            backActionArr.push(result[questionNumber + 1]?._id);
          }
        }

        localStorage.setItem("back_action", JSON.stringify(backActionArr));
      } else {
        let backActionArr = localStorage.getItem("back_action")
          ? JSON.parse(localStorage.getItem("back_action"))
          : [];
        const answerObj = data.answers.find((answer) => answer.title === value);
        if (answerObj.leads_to_id) {
          backActionArr.push(answerObj.leads_to_id);
        } else {
          if (result.length - 1 > questionNumber) {
            backActionArr.push(result[questionNumber + 1]?._id);
          }
        }

        localStorage.setItem("back_action", JSON.stringify(backActionArr));
      }
      navigate(redirectURL);
    } else {
      if (quiz.logic_jump === true) {
        const back_action_arr = JSON.parse(localStorage.getItem("back_action"));
        const question_id = back_action_arr.pop();
        const secondLastId =
          back_action_arr.length !== 0
            ? back_action_arr[back_action_arr.length - 1]
            : result[0]?._id;
        localStorage.setItem("back_action", JSON.stringify(back_action_arr));
        const prevQuestionIndex = result.findIndex(
          (question) => question._id === secondLastId
        );
        const prevQuestionObject = result.find(
          (question) => question._id === secondLastId
        );

        navigate(`/question/${prevQuestionObject?._id}/${prevQuestionIndex}`);
      } else {
        navigate(redirectPrevURL);
      }
    }
  };

  function handleSkipRedirect(dir) {
    if (localStorage.getItem("quizData")) {
      var valueArr = JSON.parse(localStorage.getItem("quizData"));
    } else {
      var valueArr = {};
    }
    valueArr[`${questionNumber}`] = {
      questionId: data._id,
      answerId: null,
      answerIds: null,
      resultWeightIds: null,
      result_logic: result_json_data.result_logic,
      product_weight: null,
      product_point: null,
      quizId: data.quiz_id,
    };
    localStorage.setItem("quizData", JSON.stringify(valueArr));
    setValue("");
    if (dir) {
      let backActionArr = localStorage.getItem("back_action")
        ? JSON.parse(localStorage.getItem("back_action"))
        : [];

      if (data.leads_to_id) {
        backActionArr.push(data.leads_to_id);
      } else {
        if (result.length - 1 > questionNumber) {
          backActionArr.push(result[questionNumber + 1]?._id);
        }
      }
      localStorage.setItem("back_action", JSON.stringify(backActionArr));
      const skipQuestionIndex = result.findIndex(
        (question) => question._id === data.leads_to_id
      );
      const skipQuestionObject = result.find(
        (question) => question._id === data.leads_to_id
      );
      if (quiz.logic_jump === true) {
        if (data.leads_to_id === "result_page") {
          navigate("/subscribe");
        } else if (!data.leads_to_id) {
          if (result.length - 1 > questionNumber) {
            navigate(
              `/question/${result[questionNumber + 1]?._id}/${
                questionNumber + 1
              }`
            );
          } else {
            navigate("/subscribe");
          }
        } else {
          navigate(`/question/${skipQuestionObject?._id}/${skipQuestionIndex}`);
        }
      } else {
        navigate(redirectURL);
      }
    } else {
      navigate(redirectPrevURL);
    }
  }

  const ManageQuestions = () => {
    return (
      <>
        <Grid container justifyContent="center" mb={2}>
          {isBack ? (
            <Grid item xs>
              <Button
                onClick={() => handleRedirect(false)}
                className="btn-secondary"
                startIcon={<ArrowBackIcon />}
              >
                <b>Back</b>
              </Button>
            </Grid>
          ) : null}
          {data.skip_que && (
            <Grid item xs>
              <Button
                onClick={() => handleSkipRedirect(true)}
                className="btn-secondary"
                // startIcon={<ArrowBackIcon />}
              >
                <b>Skip</b>
              </Button>
            </Grid>
          )}
          {value.length === 0 && multiValue.length === 0 ? (
            <Grid item xs>
              <Button disabled endIcon={<ArrowForwardIcon />}>
                <b>Next</b>
              </Button>
            </Grid>
          ) : (
            <Grid item xs>
              <Button
                onClick={() => handleRedirect(true)}
                className="btn-secondary"
                endIcon={<ArrowForwardIcon />}
              >
                <b>Next</b>
              </Button>
            </Grid>
          )}
        </Grid>
      </>
    );
  };
  const TooltipComponent = () => {
    return (
      <>
        {data.tooltip_heading !== null && (
          <Container sx={{ paddingBottom: 10 }}>
            <Tooltip
              arrow
              sx={{ backgroundColor: "transperent" }}
              title={
                <Grid>
                  <h6 style={{ textAlign: "center" }}>
                    {data.tooltip_heading}
                  </h6>
                  <p>{data.tooltip_description}</p>
                </Grid>
              }
            >
              <IconButton>
                <ReportRoundedIcon sx={{ height: 50, width: 50 }} />
              </IconButton>
            </Tooltip>
          </Container>
        )}
      </>
    );
  };
  const AnswerLayout = () => {
    return (
      <Stepper activeStep={active} alternativeLabel>
        {data.answers.map((item, index) => (
          <Step
            key={item.title}
            active={index !== active}
            completed={index !== active}
            onClick={() => handleAnsValue(item, index)}
          >
            <StepLabel
              className="answer_title"
              StepIconComponent={
                active?.includes(index)
                  ? RadioButtonCheckedIcon
                  : RadioButtonUncheckedIcon
              }
            >
              {item.title}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  };
  const CenterStructure = () => {
    return (
      <Grid
        className="radioContainer center_part_radioContainer"
        sx={{ marginTop: "0px !important" }}
      >
        <Grid sx={{ width: "100%" }}>
          <Grid sx={{ marginInline: "5px" }}>
            <Typography variant="body1" color="inherit">
              {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
            </Typography>
            <Typography
              mt={3}
              variant="h4"
              color="inherit"
              sx={{ marginTop: "5px !important" }}
            >
              {data.heading}
            </Typography>
            <Typography
              mt={3}
              variant="h6"
              color="inherit"
              sx={{ marginTop: "5px !important" }}
            >
              {data.subheading}
            </Typography>
            {data?.question_add_rimage !== "" && (
              <div className="d-flex justify-center mt-3">
                <img
                  src={`${process.env.REACT_APP_API_URL}/question/${data?.question_add_rimage}`}
                  height={790 - parseInt(data.image_width)}
                  width={790 - parseInt(data.image_width)}
                  alt=""
                  srcset=""
                />
              </div>
            )}
          </Grid>
          <div style={{ paddingBlock: "20px" }}>
            <AnswerLayout />
          </div>
          <TooltipComponent />
          <ManageQuestions />
        </Grid>
      </Grid>
    );
  };
  // const CenterStructure = () => {
  //   return (
  //     <Grid className="radioContainer">
  //       <Grid sx={{ width: "100%" }}>
  //         <Grid>
  //           <Typography variant="body1" color="inherit">
  //             {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
  //           </Typography>
  //           <Typography mt={3} variant="h4" color="inherit">
  //             {data.heading}
  //           </Typography>
  //           <Typography mt={3} variant="h6" color="inherit">
  //             {data.subheading}
  //           </Typography>
  //           <div className="d-flex justify-center mt-3">
  //             <img
  //               src={`${process.env.REACT_APP_API_URL}/question/${data?.question_add_rimage}`}
  //               height={790 - parseInt(data.image_width)}
  //               width={790 - parseInt(data.image_width)}
  //               alt=""
  //               srcset=""
  //             />
  //           </div>
  //         </Grid>
  //         <div style={{ paddingBlock: "20px" }}>
  //           <AnswerLayout />
  //         </div>
  //         <TooltipComponent />
  //         <ManageQuestions />
  //       </Grid>
  //     </Grid>
  //   );
  // };
  // const RightStructure = () => {
  //   return (
  //     <Grid container>
  //       <Grid item xs={6} sx={{ width: "100%" }}>
  //         <Grid className="radioContainer">
  //           <div>
  //             <Grid>
  //               <Typography variant="body1" color="inherit">
  //                 {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
  //               </Typography>
  //               <Typography mt={3} variant="h4" color="inherit">
  //                 {data.heading}
  //               </Typography>
  //               <Typography mt={3} variant="h6" color="inherit">
  //                 {data.subheading}
  //               </Typography>
  //             </Grid>
  //             <div style={{ paddingBlock: "20px" }}>
  //               <AnswerLayout />
  //             </div>
  //             <TooltipComponent />
  //             <ManageQuestions />
  //           </div>
  //         </Grid>
  //       </Grid>
  //       <Grid
  //         item
  //         xs={6}
  //         sx={{
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           marginBottom: "400px",
  //         }}
  //       >
  //         <img
  //           src={`${process.env.REACT_APP_API_URL}/question/${data?.question_add_rimage}`}
  //           alt=""
  //           srcset=""
  //         />
  //       </Grid>
  //     </Grid>
  //   );
  // };
  const RightStructure = () => {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6} sm={12} sx={{ width: "100%" }}>
          <Grid
            className="radioContainer"
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "0px !important",
              height: "100%",
            }}
          >
            <Grid sx={{ width: "100%" }}>
              <Grid sx={{ marginInline: "5px" }}>
                <Typography variant="body1" color="inherit">
                  {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
                </Typography>
                <Typography mt={3} variant="h4" color="inherit">
                  {data.heading}
                </Typography>
                <Typography mt={3} variant="h6" color="inherit">
                  {data.subheading}
                </Typography>
              </Grid>
              <div className="paddingBlock-20">
                <AnswerLayout />
              </div>
              <TooltipComponent />
              <ManageQuestions />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sm={12}
          // sx={{
          //   minHeight: "100vh",
          //   display: "flex",
          //   alignItems: "center",
          // }}
        >
          <div className="left-structure-img">
            <img
              src={`${process.env.REACT_APP_API_URL}/question/${data?.question_add_rimage}`}
              alt=""
              srcset=""
            />
          </div>
        </Grid>
      </Grid>
    );
  };
  const LeftStructure = () => {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sm={12}
          // sx={{
          //   minHeight: "100vh",
          //   display: "flex",
          //   alignItems: "center",
          // }}
        >
          <div className="left-structure-img">
            <img
              src={`${process.env.REACT_APP_API_URL}/question/${data.question_add_rimage}`}
              alt=""
              srcset=""
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6} sm={12} sx={{ width: "100%" }}>
          <Grid
            className="radioContainer"
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "0px !important",
              height: "100%",
            }}
          >
            <Grid sx={{ width: "100%" }}>
              <Grid sx={{ marginInline: "5px" }}>
                <Typography variant="body1" color="inherit">
                  {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
                </Typography>
                <Typography mt={3} variant="h4" color="inherit">
                  {data.heading}
                </Typography>
                <Typography mt={3} variant="h6" color="inherit">
                  {data.subheading}
                </Typography>
              </Grid>
              <div className="paddingBlock-20">
                <AnswerLayout />
              </div>
              <TooltipComponent />
              <ManageQuestions />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const BackgroundStructure = () => {
    return (
      <>
        {/* <div
          className="back-structure"
          style={{
            zIndex: "0",
            top: "68px",
            bottom: "0",
            left: "0",
            right: "0",
            position: "absolute",
            width: "100%",
            height: "100%",
            opacity: `${data.background_opacity}%`,
            backgroundColor: `${data.background_color}`,
          }}
        ></div>
        <div
          className="background-image"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_API_URL
            }/question/${encodeURIComponent(data?.question_add_rimage)})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            zIndex: "3",
          }}
        > */}
        <div
          className="background-image"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_API_URL
            }/question/${encodeURIComponent(data?.question_add_rimage)})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            minHeight: "100vh",
            zIndex: "1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "auto",
          }}
        >
          <div
            className="overlay"
            style={{
              position: "absolute",
              inset: "0",
              width: "100%",
              height: "100%",
              zIndex: "2",
              top: "50px",
            }}
          ></div>

          <div
            className="background-image"
            style={{
              position: "absolute",
              top: "50px",
              left: "0",
              width: "100%",
              height: "100%",
              zIndex: "3",
              opacity: `${data.background_opacity}%`,
              backgroundColor: `${data.background_color}`,
            }}
          ></div>
          <Grid className="radioContainer center_part_radioContainer background-section-content">
            <Grid sx={{ width: "100%", opacity: "1", zIndex: "3" }}>
              <Grid sx={{ marginInline: "5px" }}>
                <Typography variant="body1" color="inherit">
                  {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
                </Typography>
                <Typography mt={3} variant="h4" color="inherit">
                  {data.heading}
                </Typography>
                <Typography mt={3} variant="h6" color="inherit">
                  {data.subheading}
                </Typography>
              </Grid>
              <div style={{ paddingBlock: "20px" }}>
                <AnswerLayout />
              </div>
              <TooltipComponent />
              <ManageQuestions />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };
  // const LeftStructure = () => {
  //   return (
  //     <Grid container>
  //       <Grid
  //         item
  //         xs={6}
  //         sx={{
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           marginBottom: "400px",
  //         }}
  //       >
  //         <img
  //           src={`${process.env.REACT_APP_API_URL}/question/${data.question_add_rimage}`}
  //           alt=""
  //           srcset=""
  //         />
  //       </Grid>
  //       <Grid item xs={6} sx={{ width: "100%" }}>
  //         <Grid className="radioContainer" sx={{ marginTop: "0px !important" }}>
  //           <div>
  //             <Grid>
  //               <Typography variant="body1" color="inherit">
  //                 {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
  //               </Typography>
  //               <Typography mt={3} variant="h4" color="inherit">
  //                 {data.heading}
  //               </Typography>
  //               <Typography mt={3} variant="h6" color="inherit">
  //                 {data.subheading}
  //               </Typography>
  //             </Grid>
  //             <div style={{ paddingBlock: "20px" }}>
  //               <AnswerLayout />
  //             </div>
  //             <TooltipComponent />
  //             <ManageQuestions />
  //           </div>
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //   );
  // };
  // const BackgroundStructure = () => {
  //   return (
  //     <div
  //       style={{
  //         backgroundImage: `url(${
  //           process.env.REACT_APP_API_URL
  //         }/question/${encodeURIComponent(data?.question_add_rimage)})`,
  //         backgroundRepeat: "no-repeat",
  //         backgroundSize: "cover",
  //       }}
  //     >
  //       <div
  //         style={{
  //           top: 0,
  //           left: 0,
  //           width: "100%",
  //           backgroundColor: `${data?.background_color}`,
  //           opacity: `${data.background_opacity}%`,
  //         }}
  //       >
  //         <Grid className="radioContainer">
  //           <Grid sx={{ width: "100%" }}>
  //             <Grid>
  //               <Typography variant="body1" color="inherit">
  //                 {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
  //               </Typography>
  //               <Typography mt={3} variant="h4" color="inherit">
  //                 {data.heading}
  //               </Typography>
  //               <Typography mt={3} variant="h6" color="inherit">
  //                 {data.subheading}
  //               </Typography>
  //             </Grid>
  //             <div style={{ paddingBlock: "20px" }}>
  //               <AnswerLayout />
  //             </div>
  //             <TooltipComponent />
  //             <ManageQuestions />
  //           </Grid>
  //         </Grid>
  //       </div>
  //     </div>
  //   );
  // };
  const TopStructure = () => {
    return (
      <Grid
        className="radioContainer center_part_radioContainer"
        sx={{ marginTop: "0px !important" }}
      >
        <Grid sx={{ width: "100%" }}>
          <Typography mt={2} mb={2} variant="body1" color="inherit">
            {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
          </Typography>
          <div className="d-flex justify-center">
            <img
              src={`${process.env.REACT_APP_API_URL}/question/${data?.question_add_rimage}`}
              height={790 - parseInt(data.image_width)}
              width={790 - parseInt(data.image_width)}
              alt=""
              srcset=""
            />
          </div>
          <Grid>
            <Typography mt={3} variant="h4" color="inherit">
              {data.heading}
            </Typography>
            <Typography variant="h6" color="inherit">
              {data.subheading}
            </Typography>
          </Grid>
          <div style={{ paddingBlock: "20px" }}>
            <AnswerLayout />
          </div>
          <TooltipComponent />
          <ManageQuestions />
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    localSorageData();
  }, [questionNumber]);
  return (
    <>
      {
        {
          center: <CenterStructure />,
          left: <LeftStructure />,
          right: <RightStructure />,
          background: <BackgroundStructure />,
          top: <TopStructure />,
        }[data.image_position]
      }
    </>
  );
};

export default RangeSlider;
