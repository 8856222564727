import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CButton } from "@coreui/react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import "../../answer_option/accordianradio.css";
import { Button, Checkbox, Grid, Tooltip, tooltipClasses } from "@mui/material";
import InputComponent from "../../inputfiled/Input_type_text";
import DeleteIcon from "@mui/icons-material/Delete";
import Inputtypefile from "../../input_file/Input_type_file";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useState, useContext } from "react";
import { FetchContext } from "../../../context/FetchContext";
import Selectproduct from "../../answer_option/Selectproduct";
import { useEffect } from "react";
import LeadtoQuestion from "../../answer_option/LeadtoQuestion";
import SelectproductSingle from "../../common/selectproduct/SelectProductSingle";
import SelectResultWeight from "../../common/selectproduct/SelectResultWeight";

export default function EditAccordianText({
  imgansdata,
  setimgansdata,
  type,
  validation,
  deleteAnswer,
  setDeleteAnswer,
  setIsSaveDisable,
  ProductData,
  resultLogic,
  loadingScrollableApi,
  hasMore,
  getProduct,
  imgquedata,
  allquestion,
  allQuelist,
  resultData,
}) {
  const { id } = useParams();
  const [expanded, setExpanded] = React.useState(false);
  const [clickurl, setclickurl] = React.useState("");

  const handleClick = (event, index) => {
    let newFormValues = [...imgansdata];
    const _clicked = [...clickurl];
    _clicked[index] = event.target.checked;
    setclickurl(_clicked);
    setIsSaveDisable(false);
    if (event.target.checked) {
      newFormValues[index].answer.url_valid = true;
    } else {
      newFormValues[index].answer.url_valid = false;
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: 20,
    },
  });
  const validateURL = (input) => {
    if (input) {
      const urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-zA-Z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-zA-Z\\d_]*)?$",
        "i" // fragment locator
      );
      let url = urlPattern.test(input);
      console.log("url------>", url);
      return urlPattern.test(input);
    } else {
      return true;
    }
  };
  let handleInputChange = (i, e, value) => {
    let newFormValues = [...imgansdata];
    if (e.target.name === "title" && e.target.value === "") {
      newFormValues[i].answer.valid = true;
    } else if (e.target.name === "product_point" && e.target.value < 0) {
      newFormValues[i].answer.valid = true;
    } else if (e.target.name === "url") {
      newFormValues[i].valid = !validateURL(e.target.value);
    } else {
      if (
        e.target.name === "description" &&
        imgansdata[i].answer.title === ""
      ) {
        newFormValues[i].answer.valid = true;
      } else {
        newFormValues[i].answer.valid = false;
      }
    }
    newFormValues[i].answer.url_valid = true;
    if (clickurl.length >= 0) {
      newFormValues[i].answer.url_valid = false;
    }
    if (e.target.name === "product_weight") {
      newFormValues[i].answer[e.target.name] = parseInt(e?.target?.value);
    } else {
      newFormValues[i].answer[e.target.name] = e?.target?.value;
    }
    // newFormValues[i].answer[e.target.name] = e?.target?.value;
    setimgansdata(newFormValues);
    setIsSaveDisable(false);
  };

  let handleUrlChange = (i, e, value) => {
    if (value) {
      let newFormValues = [...imgansdata];
      if (!validateURL(e.target.value) || e.target.value === "") {
        newFormValues[i].answer.url_valid = true;
      } else {
        newFormValues[i].answer.url_valid = false;
        if (newFormValues[i].answer.title === "") {
          newFormValues[i].answer.valid = true;
        }
      }
      newFormValues[i].answer[e.target.name] = e?.target?.value;
      setimgansdata(newFormValues);
      setIsSaveDisable(false);
    }
  };

  let addFormFields = () => {
    setIsSaveDisable(false);
    setimgansdata([
      ...imgansdata,
      {
        answer: {
          title: "",
          description: "",
          select_products: [],
          product_point: "",
          leads_to_id: "",
          custom_redirect_url: false,
          url: "",
          leads_question_id: id,
          valid: true,
          url_valid: true,
        },
      },
    ]);
  };

  let removeFormFields = (i) => {
    if (imgansdata[i].answer._id) {
      setDeleteAnswer([...deleteAnswer, imgansdata[i].answer._id]);
    }
    let newFormValues = [...imgansdata];
    newFormValues.splice(i, 1);
    setimgansdata(newFormValues);
    setIsSaveDisable(false);
  };

  let handleSubmit = (event) => {
    event.preventDefault();
    alert(JSON.stringify(imgansdata));
    setIsSaveDisable(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {imgansdata?.map((element, index) => (
          <Accordion
            key={index}
            className="acordian_answer"
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className="accordian-option"
            >
              <Grid className="d-flex col-12 ">
                <Grid item className="col-4 ">
                  <DragIndicatorIcon />
                </Grid>
                <Grid
                  item
                  className="col-4 text-center radio_answer_option_label"
                >
                  {element?.answer?.title
                    ? element?.answer?.title
                    : element?.title
                    ? element?.title
                    : "option"}
                </Grid>
                <Grid item className="text-end col-4 down_arrow"></Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <div className="" style={{ marginTop: "-10px" }}>
                <InputComponent
                  placeholder={"Option 1"}
                  value={element?.answer?.title || "" || element?.title}
                  label_name={"Title"}
                  name={"title"}
                  onChange={(e) => handleInputChange(index, e, element?.answer)}
                />
              </div>
              {element?.valid || element?.answer?.valid ? (
                <p className="text-[red] mx-2 mt-[-8px] text-sm">
                  {" "}
                  Title can't be blank{" "}
                </p>
              ) : (
                ""
              )}
              {type === "select" ? (
                ""
              ) : (
                <div className="" style={{ marginTop: "-10px" }}>
                  <InputComponent
                    placeholder={"Add detail about this option (optional)"}
                    name={"description"}
                    value={element?.answer?.description || ""}
                    label_name={"Description"}
                    onChange={(e) =>
                      handleInputChange(index, e, element?.answer)
                    }
                  />
                </div>
              )}
              {allquestion.quiz?.logic_jump &&
                !imgquedata.multiselect_answer && (
                  <div className="mt-4">
                    <LeadtoQuestion
                      element={element}
                      index={index}
                      allQuelist={allQuelist}
                      setIsSaveDisable={setIsSaveDisable}
                      imgansdata={imgansdata}
                      setimgansdata={setimgansdata}
                    />
                  </div>
                )}
              {resultLogic === "Points" && (
                <div>
                  <InputComponent
                    placeholder={"Enter Point"}
                    value={element?.answer?.product_point || ""}
                    label_name={"Product Point"}
                    name={"product_point"}
                    min={"0"}
                    onChange={(e) => {
                      handleInputChange(index, e, element?.answer);
                    }}
                    type="number"
                  />
                </div>
              )}
              {/* {element.valid || element.answer.valid ? (
                <p className="text-[red] mx-2 mt-[-8px] text-sm">
                  {" "}
                  Product point can't be blank{" "}
                </p>
              ) : (
                ""
              )} */}

              {resultLogic === "Single" && (
                <div className="select_product_componant mt-4">
                  <SelectproductSingle
                    isEdit={true}
                    setimgansdata={setimgansdata}
                    imgansdata={imgansdata}
                    ProductData={ProductData}
                    element={element}
                    index={index}
                    setIsSaveDisable={setIsSaveDisable}
                    loadingScrollableApi={loadingScrollableApi}
                    hasMore={hasMore}
                    getProduct={getProduct}
                    resultLogic={resultLogic}
                  />
                </div>
              )}
              {resultLogic === "Product Weight" && (
                <div className="select_product_componant mt-4">
                  <Selectproduct
                    isEdit={true}
                    setimgansdata={setimgansdata}
                    imgansdata={imgansdata}
                    ProductData={ProductData}
                    element={element}
                    index={index}
                    setIsSaveDisable={setIsSaveDisable}
                    loadingScrollableApi={loadingScrollableApi}
                    hasMore={hasMore}
                    getProduct={getProduct}
                  />
                </div>
              )}

              {resultLogic === "Result Weight" && (
                <div className="select_product_componant mt-4">
                  <SelectResultWeight
                    isEdit={true}
                    setimgansdata={setimgansdata}
                    imgansdata={imgansdata}
                    resultData={resultData}
                    element={element}
                    index={index}
                    setIsSaveDisable={setIsSaveDisable}
                    loadingScrollableApi={loadingScrollableApi}
                    hasMore={hasMore}
                  />
                </div>
              )}

              <div className="d-flex mt-4">
                <Checkbox
                  name="custom_redirect_url"
                  className="p-0"
                  defaultChecked={
                    element.answer?.custom_redirect_url === true ? true : false
                  }
                  onClick={(e) => handleClick(e, index)}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  onChange={(e, val) => {
                    let newFormValues = [...imgansdata];
                    newFormValues[index].answer[e.target.name] = val;

                    setimgansdata(newFormValues);
                  }}
                ></Checkbox>
                <Typography color="text.secondary" className=" mx-2 logic_jump">
                  Custom redirect URL
                </Typography>
              </div>
              <div className="mx-6 ml-8 opacity-70">
                <Typography
                  color="text.secondary"
                  className="mt-1"
                  style={{ letterSpacing: "1px" }}
                >
                  if a user chooses this option,skip the <br /> Results page and
                  instead send them to <br /> custom URL
                </Typography>
              </div>
              <div
                className={
                  element.answer?.custom_redirect_url == true
                    ? "custom_url_clicked"
                    : "mx-2 custom_url"
                }
                style={{ marginTop: "-15px" }}
              >
                <InputComponent
                  placeholder={"URL"}
                  name={"url"}
                  onChange={(e) => handleUrlChange(index, e, element?.answer)}
                  value={element?.answer?.url}
                />
                {element?.url_valid || element?.answer?.url_valid ? (
                  <p className="text-[red] mx-2 mt-[-8px] text-sm">
                    {" "}
                    please enter valid url{" "}
                  </p>
                ) : (
                  ""
                )}
              </div>
              {imgansdata.length > 1 && (
                <div className="col-12 main_optdelete  text-end">
                  <Typography sx={{ fontSize: 14 }} gutterBottom>
                    <CustomWidthTooltip title="After deleting the option make sure to save">
                      <Button
                        className="delete_option_btn"
                        size="lg"
                        onClick={() => removeFormFields(index)}
                      >
                        {" "}
                        <DeleteIcon className="" />
                        Delete option
                      </Button>
                    </CustomWidthTooltip>
                  </Typography>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
        <CButton
          size="lg"
          className="button_component w-100 "
          style={{ marginTop: "30px" }}
          onClick={() => addFormFields()}
        >
          Add option
        </CButton>
      </form>
    </div>
  );
}
