import { CFormInput, CFormLabel } from "@coreui/react";
import { DialogContent } from "@mui/material";
import React from "react";

const InputDateComponent = ({ label_name, onChange, placeholder, value, name }) => {

  return (
    <div>
      <CFormLabel
        htmlFor="inputText"
        className="mt-1 col-form-label input_fontsize"
      >
        {label_name}
      </CFormLabel>
      <CFormInput
        style={{ fontSize: "18px" }}
        type="date"
        name={name}
        size="lg"
        defaultValue={value}
        className="mb-3 dialog_input "
        placeholder={placeholder}
        aria-label=" input example"
        onChange={onChange}
      />
    </div>
  );
};

export default InputDateComponent;
