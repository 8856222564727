import React, { Component, useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useNavigate, Link, useParams } from "react-router-dom";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Typography,
  CssBaseline,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";

import toast from "react-hot-toast";
import { Avatar, Box, Button, Container, makeStyles } from "@material-ui/core";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";

import Iconify from "../iconify";

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResetPassword() {
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);

  const { dispatch } = useContext(AuthContext);

  const LoginSchema = Yup.object().shape({
    new_password: Yup.string().required("New Password is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      new_password: "",
      password: "",
    },
    validationSchema: LoginSchema,

    onSubmit: async (formData) => {
      if (formData.new_password !== formData.password) {
        toast.error("Your New Password And Confirm Password did Not Match");
      } else if (formData) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/reset-password/${id}`,
          formData
        );

        if (response.data.statusCode === 200) {
          setLoading(false);
          toast.success(response.data.massage);
          navigate("/");
        } else {
          setLoading(false);
          toast.error(response.data.massage);
          navigate("/");
        }
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              {/* <LockOutlinedIcon /> */}
            </Avatar>
            <Typography component="h1" variant="h5" className="mb-4">
              Reset Password
            </Typography>

            <TextField
              fullWidth
              autoComplete="new password"
              margin="normal"
              label="New Password"
              type={newPassword ? "text" : "password"}
              {...getFieldProps("new_password")}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setNewPassword(!newPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={newPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.new_password && errors.new_password)}
              helperText={touched.new_password && errors.new_password}
            />
            <TextField
              name="password"
              autoComplete="current-password"
              label="Confirm Password"
              margin="normal"
              className="w-full"
              type={showPassword ? "text" : "password"}
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              loading={isSubmitting}
            >
              submit
            </Button>
          </div>
          <Box mt={8}></Box>
        </Container>
      </Form>
    </FormikProvider>
  );
}
