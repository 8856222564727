import React from "react";
import Sidenav from "../../component/Sidenav";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Skeleton,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import defaultImage from "../../public/default-fe.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { FetchContext } from "../../context/FetchContext";
import toast from "react-hot-toast";
import { useState } from "react";

const Dashboard = () => {
  const { authAxios } = useContext(FetchContext);
  const { state, dispatch } = useContext(AuthContext);
  const [publishQuizData, setPublishQuizData] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const encodedShopId = encodeURIComponent(state.loginadmin.shop_id);
    authAxios
      .get(
        `${process.env.REACT_APP_API_URL}/quiz/get_publish_quiz/${encodedShopId}`
      )
      .then((res) => {
        const { data } = res;
        setPublishQuizData(data.data);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
    return () => {
      authAxios;
    };
  }, []);

  function handleeditquiz() {
    navigate(`/quizzes/intropage/${publishQuizData._id}`);
  }

  function formatDate(dateString) {
    const date = new Date(dateString);

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = months[date.getMonth()]; // Get the month name
    const day = date.getDate(); // Get the day of the month

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes; // Add leading zero to minutes if needed

    return `${month} ${day} at ${hours}:${formattedMinutes} ${ampm}`;
  }
  return (
    <Sidenav>
      <div className="dashboard_contain">
        <div>
          <Typography variant="h6">
            <b>Welcome back! 👋</b>
          </Typography>
          <Typography variant="body2" className="welcome_description" sx={{}}>
            Every day, is a great day to make money.
          </Typography>
        </div>
        {publishQuizData?.is_publish && (
          <div className="recent_edit_card">
            <Card variant="outlined" sx={{ borderRadius: "10px !important" }}>
              <CardContent sx={{ padding: "0px !important" }}>
                <Typography
                  variant="h6"
                  sx={{ padding: "15px 25px", color: "#303030e3 !important" }}
                >
                  <b>Most recent edit</b>
                </Typography>
                <div className="card_img_fame">
                  <img
                    src={
                      publishQuizData?.quiz_add_image
                        ? `${process.env.REACT_APP_API_URL}/quiz/${publishQuizData?.quiz_add_image}`
                        : defaultImage
                    }
                    alt=""
                    className="dash_quiz_image"
                  />
                </div>
                <hr style={{ margin: "0px" }}></hr>
                <div className="quiz_title_contain">
                  <div className="title_and_button">
                    <div>
                      <div className="chip_content">
                        <p className="quiz_heading">
                          {publishQuizData?.quiz_name}
                        </p>
                        <div>
                          <Chip
                            className="live_chip"
                            label="Live"
                            size="small"
                          />
                          {/* <Chip
                        className="unlive_chip"
                        label="Unpublished"
                        size="small"
                      /> */}
                        </div>
                      </div>
                      <p className="update_quiz_date">
                        Last saved: {formatDate(publishQuizData?.updatedAt)}
                      </p>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        className="dashboard_quiz_button"
                        onClick={handleeditquiz}
                      >
                        Customize
                      </Button>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        )}
      </div>
    </Sidenav>
  );
};

export default Dashboard;
