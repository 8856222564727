import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, Checkbox, Grid } from "@mui/material";
import InputComponent from "../../inputfiled/Input_type_text";
import Inputtypefile from "../../input_file/Input_type_file";
import Radio_component from "../../radio/Radiocomponent";
import Range from "../../ranger/Range";
import { CButton } from "@coreui/react";
import { Divider } from "@material-ui/core";
import Buttton from "../../Button/Buttton";
import { useNavigate } from "react-router-dom";
import Select from "../../dropdown/Select";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";

const LeftSideCArd = ({
  setgetdata,
  quiz_show_image,
  setquiz_add_image,
  setquiz_show_image,
  quiz_add_image,
  getdata,
  editaddimage,
  setIsSaveDisable,
  quiz_desktop_image,
  setquiz_desktop_image,
  quiz_show_desktop_image,
  setquiz_show_desktop_image,
}) => {
  const [error, setError] = React.useState("");
  const handleremove = (i) => {
    setquiz_add_image([]);
    setquiz_show_image([]);
    setgetdata({ ...getdata, quiz_add_image: "", content_alignment: "center" });
    setIsSaveDisable(false);
    setError("");
  };
  function handleDesktopRemove() {
    setquiz_desktop_image([]);
    setquiz_show_desktop_image([]);
    setgetdata({ ...getdata, quiz_desktop_image: "" });
    setIsSaveDisable(false);
    setError("");
  }

  return (
    <>
      <Grid item xs={12} sm={12} lg={4} md={12} spacing={3} className="mt-4">
        <Card sx={{ minWidth: 375 }} className="left_quiz_card">
          <CardContent className="infoq_cardcontent">
            <Grid className="d-flex">
              <Grid>
                <Checkbox
                  checked={getdata?.disable_intro === true ? true : false}
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e, val) => {
                    setgetdata({ ...getdata, disable_intro: val });
                    setIsSaveDisable(false);
                  }}
                ></Checkbox>
              </Grid>
              <Typography className="mx-2 mt-2" variant="h6" component="div">
                Disable intro page
              </Typography>
            </Grid>
            <Typography
              className="intro_quiz_text"
              variant="h6"
              color="text.secondary"
            >
              If the Intro page is disabled,users will move straight to the
              first question.
            </Typography>
            <div className="mx-2 mt-3">
              <InputComponent
                label_name={"Heading"}
                onChange={(e) => {
                  setIsSaveDisable(false);
                  setgetdata({ ...getdata, heading: e.target.value });
                }}
                value={getdata?.heading}
              />
            </div>
            <div className="mx-2">
              <InputComponent
                label_name={"Sub heading"}
                onChange={(e) => {
                  setIsSaveDisable(false);
                  setgetdata({ ...getdata, subheading: e.target.value });
                }}
                value={getdata?.subheading}
              />
            </div>
            <div className="mx-1 mt-4" style={{ display: "flex", gap: "15px" }}>
              <div style={{ flex: "auto" }}>
                <Inputtypefile
                  name={quiz_add_image}
                  image_text={
                    getdata?.quiz_add_image || quiz_add_image.length !== 0
                      ? "Change an image"
                      : "Add an image"
                  }
                  onChange={(e) => {
                    if (e.target.files.length !== 0) {
                      const file = e.target.files[0];

                      if (file.type.startsWith("image/")) {
                        setquiz_show_image(URL.createObjectURL(file));
                        setquiz_add_image(file);
                        setIsSaveDisable(false);
                        setError("");
                      } else {
                        setError("Please select a valid image file.");
                      }
                    }
                  }}
                />
              </div>
              {(getdata?.quiz_add_image || quiz_add_image.length !== 0) && (
                <div>
                  <Button
                    variant="outlined"
                    className="remove_btn"
                    onClick={handleremove}
                  >
                    Remove
                  </Button>
                </div>
              )}
            </div>
            {(getdata?.quiz_add_image || quiz_add_image.length !== 0) &&
              getdata?.content_alignment === "center" && (
                <div className="mx-2 mt-4 ">
                  <div style={{ fontSize: "18px" }}> Image width(px)</div>
                  <div className="image_range">
                    <Range
                      value={parseInt(getdata?.image_width)}
                      onChange={(e) => {
                        setgetdata({
                          ...getdata,
                          image_width: e.target.value,
                        });
                        setIsSaveDisable(false);
                      }}
                      max={true}
                    />
                  </div>
                </div>
              )}
            {(getdata?.quiz_add_image || quiz_add_image.length !== 0) && (
              <div className="mx-2 mt-4">
                <Select
                  dropdown_text={"Content alignment"}
                  values={["right", "left", "center"]}
                  onChange={(e) => {
                    setgetdata({
                      ...getdata,
                      content_alignment: e.target.value,
                    });
                    setIsSaveDisable(false);
                  }}
                  value={getdata?.content_alignment || "center"}
                />
              </div>
            )}
            <div className="mx-2 mt-2">
              <InputComponent
                label_name={"Button text"}
                onChange={(e) => {
                  setgetdata({ ...getdata, button_text: e.target.value });
                  setIsSaveDisable(false);
                }}
                value={getdata?.button_text}
              />
            </div>
          </CardContent>
        </Card>

        <Card sx={{ minWidth: 375 }} className="left_second_qcard">
          <CardContent className="infoq_cardcontent">
            <Grid>
              <Typography className="mt-2 mx-1" variant="h6" component="div">
                Background
              </Typography>
            </Grid>
            <Grid className="radio_main" variant="h6">
              <Grid>
                <Radio_component
                  onChange={(e) => {
                    setgetdata({ ...getdata, background_type: e.target.value });
                    setIsSaveDisable(false);
                  }}
                  value={getdata?.background_type}
                /> 
              </Grid>
            </Grid>
            {getdata.background_type === "Image" && (
              <div>
                <div
                  className="mx-1 mt-4"
                  style={{ display: "flex", gap: "15px" }}
                >
                  <div style={{ flex: "auto" }}>
                    <Inputtypefile
                      name={quiz_desktop_image}
                      image_text={
                        getdata?.quiz_desktop_image ||
                        quiz_desktop_image.length !== 0
                          ? "Change desktop image"
                          : "Add desktop image"
                      }
                      onChange={(e) => {
                        const file = e.target.files[0];
                        setquiz_desktop_image(file);
                        setquiz_show_desktop_image(URL.createObjectURL(file));
                        setIsSaveDisable(false);
                      }}
                    />
                  </div>
                  {(getdata?.quiz_desktop_image ||
                    quiz_desktop_image.length !== 0) && (
                    <div>
                      <Button
                        variant="outlined"
                        className="remove_btn"
                        onClick={handleDesktopRemove}
                      >
                        Remove
                      </Button>
                    </div>
                  )}
                </div>
                <div className="mx-1 mt-4">
                  <Inputtypefile
                    image_text="Add mobile image (optional)"
                    onChange={(e) => {
                      setgetdata({
                        ...getdata,
                        quiz_mobile_image: e.target.files[0],
                      });
                      setIsSaveDisable(false);
                    }}
                  />
                </div>
              </div>
            )}
            {getdata.background_type === "Color" && (
              <div className="">
                <div className="overlay_text">
                  <div
                    onChange={(e) => {
                      setgetdata({ ...getdata, addMobile: e.target.value });
                      setIsSaveDisable(false);
                    }}
                  >
                    Overlay color:
                  </div>
                  <div>
                    <input
                      type="color"
                      className="color_button w-52 h-14 mt-1"
                      onChange={(e) => {
                        setgetdata({
                          ...getdata,
                          overlay_color: e.target.value,
                        });
                        setIsSaveDisable(false);
                      }}
                      value={getdata.overlay_color === "" ? "#ffffff" : getdata.overlay_color }
                      defaultValue={
                        getdata?.overlay_color
                          ? getdata.overlay_color
                          : "#ffffff"
                      }
                    ></input>
                  </div>
                </div>
                {/* <div className="overlay_text">
                  Overlay opacity:
                  {console.log(
                    "getdata?.overlay_opacity",
                    getdata?.overlay_opacity
                  )}
                  <Range
                    value={getdata?.overlay_opacity}
                    onChange={(e) => {
                      setgetdata({
                        ...getdata,
                        overlay_opacity: e.target.value,
                      });
                      setIsSaveDisable(false);
                    }}
                  />
                </div> */}
              </div>
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default LeftSideCArd;
