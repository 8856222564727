import { Box, Chip, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Buttton from "../../../Button/Buttton";
import Sidenav from "../../../Sidenav";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Pagestepper from "../../../intropage/Pagestepper";
import Questionsecondcard from "../../../question/AddQuestion/Questionsecondcard";
import { FetchContext } from "../../../../context/FetchContext";
import { AuthContext } from "../../../../context/AuthContext";
import EditLeftSelectCard from "./EditLeftSelectCard";
import EditRightSelectCard from "./EditRightSelectCard";
import { toast } from "react-hot-toast";
import CreateQuestionTitle from "../../../Title/Title";

const EditSelectCard = () => {
  const { id } = useParams();
  const { authAxios } = useContext(FetchContext);
  const { state, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const [question_add_an_iimage, setquestion_add_an_iimage] = React.useState(
    []
  );
  const [question_show_iimage, setquestion_show_iimage] = React.useState([]);
  const [allquestion, setallquestion] = React.useState([]);
  const [validation, setvalidation] = React.useState(false);
  const [isSaveDisable, setIsSaveDisable] = React.useState(true);
  const [deleteAnswer, setDeleteAnswer] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [resultLogic, setResultLogic] = useState();
  const [allQuelist, setAllQueList] = useState([]);
  const [imgquedata, setimgquedata] = React.useState({
    heading: "",
    question_types: "Range slider",
    subheading: "",
    image_width: "",
    image_position: "center",
    tooltip_heading: "",
    tooltip_description: "",
    multiselect_answer: false,
    skip_que: false,
    dont_show: false,
    property_name: "",
    answer_limit: 2,
  });

  const quiz = state?.allquiz?.filter(
    (item) => item?._id === imgquedata?.quiz_id
  )[0];
  const preview = () => {
    if (quiz.disable_intro  === true) {
      navigate(`/preview/enable_intropage/${imgquedata?.quiz_id}`);
    } else {
      navigate(`/preview/${imgquedata?.quiz_id}`);
    }  };

  const [imgansdata, setimgansdata] = React.useState([
    {
      label: "",
      custom_redirect_url: false,
      url: "",
    },
  ]);

  useEffect(() => {
    authAxios
      .post(
        `${process.env.REACT_APP_API_URL}/question/get_question_answerdata/${id}`
      )
      .then((res) => {
        setResultLogic(res.data.quiz.result_json.result_logic);
        setimgquedata(res.data.questiondata);
        setimgansdata(res.data.editanswerdata);
        setallquestion(res.data);
        const allQuestion_list = res.data.result;
        const result = allQuestion_list.map((item, index) => ({
          label: `Question ${index + 1}. ${item.heading}`,
          id: item._id,
        }));
        setAllQueList(result);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }, []);

  React.useEffect(() => {
    authAxios
      .post(`${process.env.REACT_APP_API_URL}/question/show_question/${id}`, id)
      .then((res) => {
        setallquestion(res.data);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }, [id]);

  const questionsave = () => {
    if (imgansdata.length > 0) {
      if (deleteAnswer.length > 0) {
        try {
          authAxios.delete(
            `${process.env.REACT_APP_API_URL}/answer/delete_answer/${deleteAnswer}`
          );
          console.log("All answers deleted successfully");
          setDeleteAnswer([]);
        } catch (error) {
          console.error("Error deleting answers:", error);
        }
      }
      let updatedImgquedata = { ...imgquedata };
      if (imgquedata.answer_limit > imgansdata.length) {
        updatedImgquedata.answer_limit = imgansdata.length;
      }
      setimgquedata(updatedImgquedata);
      const formData = new FormData();
      formData.append("imgquedata", JSON.stringify(updatedImgquedata));
      formData.append("imgansdata", JSON.stringify(imgansdata));
      formData.append("question_add_an_iimage", question_add_an_iimage);

      if (imgquedata.heading === "") {
        toast.error("Question heading can't be blank ");
        setvalidation(true);
      } else if (
        imgansdata.filter(
          (item, index) => item.valid || item.answer.valid === true
        ).length !== 0
      ) {
        toast.error("Title can't be blank ");
      } else if (
        imgansdata?.filter(
          (item, index) => item.url_valid || item.answer.url_valid == true
        ).length !== 0
      ) {
        toast.error("Url can't be blank ");
      } else {
        setLoading(true);
        authAxios
          .post(
            `${process.env.REACT_APP_API_URL}/question/edit_question_answer/${id}`,
            formData
          )
          .then((res) => {
            // setimgquedata(res.data);
            if (res.data.msg === "success") {
              setLoading(false);
              setimgquedata(res.data);
              navigate(`/quizzes/intropage/${quiz?._id}`);
            }
          })
          .catch((err) => {
            if (err) {
              setLoading(false);
              toast.error(err);
              console.log(err);
            }
          });
      }
    } else {
      toast.error("Please add at least one answer or field");
    }
  };

  return (
    <>
      <Sidenav>
        <CreateQuestionTitle
          link={`/quizzes/intropage/${quiz?._id}`}
          quiz_name={quiz?.quiz_name}
          is_publish={quiz?.is_publish}
          preview={preview}
          questionsave={questionsave}
          isSaveDisable={isSaveDisable}
          loading={loading}
        />
        {/* <div className="d-flex">
          <Grid className="intro_back " xs={3}>
            <div className="mx-4 d-flex">
              <Link to={{ pathname: `/quizzes/intropage/${quiz?._id}` }}>
                {" "}
                <KeyboardBackspaceRoundedIcon className="intro_backbutton" />{" "}
              </Link>
              <div className="mx-4 intro_quizname mt-2">{quiz?.quiz_name}</div>
              <div className="mt-1">
                {quiz?.is_status === true ? (
                  <Chip className="live_chip" label="Live" />
                ) : (
                  <Chip className="unlive_chip" label="Unpublished" />
                )}
              </div>
            </div>
          </Grid>
          <Grid xs={6}></Grid>
          <Grid className="save_button mr-6 " xs={3}>
            <Grid className="mr-10 mt-2" onClick={(e) => preview()}>
              <VisibilityIcon />{" "}
              <span className="preview_save mx-1"> preview </span>
            </Grid>
            <Buttton
              handleClick={questionsave}
              className="savebutton"
              title={"Save"}
              disabled={isSaveDisable}
              loading={loading}
            />
          </Grid>
        </div> */}
        <div className="mt-4 mx-4">
          <Pagestepper quiz={quiz} />
        </div>
        <div className="mt-4 mx-4">
          <Questionsecondcard
            allquestion={allquestion}
            imgquedata={imgquedata}
          />
        </div>
        <Box className="d-flex mx-4">
          <Grid container spacing={3}>
            <EditLeftSelectCard
              imgquedata={imgquedata}
              setimgquedata={setimgquedata}
              question_show_iimage={question_show_iimage}
              imgansdata={imgansdata}
              setimgansdata={setimgansdata}
              question_add_an_iimage={question_add_an_iimage}
              setquestion_add_an_iimage={setquestion_add_an_iimage}
              setquestion_show_iimage={setquestion_show_iimage}
              validation={validation}
              setvalidation={setvalidation}
              setIsSaveDisable={setIsSaveDisable}
              deleteAnswer={deleteAnswer}
              setDeleteAnswer={setDeleteAnswer}
              resultLogic={resultLogic}
              allquestion={allquestion}
              allQuelist={allQuelist}
            />
            <EditRightSelectCard
              imgquedata={imgquedata}
              question_show_iimage={question_show_iimage}
              imgansdata={imgansdata}
              question_add_an_iimage={question_add_an_iimage}
              allquestion={allquestion}
            />
          </Grid>
        </Box>
      </Sidenav>
    </>
  );
};

export default EditSelectCard;
