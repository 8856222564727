import { Button, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FetchContext } from "../../context/FetchContext";
import styled from "styled-components";
import { CButton, CFormLabel } from "@coreui/react";
import { Avatar, Box } from "@mui/material";
import defaultImage from "../../public/default-fe.png";
import Subscribe from "./Subscribe";
import { AuthContext } from "../../context/AuthContext";
// import { AuthContext } from "../../../../context/AuthContext";

const ResultLayout = ({ redirectPrevURL, redirectURL, topText, isBack }) => {
  const { id, submission_id } = useParams();
  const { authAxios } = React.useContext(FetchContext);
  const [data, setdata] = useState();
  const navigate = useNavigate();
  const [image, setImage] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [variantData, setVariantData] = React.useState([]);
  const { state } = useContext(AuthContext);

  const quiz = state?.allquiz?.filter((item) => item?._id === id)[0];
  console.log("data===============>", data);

  const shop_id = "gid://shopify/Shop/83321454872";

  React.useEffect(() => {
    function getProduct() {
      authAxios
        .get(
          `${process.env.REACT_APP_API_URL}/submission/get_submission_details/${submission_id}`
        )
        .then((res) => {
          console.log("res.data", res.data[0].products_data);
          if (res.data[0].products_data.length === 0) {
            authAxios
              .post(`${process.env.REACT_APP_API_URL}/product/product_get`, {
                shop_id: shop_id,
                limit: 3,
              })
              .then((res) => {
                setProducts(res.data.data);
              });
          } else {
            // setProductData(res.data.data);
            setProducts(res.data[0].products_data);
            setdata(res.data[0].results[0]);
          }
        })
        .catch((err) => {
          if (err) {
            console.log(err);
          }
        });
    }
    getProduct();
  }, []);
  function getResultData() {
    authAxios
      .post(`${process.env.REACT_APP_API_URL}/question/show_question/${id}`, id)
      .then((res) => {
        console.log("res.data.quiz", res.data.quiz);
        setdata(res.data.quiz.result_json);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }
  // React.useEffect(() => {
  //     getResultData();
  // }, [id]);

  const MainImage = styled("img")({
    width:
      data?.product_image_height > 200 ? "100%" : data?.product_image_height,
    height: ` ${data?.product_image_height}px`,
    borderRadius: 8,
    objectFit: data?.product_image_position === "stretch" && "cover",
  });

  const SubImage = styled("img")(({ isActive }) => ({
    width: "100%",
    height: "70px",
    borderRadius: 4,
    cursor: "pointer",
    // border: '2px solid transparent',
    "&:hover": {
      // border: isActive ? '2px solid transparent' : '2px solid #000',
      border: isActive && "2px solid #000",
    },
    ...(isActive && {
      border: "2px solid black",
    }),
  }));

  const handleSubImage = (e, index) => {
    let updatedImages = [...image];
    updatedImages[index] = e?.url;
    setImage(updatedImages);
  };

  const DefaultStructure = () => {
    return (
      <Grid className="radioContainer center_part_radioContainer">
        <Grid sx={{ width: "100%" }}>
          <Grid sx={{ width: "100%" }}>
            <Typography
              mb={3}
              variant="h6"
              color="inherit"
              style={{ fontFamily: "Cormorant Garamond" }}
            >
              The results are in.
            </Typography>
            <Typography
              mb={3}
              variant="h4"
              color="inherit"
              style={{ fontFamily: "Cormorant Garamond" }}
            >
              Found: Your Perfect Planner
            </Typography>
            <Typography
              sx={{ mb: 2.5 }}
              className="description_result_text"
              style={{ fontFamily: "Cormorant Garamond" }}
            >
              Based on your results, here are your top planner recommendations.
              For more information or to add a monogram click on any of the
              results below.
            </Typography>
          </Grid>
          <div style={{ paddingBlock: "20px" }}>
            <Grid
              container
              spacing={2}
              sx={{ padding: "30px", justifyContent: "center" }}
            >
              {products?.map((product, index) => {
                return (
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: 2,
                        borderRadius: 4,
                        maxWidth: 400,
                        margin: "auto",
                      }}
                    >
                      <MainImage
                        src={
                          image[index] === null || image[index] === undefined
                            ? product.image_url || defaultImage
                            : image[index]
                        }
                        alt="The Notebook in Sky Blue"
                      />
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          marginBottom: "40px",
                          marginTop: "40px",
                          justifyContent: "center",
                        }}
                      >
                        {product.product_json.images.map((images) => {
                          return (
                            images !== null && (
                              <Grid
                                item
                                xs={4}
                                onClick={() => handleSubImage(images, index)}
                              >
                                <SubImage
                                  src={images?.url}
                                  className={
                                    image.url === images?.url ? "acive" : ""
                                  }
                                  isActive={
                                    (image[index] === (null || undefined)
                                      ? product.image_url
                                      : image[index]) === images?.url
                                  }
                                />
                              </Grid>
                            )
                          );
                        })}
                      </Grid>
                      <Typography
                        variant="h5"
                        sx={{
                          textTransform: "uppercase",
                          fontFamily: "Cormorant Garamond",
                          fontSize: "12px",
                        }}
                      >
                        {product?.product_json?.vendor}
                      </Typography>

                      <Typography
                        variant="h5"
                        sx={{
                          textTransform: "uppercase",
                          fontFamily: "Cormorant Garamond",
                          fontSize: "12px",
                        }}
                      >
                        {product?.product_json?.productType}
                      </Typography>

                      <Typography
                        variant="h5"
                        className="ps-4 pe-4 pb-3 pt-2"
                        sx={{
                          fontFamily: "Cormorant Garamond",
                          fontSize: "20px",
                        }}
                      >
                        {product?.product_json?.title}
                      </Typography>

                      <Typography
                        className="mt-2"
                        variant="h6"
                        color="textSecondary"
                      >
                        {product?.price}$
                      </Typography>

                      <CButton
                        color="secondary"
                        shape="rounded-0"
                        variant="outline"
                        size="lg"
                        className="mt-3 text-color"
                        sx={{ width: "125px" }}
                        style={{
                          fontFamily: "Trade Gothic LH",
                          textTransform: "uppercase",
                        }}
                      >
                        Add to bag
                      </CButton>
                    </Box>
                  </Grid>
                );
              })}

              <div className={"d-flex  center_small_image_box"}>
                <>
                  <Button
                    className="mt-4"
                    sx={{
                      color: "black",
                      borderBottom: "1px solid black",
                      borderRadius: "0px",
                      // textTransform: "none",
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "darkgray",
                      },
                      textTransform: "uppercase",
                      fontFamily: "Trade Gothic LH",
                    }}
                    onClick={(e) => {
                      window.location.href = data?.button_URL;
                    }}
                  >
                    EXPLORE ALL PLANNERS{" "}
                  </Button>
                </>
                <Button
                  className="mt-4 ms-3"
                  sx={{
                    color: "black",
                    borderBottom: "1px solid black",
                    borderRadius: "0px",
                    // textTransform: "none",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "darkgray",
                    },
                    textTransform: "uppercase",
                    fontFamily: "Trade Gothic LH",
                  }}
                  onClick={(e) => {
                    if (quiz.disable_intro === true) {
                      navigate(`/preview/enable_intropage/${id}`);
                    } else {
                      navigate(`/preview/${id}`);
                    }
                  }}
                >
                  RETAKE QUIZ
                </Button>
              </div>
            </Grid>{" "}
          </div>
        </Grid>
      </Grid>
    );
  };

  const AnswerLayout = () => {
    return (
      <div>
        <Grid
          container
          spacing={2}
          sx={{ padding: "30px", justifyContent: "center" }}
        >
          {products?.map((product, index) => {
            return (
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 2,
                    borderRadius: 4,
                    maxWidth: 400,
                    margin: "auto",
                  }}
                >
                  <MainImage
                    src={
                      image[index] === null || image[index] === undefined
                        ? product.image_url || defaultImage
                        : image[index]
                    }
                    alt="The Notebook in Sky Blue"
                  />
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      marginBottom: "40px",
                      marginTop: "40px",
                      justifyContent: "center",
                    }}
                  >
                    {data?.show_all_product_image === true &&
                      (data?.product_featured_image === false
                        ? product.product_json?.variants.map((variant) => {
                            return (
                              variant?.image !== null && (
                                <Grid
                                  item
                                  xs={4}
                                  onClick={() =>
                                    handleSubImage(variant?.image, index)
                                  }
                                >
                                  <SubImage
                                    src={variant?.image?.url}
                                    className={
                                      image.url === variant?.image?.url
                                        ? "acive"
                                        : ""
                                    }
                                    isActive={
                                      (image[index] === (null || undefined)
                                        ? product.image_url
                                        : image[index]) === variant?.image?.url
                                    }
                                  />
                                </Grid>
                              )
                            );
                          })
                        : product.product_json?.images.map((images) => {
                            return (
                              images !== null && (
                                <Grid
                                  item
                                  xs={4}
                                  onClick={() => handleSubImage(images, index)}
                                >
                                  <SubImage
                                    src={images?.url}
                                    className={
                                      image.url === images?.url ? "acive" : ""
                                    }
                                    isActive={
                                      (image[index] === (null || undefined)
                                        ? product.image_url
                                        : image[index]) === images?.url
                                    }
                                  />
                                </Grid>
                              )
                            );
                          }))}
                  </Grid>
                  {data?.show_product_title === false && (
                    <>
                      {data?.show_vendor === true && (
                        <Typography
                          variant="h5"
                          sx={{
                            textTransform: "uppercase",
                            fontFamily: "Cormorant Garamond",
                            fontSize: "12px",
                          }}
                        >
                          {product?.product_json?.vendor}
                        </Typography>
                      )}
                      {data?.show_product_type === true && (
                        <Typography
                          variant="h5"
                          sx={{
                            textTransform: "uppercase",
                            fontFamily: "Cormorant Garamond",
                            fontSize: "12px",
                          }}
                        >
                          {product?.product_json?.productType}
                        </Typography>
                      )}

                      <Typography
                        variant="h5"
                        className="ps-4 pe-4 pb-3 pt-2"
                        sx={{
                          fontFamily: "Cormorant Garamond",
                          fontSize: "20px",
                        }}
                      >
                        {product?.product_json?.title}
                      </Typography>
                      {data?.hide_price == false && (
                        <Typography
                          className="mt-2"
                          variant="h6"
                          color="textSecondary"
                        >
                          {/* {product?.price}$ */}
                          {variantData[index]?.index === index
                            ? variantData[index].price
                              ? variantData[index].price + "$"
                              : product?.price + "$"
                            : product?.price + "$"}
                        </Typography>
                      )}
                    </>
                  )}
                  {data?.show_product_title === true && (
                    <Typography
                      variant="h5"
                      className="ps-4 pe-4 pb-3 pt-2"
                      sx={{
                        fontFamily: "Cormorant Garamond",
                        fontSize: "20px",
                      }}
                    >
                      {product?.product_json?.title}
                    </Typography>
                  )}

                  {data?.hide_variants === false && (
                    <div className="mt-3" style={{ textAlign: "justify" }}>
                      <span style={{ fontSize: "17px" }}>Title</span>
                      <select
                        style={{
                          width: "150px",
                          boxShadow: "none",
                          border: "1px solid #0000007d",
                          backgroundColor: "transparent",
                        }}
                        className="form-select mt-1 form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                        onChange={(e) => {
                          const selectedVariantId = e.target.value;
                          const selectedVariant =
                            product.product_json.variants.find(
                              (variant) => variant.id === selectedVariantId
                            );
                          console.log("selectedVariant", selectedVariant);
                          setVariantData((prevVariantData) => {
                            const updatedVariants = [...prevVariantData];
                            updatedVariants[index] = {
                              ...updatedVariants[index],
                              index: index,
                              title: selectedVariant.title,
                              id: selectedVariantId,
                              price: selectedVariant.price,
                              image: selectedVariant.image?.url,
                            };
                            return updatedVariants;
                          });
                          handleSubImage(selectedVariant.image, index);
                        }}
                        // value={variantData[index]?.title}
                        defaultValue={variantData[index]?.id}
                      >
                        {product.product_json?.variants.map((variant) => {
                          return (
                            <option value={variant.id}>{variant.title}</option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  {data?.hide_add_to_bag === false &&
                    (data?.add_bag_button_width === false ? (
                      <CButton
                        color="secondary"
                        shape="rounded-0"
                        variant="outline"
                        size=""
                        className="mt-2 text-color"
                        style={{
                          fontFamily: "Trade Gothic LH",
                          textTransform: "uppercase",
                        }}
                      >
                        Add to bag
                      </CButton>
                    ) : (
                      <CButton
                        color="secondary"
                        shape="rounded-0"
                        variant="outline"
                        size="lg"
                        className="mt-3 text-color"
                        sx={{ width: "125px" }}
                        style={{
                          fontFamily: "Trade Gothic LH",
                          textTransform: "uppercase",
                        }}
                      >
                        Add to bag
                      </CButton>
                    ))}
                </Box>
              </Grid>
            );
          })}
        </Grid>
        <div className={"d-flex  center_small_image_box"}>
          {data?.button_text ? (
            <>
              <Button
                className="mt-4"
                sx={{
                  color: "black",
                  borderBottom: "1px solid black",
                  borderRadius: "0px",
                  // textTransform: "none",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "darkgray",
                  },
                  textTransform: "uppercase",
                  fontFamily: "Trade Gothic LH",
                }}
                onClick={(e) => {
                  window.open(data.button_url, "_blank");
                }}
              >
                {data?.button_text}
              </Button>
            </>
          ) : null}
          <Button
            className="mt-4 ms-3"
            sx={{
              color: "black",
              borderBottom: "1px solid black",
              borderRadius: "0px",
              // textTransform: "none",
              "&:hover": { backgroundColor: "transparent", color: "darkgray" },
              textTransform: "uppercase",
              fontFamily: "Trade Gothic LH",
            }}
            onClick={(e) => {
              if (quiz.disable_intro === true) {
                navigate(`/preview/enable_intropage/${id}`);
              } else {
                navigate(`/preview/${id}`);
              }
            }}
          >
            RETAKE QUIZ
          </Button>
        </div>
      </div>
    );
  };
  const CenterStructure = () => {
    return (
      <Grid
        className="radioContainer center_part_radioContainer"
        sx={{ marginTop: "100px" }}
      >
        <Grid sx={{ width: "100%" }}>
          <Grid sx={{ marginInline: "5px" }}>
            <Typography
              variant="body1"
              color="inherit"
              style={{ fontFamily: "Cormorant Garamond" }}
            >
              {data.dont_show ? "" : topText}
            </Typography>
            <Typography
              mb={3}
              variant="h6"
              color="inherit"
              style={{ fontFamily: "Cormorant Garamond" }}
            >
              {data.subheading}
            </Typography>
            <Typography
              mb={3}
              variant="h4"
              color="inherit"
              style={{ fontFamily: "Cormorant Garamond" }}
            >
              {data.heading}
            </Typography>
            {data?.placeholder ? (
              <Typography
                sx={{ mb: 2.5 }}
                className="description_result_text"
                style={{ fontFamily: "Cormorant Garamond" }}
              >
                {data?.placeholder}
              </Typography>
            ) : null}
            {data?.result_image_obj && (
              <div className="d-flex justify-center mt-3">
                <img
                  src={`${process.env.REACT_APP_API_URL}/result/${data?.result_image_obj}`}
                  height={790 - parseInt(data.image_width)}
                  width={790 - parseInt(data.image_width)}
                  alt=""
                  srcset=""
                />
              </div>
            )}
          </Grid>
          <div style={{ paddingBlock: "20px" }}>
            <AnswerLayout />
          </div>
        </Grid>
      </Grid>
    );
  };
  const RightStructure = () => {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6} sm={12} sx={{ width: "100%" }}>
          <Grid
            className="radioContainer"
            sx={{ marginTop: "0px !important", height: "100%" }}
          >
            <Grid sx={{ width: "100%" }}>
              <Grid sx={{ marginInline: "5px" }}>
                <Typography variant="body1" color="inherit">
                  {data.dont_show ? "" : topText}
                </Typography>
                <Typography
                  mb={3}
                  variant="h6"
                  color="inherit"
                  style={{ fontFamily: "Cormorant Garamond" }}
                >
                  {data.subheading}
                </Typography>
                <Typography
                  mb={3}
                  variant="h4"
                  color="inherit"
                  style={{ fontFamily: "Cormorant Garamond" }}
                >
                  {data.heading}
                </Typography>
                {data?.placeholder ? (
                  <Typography
                    sx={{ mb: 2.5 }}
                    className="description_result_text"
                    style={{ fontFamily: "Cormorant Garamond" }}
                  >
                    {data?.placeholder}
                  </Typography>
                ) : null}
              </Grid>
              <div style={{ paddingBlock: "20px" }}>
                <AnswerLayout />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sm={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="right_structure_img">
            <img
              src={`${process.env.REACT_APP_API_URL}/result/${data?.result_image_obj}`}
              alt=""
              srcset=""
            />
          </div>
        </Grid>
      </Grid>
    );
  };
  const LeftStructure = () => {
    return (
      <Grid container justifyContent="center">
        <Grid
          item
          xs={12}
          md={6}
          sm={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="left-structure-img">
            <img
              src={`${process.env.REACT_APP_API_URL}/result/${data.result_image_obj}`}
              alt=""
              srcset=""
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6} sm={12} sx={{ width: "100%" }}>
          <Grid
            className="radioContainer"
            sx={{ marginTop: "0px !important", height: "100%" }}
          >
            <Grid sx={{ width: "100%" }}>
              <Grid sx={{ marginInline: "5px" }}>
                <Typography variant="body1" color="inherit">
                  {data.dont_show ? "" : topText}
                </Typography>
                <Typography
                  mb={3}
                  mt={3}
                  variant="h6"
                  color="inherit"
                  style={{ fontFamily: "Cormorant Garamond" }}
                >
                  {data.subheading}
                </Typography>
                <Typography
                  mb={3}
                  variant="h4"
                  color="inherit"
                  style={{ fontFamily: "Cormorant Garamond" }}
                >
                  {data.heading}
                </Typography>
                {data?.placeholder ? (
                  <Typography
                    sx={{ mb: 2.5 }}
                    className="description_result_text"
                    style={{ fontFamily: "Cormorant Garamond" }}
                  >
                    {data?.placeholder}
                  </Typography>
                ) : null}
              </Grid>
              <div style={{ paddingBlock: "20px" }}>
                <AnswerLayout />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const BackgroundStructure = () => {
    return (
      //   <div
      //     style={{
      //       backgroundImage: `url(${
      //         process.env.REACT_APP_API_URL
      //       }/result/${encodeURIComponent(data?.result_image_obj)})`,
      //       backgroundRepeat: "no-repeat",
      //       backgroundSize: "cover",
      //     }}
      //   >
      //     <div
      //       style={{
      //         top: 0,
      //         left: 0,
      //         width: "100%",
      //         backgroundColor: `${data?.background_color}`,
      //         opacity: `${data.background_opacity}%`,
      //       }}
      //     >
      <div
        className="background-image"
        style={{
          backgroundImage: `url(${
            process.env.REACT_APP_API_URL
          }/result/${encodeURIComponent(data?.result_image_obj)})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100vh",
          zIndex: "1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "auto",
        }}
      >
        <div
          className="overlay"
          style={{
            position: "absolute",
            inset: "0",
            width: "100%",
            height: "100%",
            zIndex: "2",
            top: "50px",
          }}
        ></div>

        <div
          className="background-image"
          style={{
            position: "absolute",
            top: "50px",
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: "3",
            opacity: `${data.background_opacity}%`,
            backgroundColor: `${data.background_color}`,
          }}
        ></div>
        <Grid
          className="radioContainer center_part_radioContainer background-section-content"
          sx={{ marginTop: "0px !important" }}
        >
          <Grid sx={{ width: "100%", opacity: "1", zIndex: "3"  }}>
            <Grid sx={{ marginInline: "5px" }}>
              <Typography variant="body1" color="inherit">
                {data.dont_show ? "" : topText}
              </Typography>
              <Typography
                mb={3}
                variant="h6"
                color="inherit"
                style={{ fontFamily: "Cormorant Garamond" }}
              >
                {data.subheading}
              </Typography>
              <Typography
                mb={3}
                variant="h4"
                color="inherit"
                style={{ fontFamily: "Cormorant Garamond" }}
              >
                {data.heading}
              </Typography>
              {data?.placeholder ? (
                <Typography
                  sx={{ mb: 2.5 }}
                  className="description_result_text"
                  style={{ fontFamily: "Cormorant Garamond" }}
                >
                  {data?.placeholder}
                </Typography>
              ) : null}
            </Grid>
            <div style={{ paddingBlock: "20px" }}>
              <AnswerLayout />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };
  const TopStructure = () => {
    return (
      <Grid className="radioContainer">
        <Grid sx={{ width: "100%" }}>
          <div className="d-flex justify-center">
            <img
              src={`${process.env.REACT_APP_API_URL}/result/${data?.result_image_obj}`}
              height={790 - parseInt(data.image_width)}
              width={790 - parseInt(data.image_width)}
              alt=""
              srcset=""
            />
          </div>
          <Grid>
            <Typography variant="body1" color="inherit">
              {data.dont_show ? "" : topText}
            </Typography>
            <Typography
              mt={3}
              variant="h4"
              color="inherit"
              style={{ fontFamily: "Cormorant Garamond" }}
            >
              {data.heading}
            </Typography>
            <Typography
              variant="h6"
              color="inherit"
              style={{ fontFamily: "Cormorant Garamond" }}
            >
              {data.subheading}
            </Typography>
          </Grid>
          <div style={{ paddingBlock: "20px" }}>
            <AnswerLayout />
          </div>
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      {data === null ? (
        <DefaultStructure />
      ) : data?.image_position === "center" ? (
        <CenterStructure />
      ) : (
        {
          center: <CenterStructure />,
          left: <LeftStructure />,
          right: <RightStructure />,
          background: <BackgroundStructure />,
          top: <TopStructure />,
        }[data?.image_position]
      )}
    </>
  );
};

export default ResultLayout;
