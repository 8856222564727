import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useParams, Link } from "react-router-dom";
import Sidenav from "../../Sidenav";
import { Grid, Icon, ListItemIcon } from "@mui/material";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import type_icon_1 from "../../../public/type_icon_1.svg";
import type_icon_2 from "../../../public/type_icon_2.svg";
import type_icon_3 from "../../../public/type_icon_3.svg";
import type_icon_4 from "../../../public/type_icon_4.svg";
import type_icon_5 from "../../../public/type_icon_5.svg";
import type_icon_6 from "../../../public/type_icon_6.svg";
import type_icon_7 from "../../../public/type_icon_7.svg";
import type_icon_8 from "../../../public/type_icon_8.svg";
import { CardImage } from "@progress/kendo-react-layout";

const question_icons = [
  type_icon_1,
  type_icon_2,
  type_icon_3,
  type_icon_4,
  type_icon_5,
  type_icon_6,
  type_icon_7,
  type_icon_8,
];

const question_type = [
  "Image with text",
  "Radio buttons",
  "Text box",
  "Range slider",
  "Input",
  "select",
  "info",
  "File upload",
];

export default function Questiontype() {
  const { id } = useParams();

  const question_onclick = [
    `/quiz/image_question/add/${id}`,
    `/quiz/Radio_question/add/${id}`,
    `/quiz/text_box_question/add/${id}`,
    `/quiz/range_question/add/${id}`,
    `/quiz/input_question/add/${id}`,
    `/quiz/select_question/add/${id}`,
    `/quiz/info_question/add/${id}`,
    `/quiz/file_upload_question/add/${id}`,
  ];
  return (
    <Sidenav>
      <Grid container maxWidth="lg" className="mx-16">
        <Grid className="d-flex" item sm={12} xs={12} lg={6} md={4}>
          <Grid className="">
            <Link to={{ pathname: `/quizzes/intropage/${id}` }}>
              {" "}
              <KeyboardBackspaceRoundedIcon className="intro_backbutton" />{" "}
            </Link>
          </Grid>
          <Grid className="select_text mt-2 px-4">Select Question Type</Grid>
        </Grid>
      </Grid>

      <div className="mx-16 mt-8 d-flex justify-center ">
        <Grid container maxWidth="lg" spacing={3}>
          {question_type?.map((text, index) => (
            <Grid item sm={12} lg={4} xs={12} md={12}>
              <Link to={{ pathname: question_onclick[index] }}>
                <Card
                  className="que_type_card"
                  sx={{ minWidth: 275, height: 280 }}
                >
                  <CardContent>
                    <Typography
                      style={{ height: "150px" }}
                      className="d-flex justify-center type_icon "
                      sx={{ mt: 7 }}
                      variant="h1"
                    >
                      <CardImage
                        className="Question_type_icons"
                        src={question_icons[index]}
                      ></CardImage>
                    </Typography>
                    <Typography
                      className="d-flex justify-center question_name"
                      component="div"
                    >
                      {text}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>
    </Sidenav>
  );
}
