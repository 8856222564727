import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";
import { CButton } from "@coreui/react";
import InfoIcon from "@mui/icons-material/Info";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";

const EditRightRadioCard = ({
  imgquedata,
  imgansdata,
  question_add_rimage,
  allquestion,
  question_show_rimage,
}) => {
  console.log(
    "question_show_rimage",
    question_show_rimage,
    imgquedata?.question_add_rimage
  );

  let image_url =
    question_show_rimage.length !== 0
      ? question_show_rimage
      : `${process.env.REACT_APP_API_URL}/question/${imgquedata?.question_add_rimage}`;
  console.log("image_url", image_url);

  const { id } = useParams();
  const question_index = allquestion?.result?.findIndex(
    (item) => item._id === id
  );


  let backgroundImg;
  if (
    question_add_rimage?.length === 0 &&
    imgquedata?.question_add_rimage != 0
  ) {
    if (imgquedata.question_add_rimage) {
      backgroundImg = `url(${
        process.env.REACT_APP_API_URL
      }/question/${encodeURIComponent(imgquedata.question_add_rimage)})`;
    }
  } else if (question_show_rimage) {
    backgroundImg = `url(${question_show_rimage})`;
  } else {
    backgroundImg = "";
  }

  const containerStyle = {
    position: "relative",
    width: "100%",
    // height: '100vh',
    backgroundImage: backgroundImg,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: `${imgquedata?.background_color}`,
    opacity: `${imgquedata.background_opacity}%`,
  };
  return (
    <>
      <Grid item xs={12} sm={12} lg={8} md={12} spacing={3} className="mt-4">
        <Card
          sx={imgquedata.image_position === "background" && containerStyle}
          className="right_quiz_card"
        >
          {imgquedata.image_position === "background" && (
            <div style={overlayStyle} className="backDiv"></div>
          )}
          <CardContent>
            <Grid className="radio_card_top">
              {!imgquedata.hide_progress_bar && (
                <Typography
                  variant="h6"
                  className="question_length"
                  component="div"
                >
                  {question_index + 1 + " of " + allquestion?.result?.length}
                </Typography>
              )}
              {(imgquedata.image_position === "top" ||
                imgquedata.image_position === "left") &&
                (question_show_rimage.length !== 0 ||
                  imgquedata?.question_add_rimage) && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={image_url}
                      width={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}
              <Typography sx={{ mb: 1 }} className="right_imgque_heading">
                {imgquedata.heading}
              </Typography>
              <Typography sx={{ mb: 1.5 }} className="right_imgque_subheading">
                {imgquedata.subheading}
              </Typography>
              {imgquedata.image_position === "center" &&
                (question_show_rimage.length !== 0 ||
                  imgquedata?.question_add_rimage) && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={image_url}
                      width={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        !imgquedata.image_width || imgquedata.image_width === 0
                          ? "60px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}

              <div
                className="option_main_contain"
                style={{
                  margin:
                    imgquedata.question_layout_style === "Wide" ||
                    imgquedata.question_layout_style === "Default"
                      ? "30px 160px 15px 195px"
                      : imgquedata.layout_display === "Vertical"
                      ? "30px 280px"
                      : "30px 60px 30px 60px",
                }}
              >
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    sx={{ display: "flex" }}
                  >
                    {imgansdata.map((item, index) => {
                      return (
                        <Grid
                          item
                          wrap="nowrap"
                          className={
                            imgquedata.question_layout_style === "Narrow"
                              ? "pb-1"
                              : ""
                          }
                          xs={
                            imgquedata.layout_display === "Vertical"
                              ? imgquedata.question_layout_style === "Narrow"
                                ? 6
                                : 12
                              : imgquedata.question_layout_style !== "Narrow"
                              ? 6
                              : 3
                          }
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {/* ******************************************************************************WIDE LAYOUT START***************************** */}
                            {imgquedata.question_layout_style === "Wide" && (
                              <FormControlLabel
                                className="radio_que_grop_box"
                                value={
                                  item?.answer?.title
                                    ? item?.answer?.title
                                    : item.title
                                    ? item.title
                                    : "value"
                                }
                                control={
                                  <Radio
                                    sx={{
                                      color: "black",
                                      "&.Mui-checked": {
                                        color: "black",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  item?.answer?.title
                                    ? item?.answer?.title
                                    : item.title
                                    ? item.title
                                    : "value"
                                }
                              />
                            )}

                            {/* ******************************************************************************WIDE LAYOUT END***************************** */}
                            {/* ******************************************************************************NARROW LAYOUT START***************************** */}
                            {imgquedata.question_layout_style === "Narrow" && (
                              <FormControlLabel
                                className="radio_que_grop_box_narrow"
                                sx={{
                                  width:
                                    imgquedata.question_layout_style !== "Wide"
                                      ? "185px"
                                      : "150px",
                                }}
                                labelPlacement="top"
                                value={
                                  item?.answer?.title
                                    ? item?.answer?.title
                                    : item.title
                                    ? item.title
                                    : "value"
                                }
                                control={
                                  <Radio
                                    sx={{
                                      color: "black",
                                      "&.Mui-checked": {
                                        color: "black",
                                      },
                                      display: "flex !important",
                                      justifyContent: "start !important",
                                    }}
                                  />
                                }
                                label={
                                  <div className="narrow_title_radio">
                                    <p>
                                      {item?.answer?.title
                                        ? item?.answer?.title
                                        : item.title
                                        ? item.title
                                        : "value"}
                                    </p>
                                  </div>
                                }
                              />
                            )}

                            {/* ******************************************************************************NARROW LAYOUT END***************************** */}
                            {/* ******************************************************************************DEFAULT LAYOUT START***************************** */}
                            {imgquedata.question_layout_style === "Default" && (
                              <FormControlLabel
                                className="radio_que_grop_box_default"
                                value={
                                  item?.answer?.title
                                    ? item?.answer?.title
                                    : item.title
                                    ? item.title
                                    : "value"
                                }
                                control={
                                  <Radio
                                    sx={{
                                      color: "black",
                                      "&.Mui-checked": {
                                        color: "black",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <div className="narrow_title_radio">
                                    <p>
                                      {item?.answer?.title
                                        ? item?.answer?.title
                                        : item.title
                                        ? item.title
                                        : "value"}
                                    </p>
                                  </div>
                                }
                              />
                            )}
                          </div>
                          {/* ******************************************************************************DEFAULT LAYOUT END***************************** */}
                        </Grid>
                      );
                    })}
                  </Grid>
                </RadioGroup>
              </div>

              {imgquedata.image_position === "right" &&
                (question_show_rimage.length !== 0 ||
                  imgquedata?.question_add_rimage) && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={image_url}
                      width={
                        imgquedata.image_width === 0
                          ? "40px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                      height={
                        imgquedata.image_width === 0
                          ? "40px"
                          : `${Math.min(300, imgquedata.image_width / 2)}px`
                      }
                    />
                  </div>
                )}

              {/* {imgquedata.image_position !== "top" && (
                <>
                  <Typography sx={{ mb: 1.5 }} className="right_imgque_heading">
                    {imgquedata.heading}
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    className="right_imgque_subheading"
                  >
                    {imgquedata.subheading}
                  </Typography>
                </>
              )}

              {imgquedata.image_position !== "background" && (
                <div
                  className={
                    imgquedata.image_position === "center" ||
                    imgquedata.image_position === "top"
                      ? "center_position"
                      : imgquedata.image_position === "right"
                      ? "right_position"
                      : "right_imgmain_image"
                  }
                >
                  {question_show_rimage && question_add_rimage.length !== 0 ? (
                    imgquedata.image_position == "center" ||
                    imgquedata.image_position === "top" ? (
                      <img
                        src={question_show_rimage}
                        width={`${790 - imgquedata.image_width}px`}
                        height={`${790 - imgquedata.image_width}px`}
                      />
                    ) : (
                      <img
                        src={question_show_rimage}
                        width={`790px`}
                        height={`790px`}
                      />
                    )
                  ) : question_add_rimage.length === 0 &&
                    imgquedata?.question_add_rimage != 0 ? (
                    imgquedata.image_position === "center" ||
                    imgquedata.image_position === "top" ? (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/question/${imgquedata?.question_add_rimage}`}
                        width={`${790 - imgquedata.image_width}px`}
                        height={`${790 - imgquedata.image_width}px`}
                      />
                    ) : (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/question/${imgquedata?.question_add_rimage}`}
                        width={`790px`}
                        height={`790px`}
                      />
                    )
                  ) : question_add_rimage.length === 0 &&
                    question_show_rimage.length === 0 ? (
                    ""
                  ) : (
                    ""
                  )}
                </div>
              )}
              {imgquedata.image_position === "top" && (
                <>
                  <Typography sx={{ mb: 1.5 }} className="right_imgque_heading">
                    {imgquedata.heading}
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    className="right_imgque_subheading"
                  >
                    {imgquedata.subheading}
                  </Typography>
                </>
              )}
              <div
                className={
                  imgquedata.layout_display === "Vertical"
                    ? "verticle"
                    : " d-flex gap-4 main_option mt-8"
                }
              >
                <Typography
                  variant="body2"
                  className={
                    imgquedata.layout_display === "Vertical" ? "mt-4" : ""
                  }
                >
                  <FormControl
                    className={
                      imgquedata.question_layout_style === "Narrow"
                        ? "narrow_radio_button transparent-background"
                        : imgquedata.question_layout_style === "Default"
                        ? "default_radio_button transparent-background"
                        : "option_button   mt-4 transparent-background"
                    }
                  >
                    <RadioGroup
                      sx={{ width: "100%" }}
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      defaultValue="value"
                    >
                      {imgansdata.map((item, index) => (
                        <>
                          {imgquedata.question_layout_style !== "Narrow" ? (
                            <FormControlLabel
                              sx={
                                imgquedata.question_layout_style !==
                                  "Default" && { backgroundColor: "#FFFFFF" }
                              }
                              className={
                                imgquedata.question_layout_style !== "Default"
                                  ? `custom-option-radio ${
                                      item.title ? "active" : ""
                                    }`
                                  : "custom-option-radio-default-button"
                              }
                              value={item?.answer?.title}
                              control={<Radio className="custom-radio" />}
                              label={
                                item?.answer?.title
                                  ? item?.answer?.title
                                  : item.title
                                  ? item.title
                                  : "value"
                              }
                            />
                          ) : (
                            <>
                              {imgquedata.layout_display !== "Vertical" ? (
                                <Grid sx={{ marginLeft: "30px" }} item xs={2}>
                                  <div
                                    className={`custom-option-radio ${
                                      item.title ? "active" : ""
                                    }`}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      backgroundColor: "#FFFFFF",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        wordBreak: "break-all",
                                        textAlign: "center",
                                        fontSize: "large",
                                      }}
                                      variant="body2"
                                      color="inherit"
                                      sx={{ marginTop: "10px" }}
                                    >
                                      {item?.answer?.title
                                        ? item?.answer?.title
                                        : item.title
                                        ? item.title
                                        : "value"}
                                    </Typography>
                                    <FormControlLabel
                                      value={item?.answer?.title}
                                      control={
                                        <Radio
                                          className="custom-radio"
                                          sx={{
                                            marginLeft: "44%",
                                            backgroundColor: "#FFFFFF",
                                          }}
                                        />
                                      }
                                      // label={item.title}
                                    />
                                  </div>
                                </Grid>
                              ) : (
                                <Grid sx={{ marginLeft: "30px" }} item xs={6}>
                                  <div
                                    className={`custom-option-radio ${
                                      item.title ? "active" : ""
                                    }`}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "334px",
                                      backgroundColor: "#FFFFFF",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        wordBreak: "break-all",
                                        textAlign: "center",
                                        fontSize: "large",
                                      }}
                                      variant="body2"
                                      color="inherit"
                                      sx={{ marginTop: "20px" }}
                                    >
                                      {item?.answer?.title
                                        ? item?.answer?.title
                                        : item.title
                                        ? item.title
                                        : "value"}
                                    </Typography>
                                    <FormControlLabel
                                      value={item?.answer?.title}
                                      control={
                                        <Radio
                                          className="custom-radio"
                                          sx={{ marginLeft: "44%" }}
                                        />
                                      }
                                      // label={item.title}
                                    />
                                  </div>
                                </Grid>
                              )}
                            </>
                          )}
                        </>
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Typography>
              </div> */}

              <div className="info_icon mt-12">
                {imgquedata.tooltip_heading ? (
                  <>
                     <Container sx={{ paddingBottom: 2 }}>
                      <Tooltip
                        arrow
                        sx={{
                          backgroundColor: "transparent",
                          ":hover": "none",
                        }}
                        title={
                          <Grid>
                            <h5 style={{ textAlign: "center" }}>
                              {imgquedata?.tooltip_heading}
                            </h5>
                            <p>{imgquedata?.tooltip_description}</p>
                          </Grid>
                        }
                      >
                        <IconButton>
                          <ReportRoundedIcon
                            sx={{ fontSize: "50px !important" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Container>
                  </>
                ) : (
                  ""
                )}
              </div>

              <Grid container className="" justifyContent="center">
                <Grid
                  className="back"
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <WestIcon /> <span className="mx-2"> BACK </span>
                </Grid>
                {imgquedata.skip_que && (
                  <Grid
                    className="back"
                    xs
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <span className="mx-2"> SKIP </span>
                  </Grid>
                )}

                <Grid
                  className="next"
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <span className="mx-2 "> NEXT </span> <EastIcon />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default EditRightRadioCard;
