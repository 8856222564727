import React, { useContext, useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FetchContext } from "../../context/FetchContext";
import { AuthContext } from "../../context/AuthContext";
import CloseIcon from "@mui/icons-material/Close";

const PreviewIntroPage = () => {
  const { authAxios } = useContext(FetchContext);
  const { state, dispatch } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate(`/question/${state?.question?.result[0]?._id}/0`);
  };
  const [loading, setLoading] = useState(true);
  const [quizData, setQuizData] = useState();
  const intro_quiz = state?.question?.quiz;

  useEffect(() => {
    authAxios 
      .post(`${process.env.REACT_APP_API_URL}/question/show_question/${id}`)
      .then((res) => {
        const { data } = res;
        console.log("data=================>",data);
        
        setQuizData(data.quiz);
        setLoading(false);
        dispatch({
          type: "QUESTION",
          payload: {
            question: data,
          },
        });
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }, [id]);
  return (
    <>
      {loading && (
        <div sx={{ display: "flex" }}>
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="success" />
          </Backdrop>
        </div>
      )}
      {!loading && (
        <>
          <Card>
            <div className=" d-flex justify-end p-2 mx-3 ">
              <Link to="/quizzes/allquiz">
                {" "}
                <CloseIcon
                  className="m-2 font-semibold text-black  "
                  style={{ fontSize: "35px" }}
                />{" "}
              </Link>
            </div>
          </Card>
          <div
            className=""
            style={{
              backgroundColor:
                quizData?.background_type === "Color" &&
                quizData?.content_alignment === "center"
                  ? quizData?.overlay_color
                  : "",
              backgroundImage:
                quizData?.background_type === "Image" &&
                quizData?.content_alignment === "center"
                  ? `url(${process.env.REACT_APP_API_URL}/quiz/${quizData?.quiz_desktop_image})`
                  : "",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            {quizData?.content_alignment === "left" && (
              <Grid container>
                <Grid sm={12} md={6} lg={6} item>
                  {state.question?.quiz?.quiz_add_image && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        className="introImg"
                        src={`${process.env.REACT_APP_API_URL}/quiz/${quizData?.quiz_add_image}`}
                        alt=""
                        srcset=""
                      />
                    </div>
                  )}
                </Grid>
                <Grid
                  className="introRightSide"
                  sm={12}
                  md={6}
                  lg={6}
                  item
                  sx={{
                    backgroundColor:
                      quizData?.background_type === "Color"
                        ? `${quizData?.overlay_color}`
                        : "",
                    backgroundImage:
                      quizData?.background_type === "Image"
                        ? `url(${process.env.REACT_APP_API_URL}/quiz/${quizData?.quiz_desktop_image})`
                        : "",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <Container className="custom-container">
                    <Typography mb={5} variant="h3" color="initial">
                      {quizData?.heading}
                    </Typography>
                    <Typography variant="h5" color="initial">
                      {quizData?.subheading}
                    </Typography>
                    <Button
                      onClick={handleRedirect}
                      className="btn-default"
                      sx={{ mt: 5 }}
                      variant="contained"
                    >
                      {quizData?.button_text}
                    </Button>
                  </Container>
                  <div
                    style={{
                      backgroundColor: `${quizData?.overlay_color}`,
                      opacity: `${quizData?.overlay_opacity}%`,
                    }}
                    className="backDiv"
                  ></div>
                </Grid>
              </Grid>
            )}
            {quizData?.content_alignment === "right" && (
              <Grid container>
                {console.log("data insise", quizData)}
                <Grid
                  className="introRightSide"
                  sm={12}
                  md={6}
                  lg={6}
                  item
                  sx={{
                    backgroundColor:
                      quizData?.background_type === "Color"
                        ? `${quizData?.overlay_color}`
                        : "",
                    backgroundImage:
                      quizData?.background_type === "Image"
                        ? `url(${process.env.REACT_APP_API_URL}/quiz/${quizData?.quiz_desktop_image})!important`
                        : "",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <Container className="custom-container">
                    <Typography
                      mb={5}
                      variant="h3"
                      color="initial"
                      className="preview_head"
                    >
                      {quizData?.heading}
                    </Typography>
                    <Typography variant="h5" color="initial">
                      {quizData?.subheading}
                    </Typography>
                    <Button
                      onClick={handleRedirect}
                      className="btn-default"
                      sx={{ mt: 5 }}
                      variant="contained"
                    >
                      {quizData?.button_text}
                    </Button>
                  </Container>
                </Grid>
                <Grid sm={12} md={6} lg={6} item>
                  {quizData?.quiz_add_image && (
                    <img
                      className="intropreviewImg"
                      src={`${process.env.REACT_APP_API_URL}/quiz/${quizData?.quiz_add_image}`}
                      alt=""
                      srcset=""
                    />
                  )}
                </Grid>
              </Grid>
            )}
            {quizData?.content_alignment === "center" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "93vh",
                }}
              >
                <div>
                  <Typography mb={3} variant="h3" color="initial">
                    {quizData?.heading}
                  </Typography>
                  <Typography
                    mb={3}
                    variant="h5"
                    color="initial"
                    align="center"
                  >
                    {quizData?.subheading}
                  </Typography>
                </div>
                {quizData?.quiz_add_image && (
                  <div className="d-flex justify-center mt-3">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/quiz/${quizData?.quiz_add_image}`}
                      height={790 - parseInt(quizData.image_width)}
                      width={790 - parseInt(quizData.image_width)}
                      alt=""
                      srcset=""
                    />
                  </div>
                )}
                <div>
                  <Button
                    onClick={handleRedirect}
                    className="btn-default"
                    sx={{ mt: 5 }}
                    variant="contained"
                  >
                    {quizData?.button_text}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PreviewIntroPage;
