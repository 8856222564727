import { Box, Chip, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Buttton from "../Button/Buttton";
import Pagestepper from "../intropage/Pagestepper";
import Sidenav from "../Sidenav";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { FetchContext } from "../../context/FetchContext";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-hot-toast";
import LeftResult from "./LeftResult";
import RightResult from "./RightResult";
import CreateQuestionTitle from "../Title/Title";
import ThirdPartResult from "./ThirdPartResult";

const MainResult = () => {
  const { id, result_id } = useParams();
  const { authAxios } = useContext(FetchContext);
  const { state } = useContext(AuthContext);
  console.log("state==========>", state);

  const navigate = useNavigate();
  const quiz = state?.allquiz?.filter((item) => item._id === id)[0];

  const [result_add_an_iimage, setresult_add_an_iimage] = useState([]);
  const [result_show_iimage, setresult_show_iimage] = useState([]);

  const [validation, setvalidation] = useState({
    heading: false,
    result_name: false,
    url: false,
  });
  const [isSaveDisable, setIsSaveDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [resultProduct, setResultProduct] = useState([]);
  const [qustionData, setQuestionData] = useState();
  console.log("qustionData", qustionData);
  const [answerOptions, setAnswerOptions] = useState([]);

  const preview = () => {
    if (quiz.disable_intro === true) {
      navigate(`/preview/enable_intropage/${id}`);
    } else {
      navigate(`/preview/${id}`);
    }
  };

  const [resultsettingdata, setresultsettingdata] = useState({
    heading: "",
    subheading: "",
    placeholder: "",
    image_position: "center",
    image_width: "",
    add_bag_button_width: false,
    background_opacity: 0,
    background_color: "#ffffff",
    button_text: "",
    product_image_position: "auto",
    product_image_height: 200,
    show_quantity_picker: false,
    show_description: false,
    limit_description: false,
    show_vendor: false,
    show_product_type: false,
    show_money_saved: false,
    show_product_title: false,
    product_featured_image: false,
    product_second_image: false,
    show_all_product_image: false,
    show_variant_name: false,
    variants_box: false,
    separate_product: false,
    hide_variants: false,
    hide_add_to_bag: false,
    hide_price: false,
    hide_link_product_img: false,
    button_url: "",
    quiz_id: id,
    from: 0,
    to: 0,
    select_products: [],
    result_path: [],
    result_image_obj: "",
    result_name: "",
  });

  const validateURL = (input) => {
    if (input) {
      const urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-zA-Z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-zA-Z\\d_]*)?$",
        "i" // fragment locator
      );
      let url = urlPattern.test(input);
      return urlPattern.test(input);
    } else {
      return true;
    }
  };

  function getResultData() {
    authAxios
      .get(`${process.env.REACT_APP_API_URL}/result/result_data/${result_id}`)
      .then((res) => {
        if (res.data.result !== null) {
          setresultsettingdata(res.data.result);
          setResultProduct(res.data?.result?.select_products);
          setAnswerOptions(res.data?.result?.result_path);
        }
        if (res?.data?.result?.result_image_obj !== null) {
          setresult_add_an_iimage(res?.data?.result?.result_image_obj);
          setresult_show_iimage(
            `${process.env.REACT_APP_API_URL}/result/${res?.data?.result?.result_image_obj}`
          );
        }
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }
  function getQuizData() {
    authAxios
      .post(`${process.env.REACT_APP_API_URL}/question/show_question/${id}`, id)
      .then((res) => {
        if (res.data.quiz.result_json !== null) {
          setQuestionData(res.data);
        }
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }
  React.useEffect(() => {
    if (id !== result_id) {
      getResultData();
    }
    if (id) {
      getQuizData();
    }
  }, [id, result_id]);

  const resultSave = () => {
    if (resultProduct.length !== 0) {
      resultsettingdata.select_products = resultProduct;
      resultsettingdata.result_path = answerOptions;

      const formData = new FormData();
      formData.append("resultsettingdata", JSON.stringify(resultsettingdata));
      formData.append("result_add_an_iimage", result_add_an_iimage);
      if (
        resultsettingdata.heading === "" ||
        resultsettingdata.result_name === ""
      ) {
        if (
          resultsettingdata.heading === "" &&
          resultsettingdata.result_name === ""
        ) {
          toast.error("Fields can't be blank");
          setvalidation({ heading: true, result_name: true });
        } else {
          if (resultsettingdata.heading === "") {
            toast.error("Result heading can't be blank");
            setvalidation({ heading: true });
          } else {
            toast.error("Result name can't be blank");
            setvalidation({ result_name: true });
          }
        }
      } else {
        if (
          resultsettingdata.button_url &&
          !validateURL(resultsettingdata.button_url)
        ) {
          toast.error("The redirect URL of the result is invalid.");
          setvalidation({ url: true });
        } else {
          setLoading(true);
          authAxios
            .post(
              id === result_id
                ? `${process.env.REACT_APP_API_URL}/result/create_result`
                : `${process.env.REACT_APP_API_URL}/result/edit_result/${result_id}`,
              formData
            )
            .then((res) => {
              if (res.data.msg === "success") {
                setLoading(false);
                navigate(`/quiz/introresultpage/${id}`);
              }
            })
            .catch((err) => {
              if (err) {
                setLoading(false);
                toast.error(err);
                console.log(err);
              }
            });
        }
      }
    } else {
      toast.error("Adding a product is mandatory.");
    }
  };

  return (
    <div>
      <Sidenav>
        <CreateQuestionTitle
          link={`/quiz/introresultpage/${id}`}
          quiz_name={quiz?.quiz_name}
          is_publish={quiz?.is_publish}
          preview={preview}
          questionsave={resultSave}
          isSaveDisable={isSaveDisable}
          loading={loading}
          quiz_id={id}
          questions={qustionData}
        />
        <div className="mt-4 mx-4">
          <Pagestepper result={"2"} />
        </div>

        <Box className="d-flex mx-4">
          <Grid container spacing={1}>
            <LeftResult
              resultsettingdata={resultsettingdata}
              setresultsettingdata={setresultsettingdata}
              result_add_an_iimage={result_add_an_iimage}
              setresult_add_an_iimage={setresult_add_an_iimage}
              result_show_iimage={result_show_iimage}
              setresult_show_iimage={setresult_show_iimage}
              validation={validation}
              setvalidation={setvalidation}
              setIsSaveDisable={setIsSaveDisable}
              setResultProduct={setResultProduct}
              resultProduct={resultProduct}
              validateURL={validateURL}
              quiz={qustionData?.quiz?.result_json}
            />
            <RightResult
              resultsettingdata={resultsettingdata}
              setresultsettingdata={setresultsettingdata}
              result_add_an_iimage={result_add_an_iimage}
              setresult_add_an_iimage={setresult_add_an_iimage}
              result_show_iimage={result_show_iimage}
              setResultProduct={setResultProduct}
              resultProduct={resultProduct}
              quiz={qustionData?.quiz?.result_json}
            />
            {!resultsettingdata.is_fallback &&
              qustionData?.quiz?.result_json?.result_logic ===
                "Basic(Default)" && (
                <ThirdPartResult
                  qustionData={qustionData}
                  setAnswerOptions={setAnswerOptions}
                  answerOptions={answerOptions}
                  setIsSaveDisable={setIsSaveDisable}
                />
              )}
          </Grid>
        </Box>
      </Sidenav>
    </div>
  );
};

export default MainResult;
