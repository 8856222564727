import * as React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Checkbox, Grid } from "@mui/material";
import InputComponent from "../../../inputfiled/Input_type_text";
import Inputtypefile from "../../../input_file/Input_type_file";
import Range from "../../../ranger/Range";
import { CButton, CDropdownItem, CDropdownMenu } from "@coreui/react";
import { CardContent, Divider } from "@material-ui/core";
import Select from "../../../dropdown/Select";
import AccordianTextBox from "../../../answer_option/AccordianTextBox";
import InputTextAreaComponent from "../../../inputfiled/Input_type_text_area";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { FetchContext } from "../../../../context/FetchContext";
import LeadtoQuestion from "../../../answer_option/LeadtoQuestion";

const EditLeftFileUpload = ({
  imgquedata,
  setimgquedata,
  setquestion_add_an_iimage,
  setquestion_show_iimage,
  question_add_an_iimage,
  validation,
  setvalidation,
  question_show_iimage,
  setIsSaveDisable,
  allquestion,
  allQuelist,
}) => {
  const question_type = [
    "File upload",
    "info",
    "Input",
    "Image with text",
    "Radio buttons",
    "Range slider",
    "Text box",
    "select",
  ];
  console.log("allquestion------------------------->?", allquestion);
  const [showImage, setShowImage] = React.useState(
    imgquedata.question_add_an_iimage !== ""
  );
  const [error, setError] = React.useState("");

  const handleremove = (i) => {
    setquestion_add_an_iimage({ question_add_an_iimage: [] });
    setquestion_show_iimage("");
    setShowImage(false);
    setIsSaveDisable(false);
    setimgquedata((prevState) => ({
      ...prevState,
      background_color: "#ffffff",
      background_opacity: 0,
      question_add_an_iimage: "",
      image_position: "center",
    }));
    setError("");
  };

  return (
    <>
      <Grid item xs={12} sm={12} lg={4} md={12} spacing={3} className="mt-4">
        {/* <Grid>
          <span
            style={{
              color: "#93999c",
              fontSize: "18px",
              letterSpacing: "-1px",
            }}
          >
            QUESTION TYPE
          </span>
          <Card sx={{ minWidth: 175 }} className="left_radio_card mt-2">
            <CardContent className="infoq_cardcontent">
              <div className="mx-2 ">
                <Select
                  defaultValue={"File upload"}
                  values={question_type}
                  onChange={(e) =>
                    setimgquedata({
                      ...imgquedata,
                      question_types: e.target.value,
                    })
                  }
                />
              </div>
            </CardContent>
          </Card>
        </Grid> */}
        <Grid>
          <span
            style={{
              color: "#93999c",
              fontSize: "18px",
              letterSpacing: "-1px",
            }}
          >
            QUESTION FORMATING
          </span>
          <Card className="left_second_rcard">
            <CardContent className="infoq_cardcontent">
              <div className="mx-2">
                <InputComponent
                  label_name={"Heading"}
                  onChange={(e) => {
                    setimgquedata({ ...imgquedata, heading: e.target.value });
                    setvalidation(false);
                    setIsSaveDisable(false);
                  }}
                  value={imgquedata?.heading}
                />
              </div>
              {validation ? (
                <div className="text-[red] mx-2 mt-[-8px]">
                  {" "}
                  Question heading can't be blank{" "}
                </div>
              ) : (
                ""
              )}
              <div className="mx-2">
                <InputComponent
                  label_name={"Sub heading"}
                  onChange={(e) => {
                    setimgquedata({
                      ...imgquedata,
                      subheading: e.target.value,
                    });
                    setIsSaveDisable(false);
                  }}
                  value={imgquedata?.subheading}
                />
              </div>
              <div className="mx-2">
                <InputComponent
                  label_name={"Placeholder (optional)"}
                  onChange={(e) => {
                    setimgquedata({
                      ...imgquedata,
                      placeholder: e.target.value,
                    });
                    setIsSaveDisable(false);
                  }}
                  placeholder="Drag & drop, or click to select files."
                  value={imgquedata?.placeholder}
                />
              </div>

              {(
                showImage == true
                  ? imgquedata.question_add_an_iimage === ""
                  : question_show_iimage.length === 0
              ) ? (
                <div className="mx-2 mt-4">
                  <Inputtypefile
                    name={question_add_an_iimage}
                    image_text="Add an image"
                    onChange={(e) => {
                      if (e.target.files.length !== 0) {
                        const file = e.target.files[0];
                        if (file.type.startsWith("image/")) {
                          setquestion_show_iimage(URL.createObjectURL(file));
                          setquestion_add_an_iimage(file);
                          setShowImage(false);
                          setIsSaveDisable(false);
                          setError("");
                        } else {
                          setError("Please select a valid image file.");
                        }
                      }
                    }}
                    error={error}
                  />
                </div>
              ) : (
                <>
                  <Grid className="d-flex mt-4" xs={12} container>
                    <Grid item className="pl-0 px-2" xs={8}>
                      <Inputtypefile
                        image_text="Change an image"
                        onChange={(e) => {
                          if (e.target.files.length !== 0) {
                            const file = e.target.files[0];
                            if (file.type.startsWith("image/")) {
                              setquestion_show_iimage(
                                URL.createObjectURL(file)
                              );
                              setquestion_add_an_iimage(file);
                              setIsSaveDisable(false);
                              setError("");
                            } else {
                              setError("Please select a valid image file.");
                            }
                          }
                        }}
                        error={error}
                      />
                    </Grid>
                    <Grid item className="pl-0 px-2" xs={4}>
                      <CButton
                        className="preview remove "
                        onClick={handleremove}
                      >
                        {" "}
                        Remove
                      </CButton>
                    </Grid>
                  </Grid>

                  <div className="mx-2 mt-4 ">
                    <Select
                      dropdown_text={"Image position"}
                      values={["center", "left", "right", "background", "top"]}
                      onChange={(e) => {
                        setimgquedata({
                          ...imgquedata,
                          image_position: e.target.value,
                        });
                        setIsSaveDisable(false);
                      }}
                      value={imgquedata?.image_position}
                    />
                  </div>
                  {(imgquedata.image_position === "center" ||
                    imgquedata.image_position === "top") && (
                    <div className="mx-2 mt-4 ">
                      <div style={{ fontSize: "18px" }}> Image width(px)</div>
                      <div className="image_range">
                        <Range
                          value={parseInt(imgquedata.image_width)}
                          onChange={(e) => {
                            setimgquedata({
                              ...imgquedata,
                              image_width: e.target.value,
                            });
                            setIsSaveDisable(false);
                          }}
                          max={true}
                        />
                      </div>
                    </div>
                  )}
                  {imgquedata.image_position === "background" && (
                    <>
                      <div
                        className="d-flex col-6 overlay_text mx-2 "
                        style={{ alignItems: "center" }}
                      >
                        <input
                          style={{ width: "50px", height: "50px" }}
                          type="color"
                          className="color_button w-52 h-14 mt-1 me-2"
                          onChange={(e) => {
                            setimgquedata({
                              ...imgquedata,
                              background_color: e.target.value,
                            });
                            setIsSaveDisable(false);
                          }}
                          defaultValue={imgquedata?.background_color}
                        ></input>
                        <div className="me-2" style={{ fontSize: "18px" }}>
                          Overlay color
                        </div>
                      </div>
                      <div className="mx-2 mt-4 ">
                        <div style={{ fontSize: "18px" }}> Overlay opacity</div>
                        <div className="image_range">
                          <Range
                            value={imgquedata.background_opacity}
                            onChange={(e) => {
                              setimgquedata({
                                ...imgquedata,
                                background_opacity: e.target.value,
                              });
                              setIsSaveDisable(false);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              <Divider
                style={{ background: "gray" }}
                className="mt-4 "
              ></Divider>

              <div className="mx-2 mt-2">
                <InputComponent
                  label_name={"Tooltip heading"}
                  onChange={(e) => {
                    setimgquedata({
                      ...imgquedata,
                      tooltip_heading: e.target.value,
                    });
                    setIsSaveDisable(false);
                  }}
                  value={imgquedata?.tooltip_heading}
                />
              </div>
              <div className="mx-2">
                <InputComponent
                  label_name={"Tooltip description"}
                  onChange={(e) => {
                    setimgquedata({
                      ...imgquedata,
                      tooltip_description: e.target.value,
                    });
                    setIsSaveDisable(false);
                  }}
                  value={imgquedata?.tooltip_description}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>

        <Grid className="mt-4">
          <span
            style={{
              color: "#93999c",
              fontSize: "18px",
              letterSpacing: "-1px",
            }}
          >
            QUESTION SETTINGS
          </span>
          <Card className="left_second_rcard">
            <CardContent className="infoq_cardcontent">
              <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                {" "}
                SETTINGS
              </Typography>
              {allquestion.quiz?.logic_jump && (
                <div className="mx-2 mt-4">
                  <LeadtoQuestion
                    setimgquedata={setimgquedata}
                    imgquedata={imgquedata}
                    allQuelist={allQuelist}
                    setIsSaveDisable={setIsSaveDisable}
                  />
                </div>
              )}

              <div className="mx-2 mt-3">
                <Select
                  dropdown_text={"File type"}
                  values={["image", "Doc"]}
                  onChange={(e) => {
                    setimgquedata({
                      ...imgquedata,
                      question_layout_style: e.target.value,
                    });
                    setIsSaveDisable(false);
                  }}
                  value={imgquedata?.question_layout_style}
                />
              </div>
              <div className="d-flex ">
                <Checkbox
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setimgquedata({
                      ...imgquedata,
                      multiselect_answer: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                  checked={imgquedata.multiselect_answer == true ? true : false}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-2 logic_jump">
                  Allow multiple uploads (up to 3)
                </Typography>
              </div>
              <div className="mx-2 mt-3 ">
                <InputTextAreaComponent
                  label_name={"Upload error message"}
                  onChange={(e) => {
                    setimgquedata({
                      ...imgquedata,
                      upload_error: e.target.value,
                    });
                    setIsSaveDisable(false);
                  }}
                  value={imgquedata?.upload_error}
                  placeholder={
                    "You can upload up to 3 files (5mb max size per file)"
                  }
                />
              </div>
              <div className="mx-2 mt-3 ">
                <InputComponent
                  label_name={"Marketing property name (optional)"}
                  onChange={(e) => {
                    setIsSaveDisable(false);
                    setimgquedata({
                      ...imgquedata,
                      property_name: e.target.value,
                    });
                  }}
                  value={imgquedata?.property_name}
                />
              </div>

              <div className="d-flex ">
                <Checkbox
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setimgquedata({
                      ...imgquedata,
                      skip_que: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                  checked={imgquedata.skip_que == true ? true : false}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-2 logic_jump">
                  Enable ability to skip this questions
                </Typography>
              </div>
              <div className="d-flex ">
                <Checkbox
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setimgquedata({
                      ...imgquedata,
                      hide_progress_bar: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                  checked={imgquedata?.hide_progress_bar == true ? true : false}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-2 logic_jump">
                  Hide progress bar
                </Typography>
              </div>
              {/* <div className="d-flex ">
                <Checkbox
                  className="mx-1 "
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  onChange={(e) => {
                    setimgquedata({
                      ...imgquedata,
                      dont_show: e.target.checked,
                    });
                    setIsSaveDisable(false);
                  }}
                  checked={imgquedata.dont_show == true ? true : false}
                ></Checkbox>
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Don't show this question while creating the answer paths
                </Typography>
              </div>
              <div className="mx-6 ml-14 opacity-70">
                <Typography color="text.secondary" className="mt-3 logic_jump">
                  Enable this option only if this question doesn't affect the
                  recommendations
                </Typography>
              </div> */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default EditLeftFileUpload;
