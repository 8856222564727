import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Button, Grid, TextField, Tooltip } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { CButton, CFormLabel } from "@coreui/react";
import { styled } from "@mui/system";
import { FetchContext } from "../../context/FetchContext";
import defaultImage from "../../public/default-fe.png";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Select from "../dropdown/Select";
const shop_id = "gid://shopify/Shop/83321454872";

const RightResult = ({
  resultsettingdata,
  result_add_an_iimage,
  result_show_iimage,
  resultProduct,
  setResultProduct,
  quiz,
}) => {
  const [image, setImage] = React.useState([]);
  const [variantData, setVariantData] = React.useState([]);
  const { authAxios } = React.useContext(FetchContext);
  const [products, setProducts] = React.useState([]);
  const navigate = useNavigate();
  const {
    state: {
      question: { result },
    },
  } = React.useContext(AuthContext);

  React.useEffect(() => {
    function getResultPreview() {
      authAxios
        .post(
          `${process.env.REACT_APP_API_URL}/result/result_preview`,
          resultProduct
        )
        .then((res) => {
          // setProductData(res.data.data);
          console.log("res----->", res);
          setProducts(res.data.result.products);
        })
        .catch((err) => {
          if (err) {
            console.log(err);
          }
        });
    }
    getResultPreview();
    setVariantData([]);
  }, [resultProduct]);

  const containerStyle = {
    position: "relative",
    width: "100%",
    // height: '100vh',
    backgroundImage: `url(${result_show_iimage ? result_show_iimage : ""})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: `${resultsettingdata?.background_color}`,
    opacity: `${resultsettingdata?.background_opacity}%`,
  };

  const MainImage = styled("img")({
    width:
      resultsettingdata?.product_image_height > 200
        ? "100%"
        : resultsettingdata?.product_image_height,
    height: ` ${resultsettingdata?.product_image_height}px`,
    borderRadius: 8,
    objectFit:
      resultsettingdata?.product_image_position === "stretch" && "cover",
  });

  const SubImage = styled("img")(({ isActive }) => ({
    width: "100%",
    height: "65px",
    borderRadius: 4,
    cursor: "pointer",
    "&:hover": {
      border: isActive && "2px solid #000",
    },
    ...(isActive && {
      border: "2px solid black",
    }),
  }));

  const handleSubImage = (e, index) => {
    let updatedImages = [...image];
    updatedImages[index] = e?.url;
    setImage(updatedImages);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        lg={!resultsettingdata.is_fallback && quiz?.result_logic === "Basic(Default)" ? 6 : 8}
        md={12}
        spacing={3}
        className="mt-4"
      >
        <Card
          sx={
            resultsettingdata?.image_position === "background" && containerStyle
          }
          className="right_quiz_card"
        >
          {resultsettingdata?.image_position === "background" && (
            <div style={overlayStyle} className="backDiv"></div>
          )}
          <CardContent>
            <Grid className="image_card_top">
              {resultsettingdata?.image_position !== "top" && (
                <>
                  <Typography
                    className="right_imgque_subheading"
                    style={{ fontFamily: "Cormorant Garamond" }}
                  >
                    {resultsettingdata?.subheading}
                  </Typography>
                  <Typography
                    className="right_imgque_heading"
                    style={{ fontFamily: "Cormorant Garamond" }}
                  >
                    {resultsettingdata?.heading}
                  </Typography>
                  <div className={"d-flex mt-4 center_small_image_box"}>
                    {resultsettingdata?.placeholder ? (
                      <Typography
                        sx={{ mb: 2.5 }}
                        className="description_result_text"
                        style={{ fontFamily: "Cormorant Garamond" }}
                      >
                        {resultsettingdata?.placeholder}
                      </Typography>
                    ) : null}
                  </div>
                </>
              )}
              {result_show_iimage &&
              result_add_an_iimage?.length !== 0 &&
              result_add_an_iimage !== undefined &&
              resultsettingdata?.image_position !== "background" ? (
                <div
                  className={
                    resultsettingdata?.image_position === "center" ||
                    resultsettingdata?.image_position === "top"
                      ? "center_position"
                      : resultsettingdata?.image_position === "right"
                      ? "right_position"
                      : "right_imgmain_image"
                  }
                >
                  <>
                    '
                    {result_show_iimage !== "" &&
                      (resultsettingdata?.image_position == "center" ||
                      resultsettingdata?.image_position === "top" ? (
                        <img
                          src={result_show_iimage}
                          width={`${790 - resultsettingdata?.image_width}px`}
                          height={`${790 - resultsettingdata?.image_width}px`}
                        />
                      ) : (
                        <img
                          src={result_show_iimage}
                          width={`790px`}
                          height={`790px`}
                        />
                      ))}
                  </>
                </div>
              ) : (
                <div className=""></div>
              )}
              {resultsettingdata?.image_position === "top" && (
                <>
                  <Typography sx={{ mb: 1.5 }} className="right_imgque_heading">
                    {resultsettingdata?.heading}
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    className="right_imgque_subheading"
                  >
                    {resultsettingdata?.subheading}
                  </Typography>
                </>
              )}

              <div>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    padding: "30px",
                    marginTop: "70px",
                    justifyContent: "center",
                  }}
                >
                  {products.map((product, index) => {
                    return (
                      <Grid item xs={4}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: 2,
                            borderRadius: 4,
                            maxWidth: 400,
                            margin: "auto",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <MainImage
                            src={
                              image[index] === null ||
                              image[index] === undefined
                                ? product.image_url || defaultImage
                                : image[index]
                            }
                            alt="The Notebook in Sky Blue"
                          />
                          {/* <MainImage src={image[index] === (null || undefined) ? product.image_url : image[index]} alt="The Notebook in Sky Blue" /> */}
                          <Grid
                            container
                            spacing={1}
                            sx={{
                              marginBottom: "40px",
                              marginTop: "40px",
                              justifyContent: "center",
                            }}
                          >
                            {resultsettingdata?.show_all_product_image ===
                              true &&
                              (resultsettingdata?.product_featured_image ===
                              false
                                ? product.product_json.variants.map(
                                    (variant) => {
                                      return (
                                        variant?.image !== null && (
                                          <Grid
                                            item
                                            xs={4}
                                            onClick={() =>
                                              handleSubImage(
                                                variant?.image,
                                                index
                                              )
                                            }
                                          >
                                            <SubImage
                                              src={variant?.image?.url}
                                              className={
                                                image[index] ===
                                                variant?.image?.url
                                                  ? "acive-border-result-page"
                                                  : ""
                                              }
                                              isActive={
                                                (image[index] ===
                                                (null || undefined)
                                                  ? product.image_url
                                                  : image[index]) ===
                                                variant?.image?.url
                                              }
                                            />
                                          </Grid>
                                        )
                                      );
                                    }
                                  )
                                : product.product_json.images.map((images) => {
                                    return (
                                      images !== null && (
                                        <Grid
                                          item
                                          xs={4}
                                          onClick={() =>
                                            handleSubImage(images, index)
                                          }
                                        >
                                          <SubImage
                                            src={images?.url}
                                            className={
                                              product.image_url === images?.url
                                                ? "acive-border-result-page"
                                                : ""
                                            }
                                            isActive={
                                              (image[index] ===
                                              (null || undefined)
                                                ? product.image_url
                                                : image[index]) === images?.url
                                            }
                                          />
                                        </Grid>
                                      )
                                    );
                                  }))}
                          </Grid>
                          {resultsettingdata?.show_product_title === false && (
                            <>
                              {resultsettingdata?.show_vendor === true && (
                                <Typography
                                  variant="h5"
                                  sx={{
                                    textTransform: "uppercase",
                                    fontFamily: "Cormorant Garamond",
                                    fontSize: "12px",
                                  }}
                                >
                                  {product?.product_json?.vendor}
                                </Typography>
                              )}
                              {resultsettingdata?.show_product_type ===
                                true && (
                                <Typography
                                  variant="h5"
                                  sx={{
                                    textTransform: "uppercase",
                                    fontFamily: "Cormorant Garamond",
                                    fontSize: "12px",
                                  }}
                                >
                                  {product?.product_json?.productType}
                                </Typography>
                              )}

                              <Typography
                                variant="h5"
                                className="ps-4 pe-4 pb-3 pt-2"
                                sx={{
                                  fontFamily: "Cormorant Garamond",
                                  fontSize: "20px",
                                }}
                              >
                                {product?.product_json?.title}
                              </Typography>
                              {resultsettingdata?.hide_price == false && (
                                <Typography
                                  className="mt-2"
                                  variant="h6"
                                  color="textSecondary"
                                >
                                  {/* {product?.price}$ */}
                                  {variantData[index]?.index === index
                                    ? variantData[index].price
                                      ? variantData[index].price + "$"
                                      : product?.price + "$"
                                    : product?.price + "$"}
                                </Typography>
                              )}
                              {resultsettingdata?.hide_variants === false && (
                                <div
                                  className="mt-3"
                                  style={{ textAlign: "justify" }}
                                >
                                  <span style={{ fontSize: "17px" }}>
                                    Title
                                  </span>
                                  <select
                                    style={{
                                      width: "150px",
                                      boxShadow: "none",
                                      border: "1px solid #0000007d",
                                    }}
                                    className="form-select mt-1 form-select-lg mb-3"
                                    aria-label=".form-select-lg example"
                                    onChange={(e) => {
                                      const selectedVariantId = e.target.value;
                                      const selectedVariant =
                                        product.product_json.variants.find(
                                          (variant) =>
                                            variant.id === selectedVariantId
                                        );
                                      setVariantData((prevVariantData) => {
                                        const updatedVariants = [
                                          ...prevVariantData,
                                        ];
                                        updatedVariants[index] = {
                                          ...updatedVariants[index],
                                          index: index,
                                          price: selectedVariant.price,
                                          image: selectedVariant.image?.url,
                                        };
                                        return updatedVariants;
                                      });
                                      handleSubImage(
                                        selectedVariant.image,
                                        index
                                      );
                                    }}
                                  >
                                    {product.product_json.variants.map(
                                      (variant) => {
                                        return (
                                          <option value={variant.id}>
                                            {variant.title}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                </div>
                              )}
                            </>
                          )}
                          {resultsettingdata?.show_product_title === true && (
                            <Typography
                              variant="h5"
                              className="ps-4 pe-4 pb-3 pt-2"
                              sx={{
                                fontFamily: "Cormorant Garamond",
                                fontSize: "20px",
                              }}
                            >
                              {product?.product_json?.title}
                            </Typography>
                          )}
                          {resultsettingdata?.hide_add_to_bag === false &&
                            (resultsettingdata?.add_bag_button_width ===
                            false ? (
                              <CButton
                                color="secondary"
                                shape="rounded-0"
                                variant="outline"
                                size=""
                                className="mt-2 text-color"
                                style={{
                                  fontFamily: "Trade Gothic LH",
                                  textTransform: "uppercase",
                                }}
                              >
                                Add to bag
                              </CButton>
                            ) : (
                              <CButton
                                color="secondary"
                                shape="rounded-0"
                                variant="outline"
                                size="lg"
                                className="mt-2 text-color"
                                sx={{ width: "150px" }}
                                style={{
                                  fontFamily: "Trade Gothic LH",
                                  textTransform: "uppercase",
                                }}
                              >
                                Add to bag
                              </CButton>
                            ))}
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>

              <div className={"d-flex  center_small_image_box"}>
                {resultsettingdata?.button_text ? (
                  <>
                    <Button
                      className="mt-4"
                      sx={{
                        color: "black",
                        borderBottom: "1px solid black",
                        borderRadius: "0px",
                        // textTransform: "none",
                        "&:hover": {
                          backgroundColor: "transparent",
                          color: "darkgray",
                        },
                        textTransform: "uppercase",
                        fontFamily: "Trade Gothic LH",
                      }}
                    >
                      {resultsettingdata?.button_text}
                    </Button>
                  </>
                ) : null}
                <Button
                  className="mt-4 ms-3"
                  sx={{
                    color: "black",
                    borderBottom: "1px solid black",
                    borderRadius: "0px",
                    // textTransform: "none",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "darkgray",
                    },
                    textTransform: "uppercase",
                    fontFamily: "Trade Gothic LH",
                  }}
                >
                  RETAKE QUIZ
                </Button>
              </div>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default RightResult;
