import { Box, Chip, Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Buttton from "../../../Button/Buttton";
import Pagestepper from "../../../intropage/Pagestepper";
import Sidenav from "../../../Sidenav";
// import Questionsecondcard from "../Questionsecondcard";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { FetchContext } from "../../../../context/FetchContext";
import { AuthContext } from "../../../../context/AuthContext";
import EditLeftImageCard from "./EditLeftImageCard";
import EditRightImageCard from "./EditRightImageCard";
import { toast } from "react-hot-toast";
import CreateQuestionTitle from "../../../Title/Title";
import { useState } from "react";
import Questionsecondcard from "../../../question/AddQuestion/Questionsecondcard";

const EditImageTypeQuestion = () => {
  const { id } = useParams();
  const { authAxios } = useContext(FetchContext);
  const { state } = useContext(AuthContext);
  const navigate = useNavigate();
  const [question_add_an_iimage, setquestion_add_an_iimage] = React.useState(
    []
  );
  const [answer_add_image, setanswer_add_image] = React.useState([]);
  const [answer_show_image, setanswer_show_image] = React.useState([]);
  const [answer_change_icon, setanswer_change_icon] = React.useState([]);
  const [question_show_iimage, setquestion_show_iimage] = React.useState([]);
  const [imgquedata, setimgquedata] = React.useState({});
  const [imgansdata, setimgansdata] = React.useState([]);
  const [validation, setvalidation] = React.useState(false);
  const [deleteAnswer, setDeleteAnswer] = React.useState([]);
  const [isSaveDisable, setIsSaveDisable] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [resultLogic, setResultLogic] = useState();
  const [allquestion, setallquestion] = React.useState([]);
  const [allQuelist, setAllQueList] = useState([]);
  console.log("allQuelist", allQuelist);
  const quiz = state?.allquiz?.filter(
    (item) => item?._id === imgquedata?.quiz_id
  )[0];
  console.log("quiz------>", quiz);

  const preview = () => {
    if (quiz.disable_intro === true) {
      navigate(`/preview/enable_intropage/${imgquedata?.quiz_id}`);
    } else {
      navigate(`/preview/${imgquedata?.quiz_id}`);
    }
  };

  useEffect(() => {
    authAxios
      .post(
        `${process.env.REACT_APP_API_URL}/question/get_question_answerdata/${id}`
      )
      .then((res) => {
        setResultLogic(res.data.quiz.result_json.result_logic);
        setimgquedata(res.data.questiondata);
        setimgansdata(res.data.editanswerdata);
        setallquestion(res.data);
        const allQuestion_list = res.data.result;
        const result = allQuestion_list.map((item, index) => ({
          label: `Question ${index + 1}. ${item.heading}`,
          id: item._id,
        }));
        setAllQueList(result);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  }, []);

  const questionsave = () => {
    console.log("imgansdata-------->", imgansdata);
    if (imgansdata.length > 0) {
      if (deleteAnswer.length > 0) {
        try {
          authAxios.delete(
            `${process.env.REACT_APP_API_URL}/answer/delete_answer/${deleteAnswer}`
          );
          setDeleteAnswer([]);
        } catch (error) {
          console.error("Error deleting answers:", error);
        }
      }
      let updatedImgquedata = { ...imgquedata };
      if (imgquedata.answer_limit > imgansdata.length) {
        updatedImgquedata.answer_limit = imgansdata.length;
      }
      setimgquedata(updatedImgquedata);
      const formData = new FormData();
      formData.append("imgquedata", JSON.stringify(updatedImgquedata));
      formData.append("imgansdata", JSON.stringify(imgansdata));
      formData.append("question_add_an_iimage", question_add_an_iimage);
      for (let i = 0; i < answer_add_image.length; i++) {
        formData.append(`answer_add_image`, answer_add_image[i]);
      }
      formData.append("answer_change_icon", answer_change_icon);
      if (imgquedata.heading === "") {
        toast.error("Question heading can't be blank ");
        setvalidation(true);
      } else if (
        imgansdata.filter(
          (item, index) => item.valid || item.answer.valid == true
        ).length !== 0
      ) {
        toast.error("Title can't be blank ");
      } else if (
        imgansdata?.filter(
          (item, index) => item.url_valid || item.answer.url_valid == true
        ).length !== 0
      ) {
        toast.error("Url can't be blank ");
      } else {
        setLoading(true);
        authAxios
          .post(
            `${process.env.REACT_APP_API_URL}/question/edit_question_answer/${id}`,
            formData
          )
          .then((res) => {
            if (res.data.msg === "success") {
              setLoading(false);
              setimgquedata(res.data);
              navigate(`/quizzes/intropage/${quiz?._id}`);
            }
          })
          .catch((err) => {
            if (err) {
              setLoading(false);
              toast.error(err);
              console.log(err);
            }
          });
      }
    } else {
      toast.error("Please add at least one answer or field");
    }
  };

  return (
    <div>
      <Sidenav>
        <CreateQuestionTitle
          link={`/quizzes/intropage/${quiz?._id}`}
          quiz_name={quiz?.quiz_name}
          is_publish={quiz?.is_publish}
          preview={preview}
          questionsave={questionsave}
          isSaveDisable={isSaveDisable}
          loading={loading}
        />

        <div className="mt-4 mx-4">
          <Pagestepper quiz={quiz} />
        </div>
        <div className="mt-4 mx-4">
          <Questionsecondcard
            allquestion={allquestion}
            imgquedata={imgquedata}
          />
        </div>
        <Box className="d-flex mx-4">
          <Grid container spacing={3}>
            <EditLeftImageCard
              imgquedata={imgquedata}
              setimgquedata={setimgquedata}
              imgansdata={imgansdata}
              setimgansdata={setimgansdata}
              question_add_an_iimage={question_add_an_iimage}
              setquestion_add_an_iimage={setquestion_add_an_iimage}
              question_show_iimage={question_show_iimage}
              setquestion_show_iimage={setquestion_show_iimage}
              setanswer_show_image={setanswer_show_image}
              answer_show_image={answer_show_image}
              setanswer_add_image={setanswer_add_image}
              setvalidation={setvalidation}
              validation={validation}
              deleteAnswer={deleteAnswer}
              setDeleteAnswer={setDeleteAnswer}
              setIsSaveDisable={setIsSaveDisable}
              resultLogic={resultLogic}
              allquestion={allquestion}
              allQuelist={allQuelist}
            />
            <EditRightImageCard
              imgquedata={imgquedata}
              setimgquedata={setimgquedata}
              imgansdata={imgansdata}
              answer_show_image={answer_show_image}
              answer_add_image={answer_add_image}
              setimgansdata={setimgansdata}
              question_add_an_iimage={question_add_an_iimage}
              setquestion_add_an_iimage={setquestion_add_an_iimage}
              question_show_iimage={question_show_iimage}
              allquestion={allquestion}
            />
          </Grid>
        </Box>
      </Sidenav>
    </div>
  );
};

export default EditImageTypeQuestion;
