import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Grid } from "@material-ui/core";

import { useNavigate, useParams } from "react-router-dom";

export default function Questionsecondcard({ allquestion, imgquedata }) {
  console.log("allquestion", allquestion.result);
  console.log("quiz_id", imgquedata?.quiz_id);
  const { id } = useParams();
  const question_index = allquestion?.result?.findIndex(
    (item) => item._id === id
  );
  const quiz_id = imgquedata?.quiz_id ? imgquedata?.quiz_id : id;
  const navigate = useNavigate();
  const nextquestion = () => {
    navigate(`/questiontype/${quiz_id}`);
  };
  const previewquestion = () => {
    navigate(`/quizzes/intropage/${quiz_id}`);
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Grid className="d-flex">
          <Typography className="mt-3 col-3" sx={{ fontSize: 14 }} gutterBottom>
            <Button
              className="all_question_btn mx-3"
              size="lg"
              onClick={(e) => previewquestion()}
            >
              {" "}
              <ArrowCircleLeftIcon className="mx-1" /> All questions
            </Button>
          </Typography>

          <Typography
            variant="h6"
            className="mt-3 col-6 text-center"
            component="div"
          >
            Question{" "}
            {question_index === -1
              ? allquestion?.result?.length + 1
              : question_index + 1}
          </Typography>
          <Typography
            className="mt-3 col-3 add_next_question"
            sx={{ fontSize: 14 }}
            gutterBottom
          >
            <Button
              className=" mx-3 add_next_question_btn"
              size="lg"
              onClick={(e) => nextquestion()}
            >
              {" "}
              <AddCircleOutlinedIcon className="mx-1" /> Add next questions
            </Button>
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
}
