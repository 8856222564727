import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

const RadioQuestion = ({
  data,
  questionNumber,
  redirectPrevURL,
  redirectURL,
  topText,
  isBack,
  result_json_data,
  setQuiz_logic_jump_data,
  setQuestionIndex,
  questionIndex,
  quiz,
  result,
}) => {
  console.log("data=====>", data);
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [multiValue, setMultiValue] = useState([]);
  const [productWeight, setProductWeight] = useState([]);
  console.log("productWeight", productWeight);
  const [productPoint, setProductPoint] = useState(0);
  const [answerIds, setAnswerIds] = useState([]);
  const [resultWeightIds, setResultWeightIds] = useState([]);
  console.log("resultWeightIds----->", resultWeightIds);

  useEffect(() => {
    if (data.multiselect_answer === true) {
      setQuiz_logic_jump_data((prevData) => {
        const newData = [...prevData];
        newData[questionNumber] = data.leads_to_id;
        return newData;
      });
    }
  }, [data]);

  function localSorageData() {
    var valueArr = JSON.parse(localStorage.getItem("quizData"));
    if (valueArr && valueArr[questionNumber]?.answerId) {
      if (data.multiselect_answer) {
        setMultiValue(valueArr[questionNumber].answerId);
      } else {
        setValue(valueArr[questionNumber].answerId);
      }
    }
    if (valueArr && valueArr[questionNumber]?.answerIds) {
      setAnswerIds(valueArr[questionNumber]?.answerIds);
    }
    if (valueArr && valueArr[questionNumber]?.product_weight) {
      setProductWeight(valueArr[questionNumber].product_weight);
    }
    if (valueArr && valueArr[questionNumber]?.product_point) {
      setProductPoint(valueArr[questionNumber].product_point);
    }
    if (valueArr && valueArr[questionNumber]?.resultWeightIds) {
      setResultWeightIds(valueArr[questionNumber]?.resultWeightIds);
    }
  }

  function handleAnsValue(event, e) {
    const newAnswerId = [...answerIds];

    if (data.multiselect_answer === false) {
      setQuiz_logic_jump_data((prevData) => {
        const newData = [...prevData];
        newData[questionNumber] = e.leads_to_id;
        return newData;
      });
    }
    const newMultiValuearr = [...multiValue];
    let newAnsWeightarr = [...productWeight];
    if (
      event.target.checked &&
      e.custom_redirect_url &&
      multiValue.length < data.answer_limit
    ) {
      window.open(e.url, "_blank");
    }
    if (e.product_point) {
      if (event.target.checked) {
        const lastValue = productPoint || 0;
        const newSum = lastValue + +e.product_point;
        setProductPoint(newSum);
      } else {
        const lastValue = productPoint || 0;
        if (lastValue >= 0) {
          const newSum = lastValue + -e.product_point;
          setProductPoint(newSum);
        }
      }
    }

    let targetData = e.select_products;
    let allVariantsTemp = [];
    let maxVariantWeightsTemp = null;
    let maxWeight = -Infinity;
    let findRemove_index = -1;
    targetData.forEach((product) => {
      product.variants.forEach((variant) => {
        // let variantCopy = { ...variant };
        // variantCopy.select_option = e.title;
        let variantCopy = {
          ...variant,
          select_option: e.title,
          answer_id: e._id,
        };
        if (
          !newAnsWeightarr.some((v) => v.select_option === e.title) &&
          multiValue.length < data.answer_limit
        ) {
          allVariantsTemp.push(variantCopy);
        } else {
          findRemove_index = newAnsWeightarr.findIndex(
            (v) => v.select_option === e.title
          );
        }
      });
    });

    if (findRemove_index !== -1) {
      newAnsWeightarr = productWeight.splice(findRemove_index, 1);
    }
    if (event.target.checked) {
      setProductWeight([...productWeight, ...allVariantsTemp]);
    } else {
      let removeVariantArray = [];
      e.select_products.forEach((product) => {
        product.variants.forEach((variant) => {
          removeVariantArray.push({ ...variant, answer_id: e._id });
          // removeVariantArray.push(variant);
        });
      });
      const filteredProductWeight = productWeight.filter((pw) => {
        const shouldRemove = removeVariantArray.some(
          (rv) => rv.answer_id === pw.answer_id
        );
        return !shouldRemove;
      });
      setProductWeight(filteredProductWeight);
    }
    const valu_index = newMultiValuearr.indexOf(e.title);
    const product_index = newAnsWeightarr.indexOf(e?.product_weight);

    const newResultWeightIds = [...resultWeightIds];
    const weightIdIndex = newResultWeightIds.indexOf(e.select_result_id);

    if (data.multiselect_answer) {
      if (event.target.checked && newAnswerId.length < data.answer_limit) {
        if (!newAnswerId.includes(e._id)) {
          newAnswerId.push(e._id);
        }
        newResultWeightIds.push(e.select_result_id);
      } else {
        const index = newAnswerId.indexOf(e._id);
        if (index > -1) {
          newAnswerId.splice(index, 1);
          newResultWeightIds.splice(weightIdIndex, 1);
        }
      }
      if (valu_index > -1) {
        newMultiValuearr.splice(valu_index, 1);
        newAnsWeightarr.splice(product_index, 1);
      } else {
        if (
          newMultiValuearr.length < data.answer_limit &&
          !e.custom_redirect_url
        ) {
          newMultiValuearr.push(e.title);
          newAnsWeightarr.push(e?.product_weight);
        } else {
          console.log(`Maximum of ${data.answer_limit} selections reached.`);
        }
      }
      setMultiValue(newMultiValuearr);
      setAnswerIds(newAnswerId);
      setResultWeightIds(newResultWeightIds);
    } else {
      setAnswerIds([e._id]);
      setResultWeightIds([e.select_result_id]);
      if (e.custom_redirect_url) {
        setValue("");
      } else {
        setValue(e.title);
      }
    }
  }

  const handleRedirect = (dir) => {
    if (localStorage.getItem("quizData")) {
      var valueArr = JSON.parse(localStorage.getItem("quizData"));
    } else {
      var valueArr = {};
    }
    valueArr[`${questionNumber}`] = {
      questionId: data._id,
      answerId: data.multiselect_answer ? multiValue : value,
      answerIds: answerIds,
      resultWeightIds: resultWeightIds,
      result_logic: result_json_data.result_logic,
      product_weight:
        result_json_data.result_logic === "Product Weight"
          ? productWeight.length
            ? productWeight
            : null
          : null,
      product_point:
        result_json_data.result_logic === "Points"
          ? productPoint >= 0
            ? productPoint
            : null
          : null,
      product_single:
        result_json_data.result_logic === "Single"
          ? productWeight.length
            ? productWeight
            : null
          : null,
      quizId: data.quiz_id,
    };
    localStorage.setItem("quizData", JSON.stringify(valueArr));
    setValue("");
    setMultiValue("");

    if (dir) {
      if (data.multiselect_answer === true) {
        let backActionArr = localStorage.getItem("back_action")
          ? JSON.parse(localStorage.getItem("back_action"))
          : [];

        if (data.leads_to_id) {
          backActionArr.push(data.leads_to_id);
        } else {
          if (result.length - 1 > questionNumber) {
            backActionArr.push(result[questionNumber + 1]?._id);
          }
        }

        localStorage.setItem("back_action", JSON.stringify(backActionArr));
      } else {
        let backActionArr = localStorage.getItem("back_action")
          ? JSON.parse(localStorage.getItem("back_action"))
          : [];
        const answerObj = data.answers.find((answer) => answer.title === value);
        if (answerObj.leads_to_id) {
          backActionArr.push(answerObj.leads_to_id);
        } else {
          if (result.length - 1 > questionNumber) {
            backActionArr.push(result[questionNumber + 1]?._id);
          }
        }

        localStorage.setItem("back_action", JSON.stringify(backActionArr));
      }
      navigate(redirectURL);
      setQuestionIndex([...questionIndex, data._id]);
    } else {
      if (quiz.logic_jump === true) {
        const back_action_arr = JSON.parse(localStorage.getItem("back_action"));
        const question_id = back_action_arr.pop();
        const secondLastId =
          back_action_arr.length !== 0
            ? back_action_arr[back_action_arr.length - 1]
            : result[0]?._id;
        localStorage.setItem("back_action", JSON.stringify(back_action_arr));
        const prevQuestionIndex = result.findIndex(
          (question) => question._id === secondLastId
        );
        const prevQuestionObject = result.find(
          (question) => question._id === secondLastId
        );

        navigate(`/question/${prevQuestionObject?._id}/${prevQuestionIndex}`);
      } else {
        navigate(redirectPrevURL);
      }
    }
  };

  function handleSkipRedirect(dir) {
    if (localStorage.getItem("quizData")) {
      var valueArr = JSON.parse(localStorage.getItem("quizData"));
    } else {
      var valueArr = {};
    }
    valueArr[`${questionNumber}`] = {
      questionId: data._id,
      answerId: null,
      answerIds: null,
      resultWeightIds: null,
      result_logic: result_json_data.result_logic,
      product_weight: null,
      product_point: null,
      quizId: data.quiz_id,
    };
    localStorage.setItem("quizData", JSON.stringify(valueArr));
    setValue("");
    setMultiValue("");
    if (dir) {
      let backActionArr = localStorage.getItem("back_action")
        ? JSON.parse(localStorage.getItem("back_action"))
        : [];

      if (data.leads_to_id) {
        backActionArr.push(data.leads_to_id);
      } else {
        if (result.length - 1 > questionNumber) {
          backActionArr.push(result[questionNumber + 1]?._id);
        }
      }
      localStorage.setItem("back_action", JSON.stringify(backActionArr));
      const skipQuestionIndex = result.findIndex(
        (question) => question._id === data.leads_to_id
      );
      const skipQuestionObject = result.find(
        (question) => question._id === data.leads_to_id
      );
      if (quiz.logic_jump === true) {
        if (data.leads_to_id === "result_page") {
          navigate("/subscribe");
        } else if (!data.leads_to_id) {
          if (result.length - 1 > questionNumber) {
            navigate(
              `/question/${result[questionNumber + 1]?._id}/${
                questionNumber + 1
              }`
            );
          } else {
            navigate("/subscribe");
          }
        } else {
          navigate(`/question/${skipQuestionObject?._id}/${skipQuestionIndex}`);
        }
      } else {
        navigate(redirectURL);
      }
    } else {
      navigate(redirectPrevURL);
    }
  }

  // *******************************preview app design **************************
  const CenterStructure = () => {
    return (
      <Grid
        className="radioContainer center_part_radioContainer"
        sx={{
          marginTop: "0px !important",
        }}
      >
        <Grid sx={{ width: "100%" }}>
          <Grid sx={{ marginInline: "5px" }}>
            <Typography mt={2} variant="body1" color="inherit">
              {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
            </Typography>
            <Typography mt={1} variant="h4" color="inherit">
              {data.heading}
            </Typography>
            <Typography mt={1} variant="h6" color="inherit">
              {data.subheading}
            </Typography>
            {data?.question_add_rimage ? (
              <div className="d-flex justify-center mt-3">
                <img
                  src={`${process.env.REACT_APP_API_URL}/question/${data?.question_add_rimage}`}
                  height={790 - parseInt(data.image_width)}
                  width={790 - parseInt(data.image_width)}
                  alt=""
                  srcset=""
                />
              </div>
            ) : (
              ""
            )}
          </Grid>
          <div
            style={{
              paddingBlock: "20px",
            }}
          >
            {
              {
                Default: <DefaultLayout />,
                Wide: <WideLayout />,
                Narrow: <NarrowLayout />,
              }[data.question_layout_style]
            }
          </div>
          <TooltipComponent />
          <ManageQuestions />
        </Grid>
      </Grid>
    );
  };
  const WideLayout = () => {
    return (
      <>
        <div>
          <RadioGroup
            sx={{ width: "100%" }}
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={value}
          >
            <Grid container justifyContent="center" sx={{ marginTop: "10px" }}>
              {data?.answers?.map((item) => {
                return (
                  <Grid
                    item
                    // xs={data.layout_display == "Vertical" ? 12 : 6}
                    lg={data.layout_display == "Vertical" ? 12 : 6}
                    md={
                      data.image_position === "left" ||
                      data.image_position === "right"
                        ? 12
                        : data.layout_display == "Vertical"
                        ? 12
                        : 6
                    }
                    sm={data.layout_display == "Vertical" ? 12 : 6}
                    xs={12}
                    sx={{
                      padding: "0px 10px",
                      // height: "80px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        className={`custom-option  ${
                          value === item.title ||
                          multiValue.includes(item.title)
                            ? "active"
                            : ""
                        }${
                          data.layout_display !== "Vertical"
                            ? "max_content_radioquestion_width"
                            : data.image_position === "background"
                            ? "bg_contain_width"
                            : data.image_position === "left" ||
                              data.image_position === "right"
                            ? "left_right_width_content"
                            : "max_radioquestion_width"
                        }`}
                        style={{
                          aspectRatio: "auto",
                          margin: "0",
                          padding: "0",
                          marginBlock: "10px",
                          width:
                            data.layout_display == "Vertical" ? "50%" : "100%",
                        }}
                      >
                        <FormControlLabel
                          value={item.title}
                          sx={{
                            height: "60px !important",
                            width: "100%",
                            display: "flex",
                            margin: "0px !important",
                            alignItems: "center",
                          }}
                          control={
                            data.multiselect_answer ? (
                              <Checkbox
                                className="custom-radio"
                                checked={multiValue.includes(item.title)}
                                onChange={(e) => handleAnsValue(e, item)}
                                checkedIcon={<RadioButtonCheckedIcon />}
                                icon={<RadioButtonUncheckedIcon />}
                              />
                            ) : (
                              <Radio
                                className="custom-radio"
                                onChange={(e) => handleAnsValue(e, item)}
                              />
                            )
                          }
                          label={
                            <Typography
                              style={{ wordBreak: "break-all" }}
                              variant="body2"
                              color="inherit"
                              className="answer_title"
                            >
                              {item.title}
                            </Typography>
                          }
                        />
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </RadioGroup>
        </div>
      </>
    );
  };
  const NarrowLayout = () => {
    return (
      <>
        <Container>
          <FormControl
          // onChange={(value) => handleAnsValue(value)}
          >
            <RadioGroup
              sx={{ width: "100%", display: "flex" }}
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={value}
            >
              <Grid
                // className={
                //   data.layout_display == "Vertical"
                //     ? "grid_narrow_v"
                //     : "grid_narrow_h"
                // }
                spacing={2}
                container
                mb={2}
                sx={{ display: "flex" }}
                justifyContent="center"
              >
                {data?.answers?.map((item) => {
                  return (
                    <Grid
                      item
                      lg={data.layout_display == "Vertical" ? 6 : 3}
                      md={data.layout_display == "Vertical" ? 6 : 4}
                      sm={3}
                      xs={6}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="grid-narrow-horizotal">
                        <div
                          className={`custom-option ${
                            value === item.title ||
                            multiValue.includes(item.title)
                              ? "active"
                              : ""
                          }`}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "20px",
                            maxWidth: "8rem",
                            width: "8rem",
                            padding: "5px",
                          }}
                        >
                          <FormControlLabel
                            // sx={{ margin: "0px" }}
                            sx={{
                              minHeight: "50px !important",
                              width: "100%",
                              display: "flex",
                              margin: "0px !important",
                              justifyContent: "center",
                              alignItems: "center",
                              paddingBottom: "15px",
                            }}
                            value={item.title}
                            control={
                              data.multiselect_answer ? (
                                <Checkbox
                                  className="custom-radio"
                                  checked={multiValue.includes(item.title)}
                                  onChange={(e) => handleAnsValue(e, item)}
                                  checkedIcon={<RadioButtonCheckedIcon />}
                                  icon={<RadioButtonUncheckedIcon />}
                                />
                              ) : (
                                <Radio
                                  className="custom-radio"
                                  onChange={(e) => handleAnsValue(e, item)}
                                />
                              )
                            }
                            label={
                              <div className="narraw-title">{item.title} </div>
                            }
                            labelPlacement="top"
                          />
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </RadioGroup>
          </FormControl>
        </Container>
      </>
    );
  };
  // const NarrowLayout = () => {
  //   return (
  //     <>
  //       <Container>
  //         <FormControl>
  //           <RadioGroup
  //             sx={{ width: "100%", display: "flex" }}
  //             aria-labelledby="demo-radio-buttons-group-label"
  //             name="radio-buttons-group"
  //             value={value}
  //           >
  //             <Grid
  //               // className={
  //               //   data.layout_display == "Vertical"
  //               //     ? "grid_narrow_v"
  //               //     : "grid_narrow_h"
  //               // }
  //               spacing={2}
  //               container
  //               className={
  //                 data.layout_display == "Vertical"
  //                   ? "grid_narrow_v"
  //                   : data.image_position === "left" ||
  //                     data.image_position === "right"
  //                   ? "left_right_narrow_h"
  //                   : "grid_narrow_h"
  //               }
  //               mb={2}
  //             >
  //               {data?.answers?.map((item) => {
  //                 return (
  //                   <div
  //                     className="grid-narrow-horizotal"
  //                     style={{
  //                       display: "flex",
  //                       justifyContent: "center",
  //                       flex: "50%",
  //                     }}
  //                   >
  //                     <div
  //                       className={`custom-option ${
  //                         value === item.title ||
  //                         multiValue.includes(item.title)
  //                           ? "active"
  //                           : ""
  //                       }`}
  //                       style={{
  //                         display: "flex",
  //                         // flexDirection: "column",
  //                         // alignItems: "center",
  //                         justifyContent: "center",
  //                         // gap: "10px",
  //                         // padding: "5px",
  //                         aspectRatio: "0",
  //                       }}
  //                     >
  //                       <FormControlLabel
  //                         sx={{
  //                           height: "auto !important",
  //                           width: "170px",
  //                           display: "flex",
  //                           margin: "0px !important",
  //                           justifyContent: "center",
  //                           alignItems: "center",
  //                           padding: "0px 5px",
  //                           // paddingBottom: "15px",
  //                         }}
  //                         value={item.title}
  //                         labelPlacement="top"
  //                         control={
  //                           data.multiselect_answer ? (
  //                             <Checkbox
  //                               className="custom-radio"
  //                               checked={multiValue.includes(item.title)}
  //                               onChange={(e) => handleAnsValue(e, item)}
  //                               checkedIcon={<RadioButtonCheckedIcon />}
  //                               icon={<RadioButtonUncheckedIcon />}
  //                             />
  //                           ) : (
  //                             <Radio
  //                               className="custom-radio"
  //                               onChange={(e) => handleAnsValue(e, item)}
  //                             />
  //                           )
  //                         }
  //                         label={
  //                           <div className="narrow_title_radio_preview">
  //                             <p>{item.title}</p>
  //                           </div>
  //                         }
  //                       />
  //                     </div>
  //                   </div>
  //                 );
  //               })}
  //             </Grid>
  //           </RadioGroup>
  //         </FormControl>
  //       </Container>
  //     </>
  //   );
  // };
  const DefaultLayout = () => {
    return (
      <>
        <Container>
          <RadioGroup
            sx={{ width: "100%", display: "flex" }}
            aria-labelledby="demo-radio-buttons-group-label"
            value={value}
            name="radio-buttons-group"
          >
            <Grid container style={{ display: "flex", margin: "0 auto" }}>
              {data.answers.map((item) => {
                return (
                  <Grid
                    item
                    xs={data.layout_display === "Vertical" ? 12 : 6}
                    lg={data.layout_display === "Vertical" ? 12 : 6}
                    md={data.layout_display === "Vertical" ? 12 : 6}
                    sm={data.layout_display === "Vertical" ? 12 : 6}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "0 auto",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: "15px",
                        maxWidth: "100%",
                        textAlign: "left",
                      }}
                    >
                      <FormControlLabel
                        value={item.title}
                        sx={{ margin: "0px" }}
                        control={
                          data.multiselect_answer ? (
                            <Checkbox
                              className="custom-radio"
                              sx={{ padding: "0px" }}
                              checked={multiValue.includes(item.title)}
                              onChange={(e) => handleAnsValue(e, item)}
                              checkedIcon={<RadioButtonCheckedIcon />}
                              icon={<RadioButtonUncheckedIcon />}
                            />
                          ) : (
                            <Radio
                              className="custom-radio"
                              sx={{ padding: "0px" }}
                              onChange={(e) => handleAnsValue(e, item)}
                            />
                          )
                        }
                        label={
                          <Typography
                            sx={{ wordBreak: "break-all", marginLeft: "8px" }}
                            variant="body2"
                            color="inherit"
                            className="answer_title"
                          >
                            {item.title}
                          </Typography>
                        }
                      />
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </RadioGroup>
        </Container>
      </>
    );
  };
  const LeftStructure = () => {
    return (
      <Grid container>
        {data.question_add_rimage ? (
          <Grid item xs={12} md={6} sm={12}>
            <div className="left-structure-img">
              <img
                src={`${process.env.REACT_APP_API_URL}/question/${data.question_add_rimage}`}
                alt=""
                srcset=""
              />
            </div>
          </Grid>
        ) : (
          ""
        )}

        <Grid item xs={12} md={6} sm={12} sx={{ width: "100%" }}>
          <Grid
            className="radioContainer"
            sx={{
              height: "100%",
              marginTop: "0px !important",
            }}
          >
            <Grid sx={{ width: "100%" }}>
              <Grid sx={{ marginInline: "5px" }}>
                <Typography variant="body1" color="inherit">
                  {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
                </Typography>
                <Typography mt={3} variant="h4" color="inherit">
                  {data.heading}
                </Typography>
                <Typography mt={3} variant="h6" color="inherit">
                  {data.subheading}
                </Typography>
              </Grid>
              <div
              // style={{ paddingBlock: "20px" }}
              >
                {
                  {
                    Default: <DefaultLayout />,
                    Wide: <WideLayout />,
                    Narrow: <NarrowLayout />,
                  }[data.question_layout_style]
                }
              </div>
              <TooltipComponent />
              <ManageQuestions />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const RightStructure = () => {
    return (
      <Grid container>
        <Grid item xs={12} md={6} sm={12} sx={{ width: "100%" }}>
          <Grid
            className="radioContainer"
            style={{
              marginTop: "0px !important",
              height: "100%",
            }}
          >
            <Grid sx={{ width: "100%" }}>
              <Grid>
                <Typography variant="body1" color="inherit">
                  {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
                </Typography>
                <Typography mt={3} variant="h4" color="inherit">
                  {data.heading}
                </Typography>
                <Typography mt={3} variant="h6" color="inherit">
                  {data.subheading}
                </Typography>
              </Grid>
              <div className="right-container" style={{ paddingBlock: "20px" }}>
                {
                  {
                    Default: <DefaultLayout />,
                    Wide: <WideLayout />,
                    Narrow: <NarrowLayout />,
                  }[data.question_layout_style]
                }
              </div>
              <TooltipComponent />
              <ManageQuestions />
            </Grid>
          </Grid>
        </Grid>
        {data?.question_add_rimage ? (
          <Grid item xs={12} md={6} sm={12}>
            <div className="right_structure_img">
              <img
                src={`${process.env.REACT_APP_API_URL}/question/${data?.question_add_rimage}`}
                alt=""
                srcset=""
              />
            </div>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    );
  };
  const TopStructure = () => {
    return (
      <Grid
        className="radioContainer center_part_radioContainer"
        sx={{ marginTop: "0px !important" }}
      >
        <Grid sx={{ width: "100%" }}>
          <Typography mt={2} mb={2} variant="body1" color="inherit">
            {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
          </Typography>
          {data?.question_add_rimage ? (
            <div className="d-flex justify-center">
              <img
                src={`${process.env.REACT_APP_API_URL}/question/${data?.question_add_rimage}`}
                height={790 - parseInt(data.image_width)}
                width={790 - parseInt(data.image_width)}
                alt=""
                srcset=""
              />
            </div>
          ) : (
            ""
          )}
          <Grid sx={{ marginInline: "5px" }}>
            <Typography mt={3} variant="h4" color="inherit">
              {data.heading}
            </Typography>
            <Typography variant="h6" color="inherit">
              {data.subheading}
            </Typography>
          </Grid>
          <div style={{ paddingBlock: "20px" }}>
            {
              {
                Default: <DefaultLayout />,
                Wide: <WideLayout />,
                Narrow: <NarrowLayout />,
              }[data.question_layout_style]
            }
          </div>
          <TooltipComponent />
          <ManageQuestions />
        </Grid>
      </Grid>
    );
  };
  const BackgroundStructure = () => {
    return (
      <>
        {/* <div
          className="back-structure"
          style={{
            zIndex: "0",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            // position: "absolute",
            width: "100%",
            height: "100%",
            opacity: `${data.background_opacity}%`,
            backgroundColor: `${data.background_color}`,
          }}
        ></div>
        <div
          className="background-image"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_API_URL
            }/question/${encodeURIComponent(data?.question_add_rimage)})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: "100%",
            height: "100vh",
            zIndex: "3",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "auto",
          }}
        > */}
        <div
          className="background-image"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_API_URL
            }/question/${encodeURIComponent(data?.question_add_rimage)})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            minHeight: "100vh",
            zIndex: "1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "auto",
          }}
        >
          <div
            className="overlay"
            style={{
              position: "absolute",
              inset: "0",
              width: "100%",
              height: "100%",
              zIndex: "2",
              top: "50px",
            }}
          ></div>

          <div
            className="background-image"
            style={{
              position: "absolute",
              top: "50px",
              left: "0",
              width: "100%",
              height: "100%",
              zIndex: "3",
              opacity: `${data.background_opacity}%`,
              backgroundColor: `${data.background_color}`,
            }}
          ></div>

          <Grid className="radioContainer center_part_radioContainer background-section-content">
            <Grid sx={{ width: "100%", opacity: "1", zIndex: "3" }}>
              <Grid sx={{ marginInline: "5px" }}>
                <Typography variant="body1" color="inherit">
                  {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
                </Typography>
                <Typography mt={3} variant="h4" color="inherit">
                  {data.heading}
                </Typography>
                <Typography mt={3} variant="h6" color="inherit">
                  {data.subheading}
                </Typography>
              </Grid>
              <div style={{ paddingBlock: "20px" }}>
                {
                  {
                    Default: <DefaultLayout />,
                    Wide: <WideLayout />,
                    Narrow: <NarrowLayout />,
                  }[data.question_layout_style]
                }
              </div>
              <TooltipComponent />
              <ManageQuestions />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };
  // *******************************************previw app design over ***************************

  const ManageQuestions = () => {
    return (
      <>
        <Grid container justifyContent="center" mb={2}>
          {isBack ? (
            <Grid item xs>
              <Button
                onClick={() => handleRedirect(false)}
                className="btn-secondary"
                startIcon={<ArrowBackIcon />}
              >
                <b>Back</b>
              </Button>
            </Grid>
          ) : null}
          {data.skip_que && (
            <Grid item xs>
              <Button
                onClick={() => handleSkipRedirect(true)}
                className="btn-secondary"
              >
                <b>Skip</b>
              </Button>
            </Grid>
          )}

          {value.length === 0 && multiValue.length === 0 ? (
            <Grid item xs>
              <Button disabled endIcon={<ArrowForwardIcon />}>
                <b>Next</b>
              </Button>
            </Grid>
          ) : (
            <Grid item xs>
              <Button
                onClick={() => handleRedirect(true)}
                className="btn-secondary"
                endIcon={<ArrowForwardIcon />}
              >
                <b>Next</b>
              </Button>
            </Grid>
          )}
        </Grid>
      </>
    );
  };
  const TooltipComponent = () => {
    return (
      <>
        {data.tooltip_heading !== null && (
          <Container>
            <Tooltip
              arrow
              sx={{ backgroundColor: "transparant" }}
              title={
                <Grid>
                  <h6 style={{ textAlign: "center" }}>
                    {data.tooltip_heading}
                  </h6>
                  <p>{data.tooltip_description}</p>
                </Grid>
              }
            >
              <IconButton>
                <ReportRoundedIcon sx={{ height: 50, width: 50 }} />
              </IconButton>
            </Tooltip>
          </Container>
        )}
      </>
    );
  };
  // const DefaultLayout = () => {
  //   return (
  //     <>
  //       <Container>
  //         <FormControl
  //           sx={{ width: "100%" }}
  //           // onChange={(value) => handleAnsValue(value)}
  //         >
  //           <RadioGroup
  //             sx={{ width: "100%", display: "flex" }}
  //             aria-labelledby="demo-radio-buttons-group-label"
  //             value={value}
  //             name="radio-buttons-group"
  //           >
  //             <Grid
  //               sx={{ width: "100%" }}
  //               container
  //               mb={1}
  //               justifyContent="center"
  //             >
  //               {data.answers.map((item) => {
  //                 return (
  //                   <Grid item xs={data.layout_display == "Vertical" ? 12 : 6}>
  //                     <div
  //                       // className={`custom-option ${
  //                       //   value === item.title ? "active" : ""
  //                       // }`}
  //                       style={{
  //                         display: "flex",
  //                         alignItems: "center",
  //                         justifyContent: "center",
  //                       }}
  //                     >
  //                       <FormControlLabel
  //                         value={item.title}
  //                         control={
  //                           data.multiselect_answer ? (
  //                             <Checkbox
  //                               className="custom-radio"
  //                               checked={multiValue.includes(item.title)}
  //                               onChange={(e) => handleAnsValue(e, item)}
  //                               checkedIcon={<RadioButtonCheckedIcon />}
  //                               icon={<RadioButtonUncheckedIcon />}
  //                             />
  //                           ) : (
  //                             <Radio
  //                               className="custom-radio"
  //                               onChange={(e) => handleAnsValue(e, item)}
  //                             />
  //                           )
  //                         }
  //                         label={
  //                           <Typography
  //                             style={{ wordBreak: "break-all" }}
  //                             variant="body2"
  //                             color="inherit"
  //                             className="answer_title"
  //                           >
  //                             {item.title}
  //                           </Typography>
  //                         }
  //                       />
  //                     </div>
  //                   </Grid>
  //                 );
  //               })}
  //             </Grid>
  //           </RadioGroup>
  //         </FormControl>
  //       </Container>
  //     </>
  //   );
  // };
  // const WideLayout = () => {
  //   return (
  //     <>
  //       <div>
  //         <div style={{ marginLeft: "50px" }}>
  //           <RadioGroup
  //             sx={{ width: "100%" }}
  //             aria-labelledby="demo-radio-buttons-group-label"
  //             name="radio-buttons-group"
  //             value={value}
  //           >
  //             <Grid
  //               container
  //               justifyContent="center"
  //               sx={{ marginTop: "10px" }}
  //             >
  //               {data?.answers?.map((item) => {
  //                 return (
  //                   <Grid
  //                     item
  //                     xs={data.layout_display == "Vertical" ? 12 : 6}
  //                     sx={{
  //                       padding: "0px 10px",
  //                     }}
  //                   >
  //                     <div
  //                       className={`custom-option ${
  //                         value === item.title ||
  //                         multiValue.includes(item.title)
  //                           ? "active"
  //                           : ""
  //                       }${
  //                         data.layout_display !== "Vertical"
  //                           ? " max_content_radioquestion_width"
  //                           : ""
  //                       }`}
  //                     >
  //                       <FormControlLabel
  //                         value={item.title}
  //                         sx={{
  //                           minHeight: "20px !important",
  //                           width: "100%",
  //                           display: "flex",
  //                           padding: " 5px 15px",
  //                           justifyContent: "center",
  //                           alignItems: "center",
  //                         }}
  //                         control={
  //                           data.multiselect_answer ? (
  //                             <Checkbox
  //                               className="custom-radio"
  //                               checked={multiValue.includes(item.title)}
  //                               onChange={(e) => handleAnsValue(e, item)}
  //                               checkedIcon={<RadioButtonCheckedIcon />}
  //                               icon={<RadioButtonUncheckedIcon />}
  //                             />
  //                           ) : (
  //                             <Radio
  //                               className="custom-radio"
  //                               onChange={(e) => handleAnsValue(e, item)}
  //                             />
  //                           )
  //                         }
  //                         label={
  //                           <Typography
  //                             style={{ wordBreak: "break-all" }}
  //                             variant="body2"
  //                             color="inherit"
  //                             className="answer_title"
  //                           >
  //                             {item.title}
  //                           </Typography>
  //                         }
  //                       />
  //                     </div>
  //                   </Grid>
  //                 );
  //               })}
  //             </Grid>
  //           </RadioGroup>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  // const NarrowLayout = () => {
  //   return (
  //     <>
  //       <Container>
  //         <FormControl
  //         // onChange={(value) => handleAnsValue(value)}
  //         >
  //           <RadioGroup
  //             sx={{ width: "100%", display: "flex" }}
  //             aria-labelledby="demo-radio-buttons-group-label"
  //             name="radio-buttons-group"
  //             value={value}
  //           >
  //             <Grid spacing={2} container mb={10}>
  //               {data?.answers?.map((item) => {
  //                 return (
  //                   <Grid item xs={data.layout_display == "Vertical" ? 12 : 6}>
  //                     <div
  //                       className={`custom-option ${
  //                         value === item.title ||
  //                         multiValue.includes(item.title)
  //                           ? "active"
  //                           : ""
  //                       }`}
  //                       style={{
  //                         display: "flex",
  //                         flexDirection: "column",
  //                         alignItems: "center",
  //                         padding: "15px",
  //                       }}
  //                     >
  //                       <Typography
  //                         style={{ wordBreak: "break-all" }}
  //                         variant="body2"
  //                         color="inherit"
  //                         className="answer_title"
  //                       >
  //                         {item.title}
  //                       </Typography>
  //                       <FormControlLabel
  //                         sx={{ margin: "0px" }}
  //                         value={item.title}
  //                         control={
  //                           data.multiselect_answer ? (
  //                             <Checkbox
  //                               className="custom-radio"
  //                               checked={multiValue.includes(item.title)}
  //                               onChange={(e) => handleAnsValue(e, item)}
  //                               checkedIcon={<RadioButtonCheckedIcon />}
  //                               icon={<RadioButtonUncheckedIcon />}
  //                             />
  //                           ) : (
  //                             <Radio
  //                               className="custom-radio"
  //                               onChange={(e) => handleAnsValue(e, item)}
  //                             />
  //                           )
  //                         }
  //                       />
  //                     </div>
  //                   </Grid>
  //                 );
  //               })}
  //             </Grid>
  //           </RadioGroup>
  //         </FormControl>
  //       </Container>
  //     </>
  //   );
  // };

  // const LeftStructure = () => {
  //   return (
  //     <Grid container>
  //       <Grid
  //         item
  //         xs={6}
  //         sx={{
  //           display: "flex",
  //           justifyContent: "center",
  //           marginTop: "20px",
  //           height: "100vh",
  //         }}
  //       >
  //         <img
  //           src={`${process.env.REACT_APP_API_URL}/question/${data.question_add_rimage}`}
  //           alt=""
  //           srcset=""
  //         />
  //       </Grid>
  //       <Grid item xs={6} sx={{ width: "100%" }}>
  //         <Grid className="radioContainer">
  //           <div>
  //             <Grid>
  //               <Typography variant="body1" color="inherit">
  //                 {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
  //               </Typography>
  //               <Typography mt={3} variant="h4" color="inherit">
  //                 {data.heading}
  //               </Typography>
  //               <Typography mt={3} variant="h6" color="inherit">
  //                 {data.subheading}
  //               </Typography>
  //             </Grid>
  //             <div style={{ paddingBlock: "20px" }}>
  //               {
  //                 {
  //                   Default: <DefaultLayout />,
  //                   Wide: <WideLayout />,
  //                   Narrow: <NarrowLayout />,
  //                 }[data.question_layout_style]
  //               }
  //             </div>
  //             <TooltipComponent />
  //             <ManageQuestions />
  //           </div>
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //   );
  // };
  // const RightStructure = () => {
  //   return (
  //     <Grid container>
  //       <Grid item xs={6} sx={{ width: "100%" }}>
  //         <Grid className="radioContainer">
  //           <div>
  //             <Grid>
  //               <Typography variant="body1" color="inherit">
  //                 {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
  //               </Typography>
  //               <Typography mt={3} variant="h4" color="inherit">
  //                 {data.heading}
  //               </Typography>
  //               <Typography mt={3} variant="h6" color="inherit">
  //                 {data.subheading}
  //               </Typography>
  //             </Grid>
  //             <div style={{ paddingBlock: "20px" }}>
  //               {
  //                 {
  //                   Default: <DefaultLayout />,
  //                   Wide: <WideLayout />,
  //                   Narrow: <NarrowLayout />,
  //                 }[data.question_layout_style]
  //               }
  //             </div>
  //             <TooltipComponent />
  //             <ManageQuestions />
  //           </div>
  //         </Grid>
  //       </Grid>
  //       <Grid
  //         item
  //         xs={6}
  //         sx={{
  //           display: "flex",
  //           justifyContent: "center",
  //           marginTop: "20px",
  //           height: "100vh",
  //         }}
  //       >
  //         <img
  //           src={`${process.env.REACT_APP_API_URL}/question/${data?.question_add_rimage}`}
  //           alt=""
  //           srcset=""
  //         />
  //       </Grid>
  //     </Grid>
  //   );
  // };
  // const CenterStructure = () => {
  //   return (
  //     <Grid className="radioContainer center_part_radioContainer">
  //       <Grid sx={{ width: "100%" }}>
  //         <Grid>
  //           <Typography variant="body1" color="inherit">
  //             {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
  //           </Typography>
  //           <Typography mt={3} variant="h4" color="inherit">
  //             {data.heading}
  //           </Typography>
  //           <Typography mt={3} variant="h6" color="inherit">
  //             {data.subheading}
  //           </Typography>
  //           <div className="d-flex justify-center mt-3">
  //             <img
  //               src={`${process.env.REACT_APP_API_URL}/question/${data?.question_add_rimage}`}
  //               height={790 - parseInt(data.image_width)}
  //               width={790 - parseInt(data.image_width)}
  //               alt=""
  //               srcset=""
  //             />
  //           </div>
  //         </Grid>
  //         <div style={{ paddingBlock: "20px" }}>
  //           {
  //             {
  //               Default: <DefaultLayout />,
  //               Wide: <WideLayout />,
  //               Narrow: <NarrowLayout />,
  //             }[data.question_layout_style]
  //           }
  //         </div>
  //         <TooltipComponent />
  //         <ManageQuestions />
  //       </Grid>
  //     </Grid>
  //   );
  // };

  // const BackgroundStructure = () => {
  //   return (
  //     <>
  //       <div
  //         style={{
  //           backgroundImage: `url(${
  //             process.env.REACT_APP_API_URL
  //           }/question/${encodeURIComponent(data?.question_add_rimage)})`,
  //           backgroundRepeat: "no-repeat",
  //           backgroundSize: "cover",
  //         }}
  //       >
  //         <div
  //           style={{
  //             top: 0,
  //             left: 0,
  //             width: "100%",
  //             backgroundColor: `${data?.background_color}`,
  //             opacity: `${data.background_opacity}%`,
  //           }}
  //         >
  //           <Grid className="radioContainer">
  //             <Grid sx={{ width: "100%" }}>
  //               <Grid>
  //                 <Typography variant="body1" color="inherit">
  //                   {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
  //                 </Typography>
  //                 <Typography mt={3} variant="h4" color="inherit">
  //                   {data.heading}
  //                 </Typography>
  //                 <Typography mt={3} variant="h6" color="inherit">
  //                   {data.subheading}
  //                 </Typography>
  //               </Grid>
  //               <div style={{ paddingBlock: "20px" }}>
  //                 {
  //                   {
  //                     Default: <DefaultLayout />,
  //                     Wide: <WideLayout />,
  //                     Narrow: <NarrowLayout />,
  //                   }[data.question_layout_style]
  //                 }
  //               </div>
  //               <TooltipComponent />
  //               <ManageQuestions />
  //             </Grid>
  //           </Grid>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };
  // const TopStructure = () => {
  //   return (
  //     <Grid className="radioContainer_radioque center_part_radioContainer">
  //       <Grid sx={{ width: "100%" }}>
  //         <div className="d-flex justify-center">
  //           <img
  //             src={`${process.env.REACT_APP_API_URL}/question/${data?.question_add_rimage}`}
  //             height={790 - parseInt(data.image_width)}
  //             width={790 - parseInt(data.image_width)}
  //             alt=""
  //             srcset=""
  //           />
  //         </div>
  //         <Grid>
  //           <Typography variant="body1" color="inherit">
  //             {quiz.logic_jump || data.hide_progress_bar ? "" : topText}
  //           </Typography>
  //           <Typography mt={3} variant="h4" color="inherit">
  //             {data.heading}
  //           </Typography>
  //           <Typography variant="h6" color="inherit">
  //             {data.subheading}
  //           </Typography>
  //         </Grid>
  //         <div style={{ paddingBlock: "20px" }}>
  //           {
  //             {
  //               Default: <DefaultLayout />,
  //               Wide: <WideLayout />,
  //               Narrow: <NarrowLayout />,
  //             }[data.question_layout_style]
  //           }
  //         </div>
  //         <TooltipComponent />
  //         <ManageQuestions />
  //       </Grid>
  //     </Grid>
  //   );
  // };
  useEffect(() => {
    localSorageData();
  }, [questionNumber]);
  return (
    <>
      {
        {
          center: <CenterStructure />,
          left: <LeftStructure />,
          right: <RightStructure />,
          background: <BackgroundStructure />,
          top: <TopStructure />,
        }[data.image_position]
      }
    </>
  );
};

export default RadioQuestion;
