import React from "react";
import "./dropdown.css";
const Select = ({ dropdown_text, values, onChange, value, label }) => {
  return (
    <div>
      <span className="dropdown_span">{dropdown_text}</span>
      <select
        class="form-select mt-2 drop_box form-select-lg mb-3"
        aria-label=".form-select-lg example"
        onChange={onChange}
        defaultValue={value}
      >
        {values?.map((item, index) => {
          return (
            <option value={item} selected={item === value ? true : false}>
              {item}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Select;
