import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { CButton, CFormLabel } from "@coreui/react";
import InfoIcon from "@mui/icons-material/Info";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";
import { styled } from "@mui/material/styles";
import defaultImage from "../../../../public/default-fe.png";
import { useParams } from "react-router-dom";
const EditRightImageCard = ({
  imgquedata,
  imgansdata,
  question_add_an_iimage,
  question_show_iimage,
  answer_show_image,
  answer_add_image,
  allquestion,
}) => {
  console.log("imgquedata------------>", imgquedata);
  console.log(
    "imgquedata?.question_add_an_iimage",
    imgquedata?.question_add_an_iimage,
    "question_show_iimage----------->",
    question_show_iimage
  );

  const { id } = useParams();
  let image_url =
    question_show_iimage.length !== 0
      ? question_show_iimage
      : `${process.env.REACT_APP_API_URL}/question/${imgquedata?.question_add_an_iimage}`;
  console.log("image_url", image_url);
  let text_align = imgquedata?.text_alignment;
  let textAlign =
    text_align === "left" ? "start" : text_align === "right" ? "end" : "center";

  const question_index = allquestion?.result?.findIndex(
    (item) => item._id === id
  );


  let backgroundImg;
  if (
    question_add_an_iimage?.length === 0 &&
    imgquedata?.question_add_an_iimage != 0
  ) {
    if (imgquedata.question_add_an_iimage) {
      backgroundImg = `url(${
        process.env.REACT_APP_API_URL
      }/question/${encodeURIComponent(imgquedata.question_add_an_iimage)})`;
    }
  } else if (question_show_iimage) {
    backgroundImg = `url(${question_show_iimage})`;
  } else {
    backgroundImg = "";
  }

  const containerStyle = {
    position: "relative",
    width: "100%",
    // height: '100vh',
    backgroundImage: backgroundImg,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: `${imgquedata?.background_color}`,
    opacity: `${imgquedata.background_opacity}%`,
  };
  return (
    <>
      <Grid item xs={12} sm={12} lg={8} md={12} spacing={3} className="mt-4">
        <Card
          sx={imgquedata.image_position === "background" && containerStyle}
          className="right_quiz_card"
        >
          {imgquedata.image_position === "background" && (
            <div style={overlayStyle} className="backDiv"></div>
          )}
          <CardContent>
            <Grid className="image_card_top">
              {!imgquedata.hide_progress_bar && (
                <Typography
                  variant="subtitle1"
                  className="question_length"
                  component="div"
                >
                  {question_index + 1 + " of " + allquestion?.result?.length}
                </Typography>
              )}
              {(imgquedata.image_position === "top" ||
                imgquedata.image_position === "left") &&
                (question_show_iimage.length !== 0 ||
                  imgquedata?.question_add_an_iimage) && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={image_url}
                      width={`${Math.min(300, imgquedata.image_width / 2)}px`}
                      height={`${Math.min(300, imgquedata.image_width / 2)}px`}
                    />
                  </div>
                )}
              <Typography sx={{ mb: 1 }} className="right_imgque_heading">
                {imgquedata.heading}
              </Typography>
              <Typography sx={{ mb: 1.5 }} className="right_imgque_subheading">
                {imgquedata.subheading}
              </Typography>
              {imgquedata.image_position === "center" &&
                (question_show_iimage.length !== 0 ||
                  imgquedata?.question_add_an_iimage) && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={image_url}
                      width={`${Math.min(300, imgquedata.image_width / 2)}px`}
                      height={`${Math.min(300, imgquedata.image_width / 2)}px`}
                    />
                  </div>
                )}
              <div
                className="option_main_contain"
                style={{
                  margin:
                    imgquedata.box_size == "small"
                      ? "15px 140px"
                      : "15px 270px",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  sx={{ display: "flex" }}
                >
                  {imgansdata.map((item, index) => (
                    <Grid
                      item
                      wrap="nowrap"
                      xs={
                        imgquedata.box_size == "large" ||
                        imgquedata.question_layout_style === "Default"
                          ? 6
                          : 3
                      }
                      // sx={{ display:"flex"}}
                    >
                      {/* ******************************************************************************WIDE LAYOUT START***************************** */}
                      {imgquedata.question_layout_style === "Wide" && (
                        <div>
                          <div className="wide_contained_admin">
                            <div className="admin_wide_box">
                              <div
                                className=""
                                style={{ alignItems: textAlign }}
                              >
                                <div
                                  className="wide_option_img"
                                  style={{
                                    display: "flex",
                                    justifyContent: textAlign,
                                  }}
                                >
                                  <img
                                    className="narrow-img-custom"
                                    src={
                                      answer_show_image[index]
                                        ? answer_show_image[index]
                                        : answer_show_image[index] ===
                                            undefined &&
                                          item.answer?.answer_add_image
                                        ? `${process.env.REACT_APP_API_URL}/answer/${item.answer?.answer_add_image}`
                                        : defaultImage
                                    }
                                    style={{
                                      width:
                                        imgquedata.image_size === "small"
                                          ? "80px"
                                          : "110px",
                                    }}
                                  />
                                </div>

                                <p
                                  className="option_title"
                                  style={{ textAlign: textAlign }}
                                >
                                  {!item.answer?.title
                                    ? "Title"
                                    : item.answer?.title}
                                </p>
                                <p
                                  className="option_description"
                                  style={{ textAlign: textAlign }}
                                >
                                  {item.answer?.description
                                    ? item.answer?.description
                                    : item.description}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* ******************************************************************************WIDE LAYOUT END***************************** */}
                      {/* ******************************************************************************NARROW LAYOUT START***************************** */}
                      {imgquedata.question_layout_style === "Narrow" && (
                        <div className="narrow_contained_admin">
                          <div className="narrow_contain_center">
                            <div
                              className="narrow_admin_round_box"
                              style={{
                                width:
                                  imgquedata.image_size === "small"
                                    ? "54%"
                                    : "65%",
                                height:
                                  imgquedata.image_size === "small"
                                    ? "85px"
                                    : "100px",
                              }}
                            >
                              <div className="">
                                <img
                                  className="narrow_admin_image"
                                  src={
                                    answer_show_image[index]
                                      ? answer_show_image[index]
                                      : answer_show_image[index] ===
                                          undefined &&
                                        item.answer?.answer_add_image
                                      ? `${process.env.REACT_APP_API_URL}/answer/${item.answer?.answer_add_image}`
                                      : defaultImage
                                  }
                                />
                              </div>
                            </div>
                            <div className="admin_narrow_box">
                              <p className="option_title">
                                {!item.answer?.title
                                  ? "Title"
                                  : item.answer?.title}
                              </p>
                              <p
                                className="option_description"
                                style={{ textAlign: textAlign }}
                              >
                                {item.answer?.description
                                  ? item.answer?.description
                                  : item.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* ******************************************************************************NARROW LAYOUT END***************************** */}
                      {/* ******************************************************************************DEFAULT LAYOUT START***************************** */}
                      {imgquedata.question_layout_style === "Default" && (
                        <div className="default_contained_admin">
                          <div className="default_box">
                            <div
                              className="admin_inner_default_box_space"
                              style={{
                                width:
                                  imgquedata.image_size === "small"
                                    ? "28%"
                                    : "38%",
                                height:
                                  imgquedata.image_size === "small"
                                    ? "40px"
                                    : "110px",
                              }}
                            >
                              <div className="">
                                <img
                                  className=""
                                  src={
                                    answer_show_image[index]
                                      ? answer_show_image[index]
                                      : answer_show_image[index] ===
                                          undefined &&
                                        item.answer?.answer_add_image
                                      ? `${process.env.REACT_APP_API_URL}/answer/${item.answer?.answer_add_image}`
                                      : defaultImage
                                  }
                                />
                              </div>
                            </div>
                            <div className="admin_default_box">
                              <p className="option_title">
                                {!item.answer?.title
                                  ? "Title"
                                  : item.answer?.title}
                              </p>
                              <p
                                className="option_description"
                                style={{ textAlign: textAlign }}
                              >
                                {item.answer?.description
                                  ? item.answer?.description
                                  : item.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* ******************************************************************************DEFAULT LAYOUT END***************************** */}
                    </Grid>
                  ))}
                </Grid>
              </div>
              {imgquedata.image_position === "right" &&
                (question_show_iimage.length !== 0 ||
                  imgquedata?.question_add_an_iimage) && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={image_url}
                      width={`${Math.min(300, imgquedata.image_width / 2)}px`}
                      height={`${Math.min(300, imgquedata.image_width / 2)}px`}
                    />
                  </div>
                )}
              {/* {imgquedata.image_position !== "top" && (
                <>
                  <Typography sx={{ mb: 1.5 }} className="right_imgque_heading">
                    {imgquedata.heading}
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    className="right_imgque_subheading"
                  >
                    {imgquedata.subheading}
                  </Typography>
                </>
              )} */}
              {/* {imgquedata.image_position !== "background" && (
                <div
                  className={
                    imgquedata?.image_position === "center" ||
                    imgquedata.image_position === "top"
                      ? "center_position"
                      : imgquedata?.image_position === "right"
                      ? "right_position"
                      : "right_imgmain_image"
                  }
                >
                  {question_show_iimage &&
                  question_add_an_iimage?.length !== 0 ? (
                    imgquedata.image_position == "center" ||
                    imgquedata.image_position === "top" ? (
                      <img
                        src={question_show_iimage}
                        width={`${790 - imgquedata.image_width}px`}
                        height={`${790 - imgquedata.image_width}px`}
                      />
                    ) : (
                      <img
                        src={question_show_iimage}
                        width={`790px`}
                        height={`790px`}
                      />
                    )
                  ) : question_add_an_iimage?.length === 0 &&
                    imgquedata?.question_add_an_iimage != 0 ? (
                    imgquedata.image_position == "center" ||
                    imgquedata.image_position === "top" ? (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/question/${imgquedata?.question_add_an_iimage}`}
                        width={`${790 - imgquedata.image_width}px`}
                        height={`${790 - imgquedata.image_width}px`}
                      />
                    ) : (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/question/${imgquedata?.question_add_an_iimage}`}
                        width={`790px`}
                        height={`790px`}
                      />
                    )
                  ) : question_add_an_iimage.length === 0 &&
                    question_show_iimage.length === 0 ? (
                    ""
                  ) : (
                    ""
                  )}
                </div>
              )}
              {imgquedata.image_position === "top" && (
                <>
                  <Typography sx={{ mb: 1.5 }} className="right_imgque_heading">
                    {imgquedata.heading}
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    className="right_imgque_subheading"
                  >
                    {imgquedata.subheading}
                  </Typography>
                </>
              )} */}
              {/* <div
                className={
                  imgquedata?.box_size === "large" &&
                  imgquedata?.text_alignment === "right"
                    ? " large_right_alignment"
                    : imgquedata?.box_size === "large" &&
                      imgquedata?.text_alignment === "left"
                    ? " large_left_alignment"
                    : imgquedata?.box_size === "large"
                    ? "large gap-2 "
                    : imgquedata?.text_alignment === "left"
                    ? "left_alignment  mt-4"
                    : imgquedata?.text_alignment === "right"
                    ? "right_alignment  mt-4"
                    : "d-flex mt-4 center_small_image_box"
                }
              >
                {imgansdata?.map((item, index) => (
                  <div
                    className={
                      imgquedata?.box_size === "small" &&
                      imgquedata?.text_alignment === "center"
                        ? imgquedata.question_layout_style === "Narrow"
                          ? "small_center_narrow_alignment"
                          : "small_center_alignment"
                        : imgquedata?.box_size === "small" &&
                          imgquedata?.text_alignment === "left" &&
                          imgquedata?.question_layout_style === "wide"
                        ? "small_wide_left_alignment"
                        : imgquedata?.box_size === "small" &&
                          imgquedata?.text_alignment === "right" &&
                          imgquedata?.question_layout_style === "wide"
                        ? "small_wide_right_alignment"
                        : imgquedata?.box_size === "small" &&
                          imgquedata?.text_alignment === "left"
                        ? "small_left_alignment"
                        : imgquedata?.box_size === "small" &&
                          imgquedata?.text_alignment === "right"
                        ? "small_right_alignment"
                        : imgquedata?.box_size === "large" &&
                          imgquedata?.text_alignment === "center"
                        ? "large_center_alignment"
                        : imgquedata?.box_size === "large" &&
                          imgquedata?.text_alignment === "left"
                        ? "large_left_narrow_alignment"
                        : ""
                    }
                  >
                    <Typography
                      variant="body2"
                      className={
                        imgquedata?.text_alignment === "right"
                          ? "right_first_box"
                          : ""
                      }
                    >
                      <div
                        className={
                          imgquedata?.question_layout_style === "Narrow"
                            ? // imgquedata?.box_size === "large"
                              "main-container"
                            : imgquedata?.question_layout_style === "Default" &&
                              imgquedata?.box_size === "large"
                            ? "large_default_image"
                            : imgquedata?.question_layout_style === "Narrow"
                            ? "narrow_image_button"
                            : imgquedata?.question_layout_style === "Default"
                            ? "default_image_button"
                            : "image_box  mt-4"
                        }
                      >
                        {imgquedata.question_layout_style === "Narrow" ? (
                          <>
                            {answer_show_image[index] ? (
                              <div
                                className={`${
                                  imgquedata?.image_size === "large"
                                    ? "circular-large-image-narrow"
                                    : "circular-image-narrow"
                                }`}
                              >
                                <img
                                  className="narrow-img-custom"
                                  src={answer_show_image[index]}
                                />
                              </div>
                            ) : answer_show_image[index] === undefined &&
                              item.answer?.answer_add_image ? (
                              <div
                                className={`${
                                  imgquedata?.image_size === "large"
                                    ? "circular-large-image-narrow"
                                    : "circular-image-narrow"
                                }`}
                              >
                                <img
                                  className="narrow-img-custom"
                                  src={`${process.env.REACT_APP_API_URL}/answer/${item.answer?.answer_add_image}`}
                                />
                              </div>
                            ) : (
                              <div
                                className={`${
                                  imgquedata?.image_size === "large"
                                    ? "circular-large-image-narrow"
                                    : "circular-image-narrow"
                                }`}
                              >
                                <div className="text-center narrow-img-custom">
                                  <img
                                    className={`${
                                      imgquedata?.image_size === "large"
                                        ? "large_size"
                                        : "image_option_box_iconimage"
                                    }`}
                                    src={defaultImage}
                                    alt="defaultImage"
                                  />
                                </div>
                              </div>
                            )}
                            <div className="image-text text-center img_disc">
                              <CFormLabel
                                htmlFor="inputText"
                                className=" col-form-label"
                                style={{ fontSize: "22px" }}
                              >
                                {" "}
                                {!item.answer?.title
                                  ? "Title"
                                  : item.answer?.title}
                              </CFormLabel>
                            </div>
                            <div className="img_disc text-center h-24">
                              {item.answer?.description
                                ? item.answer?.description
                                : item.description}{" "}
                            </div>
                          </>
                        ) : (
                          <>
                            <>
                              {answer_show_image[index] ? (
                                <>
                                  <img
                                    className={`${
                                      imgquedata?.image_size === "large"
                                        ? "large_size"
                                        : "image_option_box_iconimage"
                                    }`}
                                    src={answer_show_image[index]}
                                  />
                                </>
                              ) : answer_show_image[index] === undefined &&
                                item.answer?.answer_add_image ? (
                                <img
                                  className={`${
                                    imgquedata?.image_size === "large"
                                      ? "large_size"
                                      : "image_option_box_iconimage"
                                  }`}
                                  src={`${process.env.REACT_APP_API_URL}/answer/${item.answer?.answer_add_image}`}
                                />
                              ) : (
                                answer_show_image[index] === undefined && (
                                  <div className="text-center">
                                    <img
                                      className={`${
                                        imgquedata?.image_size === "large"
                                          ? "large_size"
                                          : "image_option_box_iconimage"
                                      }`}
                                      src={defaultImage}
                                      alt="defaultImage"
                                      style={{ margin: "auto" }}
                                    />
                                  </div>
                                )
                              )}
                              {answer_show_image[index] == "" && (
                                <div className="text-center">
                                  <img
                                    className={`${
                                      imgquedata.question_layout_style !==
                                        "Narrow" &&
                                      imgquedata?.image_size === "large"
                                        ? "large_size"
                                        : "image_option_box_iconimage"
                                    }`}
                                    src={defaultImage}
                                    alt="defaultImage"
                                    style={{ margin: "auto" }}
                                  />
                                </div>
                              )}
                            </>

                            <div className="text-center img_disc">
                              <CFormLabel
                                htmlFor="inputText"
                                className=" col-form-label"
                                style={{ fontSize: "22px" }}
                              >
                                {" "}
                                {!item.answer?.title
                                  ? "Title"
                                  : item.answer?.title}
                              </CFormLabel>
                            </div>
                            <div className="img_disc text-center h-24">
                              {item.answer?.description
                                ? item.answer?.description
                                : item.description}
                            </div>
                          </>
                        )}
                      </div>
                    </Typography>
                  </div>
                ))}
              </div> */}

              {/* tool tip section *******************************start */}
              <div className="info_icon mt-12">
                {imgquedata?.tooltip_heading ? (
                  <>
                     <Container sx={{ paddingBottom: 2 }}>
                      <Tooltip
                        arrow
                        sx={{
                          backgroundColor: "transparent",
                          ":hover": "none",
                        }}
                        title={
                          <Grid>
                            <h5 style={{ textAlign: "center" }}>
                              {imgquedata?.tooltip_heading}
                            </h5>
                            <p>{imgquedata?.tooltip_description}</p>
                          </Grid>
                        }
                      >
                        <IconButton>
                          <ReportRoundedIcon
                            sx={{ fontSize: "50px !important" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Container>
                  </>
                ) : (
                  ""
                )}
              </div>

              <Grid container className="" justifyContent="center">
                <Grid
                  className="back"
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <WestIcon /> <span className="mx-2"> BACK </span>
                </Grid>
                {imgquedata.skip_que && (
                  <Grid
                    className="back"
                    xs
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <span className="mx-2"> SKIP </span>
                  </Grid>
                )}

                <Grid
                  className="next"
                  xs
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <span className="mx-2 "> NEXT </span> <EastIcon />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default EditRightImageCard;
