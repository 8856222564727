import React, { Component,useState }  from 'react';
import * as Yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';
// @mui

import { useFormik, Form, FormikProvider } from 'formik';
import toast from 'react-hot-toast';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import { CssBaseline, Stack, TextField } from '@mui/material';
import { Box, makeStyles,Button,Container,Avatar } from '@material-ui/core';


// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

export default function ForgoteForm() {
    const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: LoginSchema,
    
    onSubmit: async (formData) => {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/forgot-password`, formData);
      if (response.data.statusCode === 200) {
        setLoading(false);
        toast.success(response.data.massage);
        navigate('/');
      }
      else{
        setLoading(false);
        toast.error(response.data.massage);
        navigate('/');
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;


  return (
<>

        <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              {/* <LockOutlinedIcon /> */}
            </Avatar>
            <Typography component="h1" variant="h5">
              forgot Password
            </Typography>

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              {...getFieldProps('email')}
              id="email"
              label="Email Address"
              name="email"
              className='mt-5'
              autoComplete="email"
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              InputLabelProps={{ shrink: true }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}  
              loading={isSubmitting}
            >
             send reset code
            </Button>
          </div>
          <Box mt={8}></Box>
        </Container>
      </Form>
    </FormikProvider>
    </>
  );
}
