// import { Backdrop, Box, Divider, Grid } from "@material-ui/core";
// import { CircularProgress, Typography } from "@mui/material";
// import React, { useContext, useEffect, useState } from "react";
// import { toast } from "react-hot-toast";
// import { useNavigate, useParams } from "react-router-dom";
// import { AuthContext } from "../../../context/AuthContext";
// import { FetchContext } from "../../../context/FetchContext";
// import Buttton from "../../Button/Buttton";
// import Sidenav from "../../Sidenav";
// import LeftSideCArd from "./LeftSideCArd";
// import RightSideCard from "./RightSideCard";

// const IntroQuizPage = () => {
//   const { authAxios } = useContext(FetchContext);
//   const { state } = useContext(AuthContext);
//   const { id } = useParams();
//   const navigate = useNavigate();

//   const [quiz_add_image, setquiz_add_image] = React.useState([]);
//   const [quiz_show_image, setquiz_show_image] = React.useState([]);
//   const [quiz_desktop_image, setquiz_desktop_image] = React.useState([]);
//   const [quiz_show_desktop_image, setquiz_show_desktop_image] = React.useState(
//     []
//   );
//   const [loading, setloading] = React.useState(false);

//   const [getdata, setdata] = React.useState({
//     heading: "",
//     subheading: "",
//     button_text: "",
//     content_alignment: "center",
//     background_type: "Color",
//     quiz_desktop_image: "",
//     quiz_mobile_image: "",
//     overlay_color: "#ffffff",
//     overlay_opacity: "",
//     disable_intro: false,
//     user_name: state?.loginadmin?.name,
//     user_id: state?.loginadmin?._id,
//   });
//   const [alldata, setalldata] = useState([]);

//   const introquizsave = () => {
//     const formData = new FormData();
//     formData.append("getdata", JSON.stringify(getdata));

//     if (quiz_add_image.length !== 0) {
//       formData.append("quiz_add_image", quiz_add_image);
//     }
//     if (quiz_desktop_image.length !== 0) {
//       formData.append("quiz_desktop_image", quiz_desktop_image);
//     }
//     authAxios
//       .post(`${process.env.REACT_APP_API_URL}/quiz/edit_quiz/${id}`, formData)
//       .then((res) => {
//         setloading(true);
//         setalldata(res.data);
//         if (res.data?.statusCode === 200) {
//           toast.success(res?.data?.msg);
//           if (getdata.disableintro == true) {
//             navigate(`/questiontype/${id}`);
//           } else {
//             navigate(`/quizzes/intropage/${id}`);
//           }
//         }
//       })
//       .catch((err) => {
//         if (err) {
//           console.log(err);
//           setloading(false);
//         }
//       });
//   };
//   useEffect(() => {}, [id]);

//   return (
//     <>
//       <Sidenav>
//         <>
//           {!loading ? (
//             <div>
//               <Backdrop
//                 sx={{
//                   color: "#fff",
//                   zIndex: (theme) => theme.zIndex.drawer + 1,
//                 }}
//                 open={loading}
//               >
//                 <CircularProgress color="inherit" />
//               </Backdrop>
//             </div>
//           ) : (
//             ""
//           )}
//         </>
//         <Typography variant="h4" gutterBottom>
//           Intro Page
//         </Typography>
//         <Box className="d-flex">
//           <Grid container spacing={3}>
//             <LeftSideCArd
//               alldata={alldata}
//               getdata={getdata}
//               setdata={setdata}
//               setquiz_add_image={setquiz_add_image}
//               setquiz_show_image={setquiz_show_image}
//               setquiz_desktop_image={setquiz_desktop_image}
//               setquiz_show_desktop_image={setquiz_show_desktop_image}
//               quiz_desktop_image={quiz_desktop_image}
//             />

//             <RightSideCard
//               alldata={alldata}
//               getdata={getdata}
//               setdata={setdata}
//               quiz_add_image={quiz_add_image}
//               setquiz_add_image={setquiz_add_image}
//               quiz_show_image={quiz_show_image}
//               setquiz_show_image={setquiz_show_image}
//               quiz_desktop_image={quiz_desktop_image}
//               setquiz_desktop_image={setquiz_desktop_image}
//               setquiz_show_desktop_image={setquiz_show_desktop_image}
//               quiz_show_desktop_image={quiz_show_desktop_image}
//             />
//           </Grid>
//         </Box>
//         <Divider style={{ background: "gray" }} className="mt-4 mx-2"></Divider>
//         <Grid className="float-right mx-2 mt-4">
//           <Buttton handleClick={introquizsave} title={"save"} />
//         </Grid>
//       </Sidenav>
//     </>
//   );
// };

// export default IntroQuizPage;
