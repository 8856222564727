import React, { useContext, useEffect, useState } from "react";
import Sidenav from "../Sidenav";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FetchContext } from "../../context/FetchContext";
import { date } from "yup";
import { AuthContext } from "../../context/AuthContext";

const Analytic = () => {
  const { authAxios } = useContext(FetchContext);
  const [submissionData, setSubmissionData] = useState([]);
  const [quizesData, setQuizesData] = useState([]);
  const [quiztype, setQuizType] = useState("");
  const [questionListData, setQuestionListData] = useState([]);
  const [imageallFiles, setImageAllFiles] = useState([]);
  const { state } = useContext(AuthContext);

  const validImageFormats = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];

  const isISODateString = (str) => {
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    return isoDatePattern.test(str);
  };

  const handleQuizesChange = (event) => {
    setQuizType(event.target.value);
    const question_list = quizesData.find(
      (item) => item._id === event.target.value
    );
    setQuestionListData(question_list.questions);
  };

  function getSubmissionData() {
    if (quiztype !== "") {
      authAxios
        .get(
          `${process.env.REACT_APP_API_URL}/submission/get_submission/${quiztype}`
        )
        .then((res) => {
          const { data } = res;
          setSubmissionData(data);

          const imageFiles = data.flatMap((submission) =>
            submission.user_answers.flatMap((answer) =>
              answer.answerId.filter((id) =>
                validImageFormats.includes(id.split(".").pop().toLowerCase())
              )
            )
          );
          setImageAllFiles([...imageFiles]);
        })
        .catch((err) => {
          if (err) {
            console.log(err);
          }
        });
      return () => {
        authAxios;
      };
    }
  }
  function getAllQuizes() {
    const encodedShopId = encodeURIComponent(state.loginadmin.shop_id);
    authAxios
      .get(`${process.env.REACT_APP_API_URL}/quiz/all_quiz/${encodedShopId}`)
      .then((res) => {
        const { data } = res;
        setQuizesData(data?.data);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
    return () => {
      authAxios;
    };
  }
  useEffect(() => {
    getSubmissionData();
    getAllQuizes();
  }, [quiztype]);
  return (
    <>
      <Sidenav>
        <h5 style={{ fontWeight: "bold" }}>Submissions</h5>
        <p>All user data collected from your quiz</p>
        <div>
          <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
            <Select
              value={quiztype}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              onChange={handleQuizesChange}
              variant="outlined"
            >
              <MenuItem value="">
                <em>Select Quiz</em>
              </MenuItem>
              {quizesData.map((item, index) => {
                return <MenuItem value={item._id}>{item.quiz_name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </div>
        <div>
          <Paper sx={{ width: "74%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table
                sx={{ minWidth: 950 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Email/Phone</TableCell>
                    <TableCell style={{ minWidth: 170, textAlign: "center" }}>
                      Customer info
                    </TableCell>
                    {questionListData.map((que, index) => (
                      <>
                        <TableCell sx={{ textAlign: "center", minWidth: 170 }}>
                          {que.heading}
                        </TableCell>
                      </>
                    ))}
                    <TableCell style={{ minWidth: 170, textAlign: "center" }}>
                      Recommendation
                    </TableCell>
                    <TableCell style={{ minWidth: 170, textAlign: "center" }}>
                      Result name
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {submissionData.map((item, index) => {
                    console.log("item=========>", item);
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          style={{ minWidth: 170 }}
                          component="th"
                          scope="row"
                        >
                          {item.email_phone ?? "Not Provided"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>-</TableCell>

                        {questionListData.map((question) => {
                          const answer = item.user_answers.find(
                            (ans) => ans.questionId === question._id
                          );
                          let formattedDate = "";
                          let dateQuestionIndex = "";
                          const dateOption = item.user_answers
                            .map((answer, index) => ({ answer, index }))
                            .filter(
                              ({ answer }) =>
                                typeof answer.answerId === "string" &&
                                isISODateString(answer.answerId)
                            );

                          dateOption.forEach(({ answer, index }) => {
                            dateQuestionIndex = index;
                            let datePart = answer.answerId.split("T")[0];
                            let [year, month, day] = datePart.split("-");
                            formattedDate = `${day}-${month}-${year}`;
                          });
                          if (answer !== undefined) {
                            const answer_id = Array.isArray(answer?.answerId);
                            return (
                              <TableCell sx={{ textAlign: "center" }}>
                                {answer_id && (
                                  <ul>
                                    {answer.answerId.map((option, index) => {
                                      const image_view =
                                        validImageFormats.includes(
                                          option.split(".").pop().toLowerCase()
                                        );
                                      return (
                                        <li
                                          key={option}
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            margin: "10px 0px",
                                          }}
                                        >
                                          {image_view ? (
                                            <img
                                              src={`${process.env.REACT_APP_API_URL}/submission/${option}`}
                                              alt={option}
                                              srcSet=""
                                              style={{
                                                width: "100px",
                                                height: "100px",
                                              }}
                                            />
                                          ) : (
                                            index + 1 + ". " + option + ", "
                                          )}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                )}{" "}
                                {!answer_id && isISODateString(answer.answerId)
                                  ? formattedDate
                                  : answer.answerId ?? "-"}
                                {/* {answer.title} */}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell sx={{ textAlign: "center" }}>
                                -
                              </TableCell>
                            );
                          }
                        })}
                        <TableCell sx={{ textAlign: "center", minWidth: 250 }}>
                          <ul style={{ textAlign: "center" }}>
                            {item.products_data.map((product) => {
                              return <li>-{product.title},</li>;
                            })}
                          </ul>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", minWidth: 170 }}>
                          {item.results.result_name}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </Sidenav>
    </>
  );
};

export default Analytic;
