import { CFormInput, CFormLabel } from "@coreui/react";
import { Input } from "@material-ui/core";
import React from "react";
import "./file.css";

const Inputtypefile = ({ image_text, onChange, name,value,error }) => {
  return (
    <form action="/form/sumbit" method="get">
      <CFormLabel size="lg" class="label">
        <input
          type="file"
          size="lg"
          className="form-control"
          name={name}
          onChange={onChange}
          defaultValue={value}
          accept="image/*"
        />
        <span>{image_text}</span>
      </CFormLabel>
      {error && <div className="mt-2" style={{ color: 'red' }}>*{error}</div>}
    </form>
  );
};

export default Inputtypefile;
