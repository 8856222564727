import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Checkbox, Link, Modal } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import toast from "react-hot-toast";
import { FetchContext } from "../../context/FetchContext";
import { useContext } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Buttton from "../Button/Buttton";
export default function Logiccard({ quizquestion }) {
  console.log("quizquestion", quizquestion.quiz);
  const theme = useTheme();
  const { id } = useParams();
  console.log("id", id);
  const { authAxios } = useContext(FetchContext);
  const [logicJump, setLogicJump] = useState(quizquestion.quiz?.logic_jump);
  console.log("logicJump------------->", logicJump);
  const [logicModalOpen, setLogicModalOpen] = useState(false);
  const handleLogicPopupClose = () => {
    setLogicModalOpen(false);
    setLogicJump(quizquestion.quiz.logic_jump);
  };
  function handleLogicPopupOpen(value) {
    setLogicModalOpen(true);
    setLogicJump(value);
  }
  function handleEnableLogic(value) {
    introquizsave(value);
    setLogicModalOpen(false);
  }
  const introquizsave = (value) => {
    const formData = new FormData();
    console.log("typeee", typeof value);
    formData.append("logic_jump", value);
    console.log("formData", formData);

    authAxios
      .post(`${process.env.REACT_APP_API_URL}/quiz/logic_jump/${id}`, formData)
      .then((res) => {
        if (res.data?.statusCode === 200) {
          toast.success(res?.data?.msg);
        }
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  };

  const handleLogicJumpChange = (event) => {
    const isChecked = event.target.checked;

    handleLogicPopupOpen(isChecked);
  };
  return (
    <>
      <div>
        <Modal
          open={logicModalOpen}
          onClose={handleLogicPopupClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="main_modal_div">
            <div
              id="modal-modal-title"
              className="product_modal_header py-0 px-2"
            >
              <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                Delete
              </Typography>
              <Typography>
                <IconButton
                  sx={{ padding: "0px" }}
                  onClick={handleLogicPopupClose}
                >
                  <CloseIcon />
                </IconButton>
              </Typography>
            </div>
            <Box className="">
              <div className="product_modal_body">
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2, padding: " 10px 20px" }}
                >
                  {logicJump
                    ? "Please note enabling this means you will have to set the 'leads to' question for each answer within the quiz."
                    : "Are you sure want to Disable"}
                </Typography>
                <div
                  style={{
                    padding: "10px 0px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                ></div>
              </div>
            </Box>
            <div
              className="product_modal_footer"
              style={{
                padding: "10px 10px",

                gap: "15px",
              }}
            >
              <Button
                onClick={handleLogicPopupClose}
                className="delete_modal_no"
                variant="contained"
              >
                No
              </Button>
              <Button
                onClick={() => handleEnableLogic(logicJump)}
                className="product_modal_save"
                variant="contained"
              >
                {logicJump ? "Enable" : "Disable"}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography
            component="div"
            variant="h6"
            className="mx-3"
            sx={{ mb: 1.5 }}
          >
            Logic jumps
          </Typography>
          <Typography
            component="div"
            color="text.secondary"
            className="logiccard_detail mx-3 mt-4"
          >
            By default, users view questions based on their order in the quiz.
            If logic jumps is enabled, users can view questions based on what
            answers they choose and "jump" to specific questions.
            <Link>
              {" "}
              Learn more about logic jumps <LaunchIcon />{" "}
            </Link>
          </Typography>
          <div className="d-flex mt-3">
            <Checkbox
              className="mx-1 "
              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
              checked={logicJump} // Step 4: Pass the state
              onChange={handleLogicJumpChange} // Step 4: Pass the handler function
            ></Checkbox>
            <Typography color="text.secondary" className="mt-2 logic_jump">
              Enable logic jumps
            </Typography>
          </div>
          {/* <div>
            <Buttton handleClick={introquizsave} title={"save"} />
          </div> */}
        </CardContent>
      </Box>
    </>
  );
}
