import React from "react";
import { CButton } from "@coreui/react";
// import { ClipLoader } from "react-spinners";
import CircularProgress from '@mui/material/CircularProgress';


const Buttton = ({ title, handleClick, disabled, loading }) => {
  return (
    <div>
      <CButton
        size="lg"
        className="button_component"
        onClick={handleClick}
        autoFocus
        disabled={disabled||loading }
      >
        {/* {title} */}
        {loading ? <CircularProgress size={24} color="inherit" /> : title}
      </CButton>
    </div>
  );
};

export default Buttton;
