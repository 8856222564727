import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Container, Grid } from "@mui/material";
import { CButton, CImage } from "@coreui/react";

const RightSideCard = ({
  data,
  quiz_show_image,
  getdata,
  editaddimage,
  quiz_add_image,
  quiz_desktop_image,
  setquiz_desktop_image,
  quiz_show_desktop_image,
  setquiz_show_desktop_image,
}) => {
  const desktop_src =
    quiz_show_desktop_image && quiz_desktop_image?.length !== 0
      ? quiz_show_desktop_image
      : `${process.env.REACT_APP_API_URL}/quiz/${getdata?.quiz_desktop_image}`;

  return (
    <>
      <Grid item xs={12} sm={12} lg={8} md={12} className="mt-4">
        <Card
          sx={{
            backgroundColor:
              getdata.background_type === "Color" &&
              getdata?.content_alignment === "center"
                ? `${getdata.overlay_color}!important`
                : "",
            backgroundImage:
              getdata.background_type === "Image" &&
              getdata?.content_alignment === "center"
                ? `url(${desktop_src})`
                : "",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="right_quiz_card"
        >
          <CardContent className="p-0">
            {quiz_show_image?.length == 0 ||
            (undefined &&
              quiz_add_image?.quiz_add_image?.length === 0 &&
              getdata?.quiz_add_image == null) ? (
              <Container className="break-all">
                <Typography variant="h5" component="div"></Typography>
                <Grid sx={{ mb: 1.5 }} className="right_introheading">
                  {getdata?.heading}
                </Grid>
                <Grid sx={{ mb: 1.5 }} className="right_introsubheading">
                  {getdata?.subheading}
                </Grid>
                <Grid variant="body2" className="start_quiz">
                  {getdata?.button_text ? (
                    <CButton className="start_button w-52  mt-4">
                      {getdata.button_text}
                    </CButton>
                  ) : null}
                </Grid>
              </Container>
            ) : (
              <>
                {quiz_add_image && getdata?.content_alignment === "center" ? (
                  <>
                    <Container className="break-all">
                      <div>
                        <Typography variant="h5" component="div"></Typography>
                        <Grid sx={{ mb: 1.5 }} className="right_introheading">
                          {getdata?.heading}
                        </Grid>
                        <Grid
                          sx={{ mb: 1.5 }}
                          className="right_introsubheading"
                        >
                          {getdata?.subheading}
                        </Grid>

                        <div
                          className={
                            quiz_show_image &&
                            getdata?.content_alignment === "center"
                              ? "center_position"
                              : "d-flex justify-center"
                          }
                        >
                          {quiz_show_image && quiz_add_image?.length !== 0 ? (
                            <img
                              className={""}
                              src={quiz_show_image}
                              width={
                                !getdata?.image_width ||
                                getdata?.image_width === 0
                                  ? "60px"
                                  : `${Math.min(
                                      300,
                                      getdata?.image_width / 2
                                    )}px`
                              }
                              height={
                                !getdata?.image_width ||
                                getdata?.image_width === 0
                                  ? "60px"
                                  : `${Math.min(
                                      300,
                                      getdata?.image_width / 2
                                    )}px`
                              }
                            />
                          ) : quiz_add_image?.length === 0 &&
                            getdata?.quiz_add_image != 0 ? (
                            getdata?.quiz_add_image && (
                              <img
                                src={`${process.env.REACT_APP_API_URL}/quiz/${getdata?.quiz_add_image}`}
                                width={
                                  !getdata?.image_width ||
                                  getdata?.image_width === 0
                                    ? "60px"
                                    : `${Math.min(
                                        300,
                                        getdata?.image_width / 2
                                      )}px`
                                }
                                height={
                                  !getdata?.image_width ||
                                  getdata?.image_width === 0
                                    ? "60px"
                                    : `${Math.min(
                                        300,
                                        getdata?.image_width / 2
                                      )}px`
                                }
                              />
                            )
                          ) : quiz_add_image?.length === 0 &&
                            quiz_show_image?.length === 0 ? (
                            ""
                          ) : (
                            ""
                          )}
                        </div>

                        <Grid variant="body2" className="start_quiz">
                          {getdata?.button_text ? (
                            <CButton className="start_button w-52  mt-4">
                              {getdata.button_text}
                            </CButton>
                          ) : null}
                        </Grid>
                      </div>
                    </Container>
                  </>
                ) : (
                  <>
                    {getdata?.content_alignment === "left" ? (
                      <Grid container className="">
                        <Grid sm={12} md={6} lg={6} item>
                          {quiz_show_image && quiz_add_image?.length !== 0 ? (
                            <img
                              className={""}
                              src={quiz_show_image}
                              width="600px"
                              style={{ height: "915px" }}
                            />
                          ) : quiz_add_image?.length === 0 &&
                            getdata?.quiz_add_image != 0 ? (
                            getdata?.quiz_add_image && (
                              <img
                                src={`${process.env.REACT_APP_API_URL}/quiz/${getdata?.quiz_add_image}`}
                                width="600px"
                                style={{ height: "910px" }}
                              />
                            )
                          ) : quiz_add_image?.length === 0 &&
                            quiz_show_image?.length === 0 ? (
                            ""
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid
                          className="introRightSide"
                          sm={12}
                          md={6}
                          lg={6}
                          item
                          sx={{
                            backgroundColor:
                              getdata.background_type === "Color"
                                ? `${getdata.overlay_color}!important`
                                : "",
                            backgroundImage:
                              getdata.background_type === "Image"
                                ? `url(${desktop_src})!important`
                                : "",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        >
                          <Container className="break-all">
                            <Typography mb={5} variant="h3" color="initial">
                              {getdata?.heading}
                            </Typography>
                            <Typography variant="h5" color="initial">
                              {getdata?.subheading}
                            </Typography>
                            <Button
                              className="btn-default"
                              sx={{ mt: 5 }}
                              variant="contained"
                            >
                              {getdata?.button_text}
                            </Button>
                          </Container>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container className="">
                        <Grid
                          className="introRightSide"
                          sm={12}
                          md={6}
                          lg={6}
                          item
                          sx={{
                            backgroundColor:
                              getdata.background_type === "Color"
                                ? `${getdata.overlay_color}!important`
                                : "",
                            backgroundImage:
                              getdata.background_type === "Image"
                                ? `url(${desktop_src})!important`
                                : "",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        >
                          <Container className="break-all">
                            <Typography mb={5} variant="h3" color="initial">
                              {getdata?.heading}
                            </Typography>
                            <Typography variant="h5" color="initial">
                              {getdata?.subheading}
                            </Typography>
                            {getdata?.button_text ? (
                              <Button
                                className="btn-default"
                                sx={{ mt: 5 }}
                                variant="contained"
                              >
                                {getdata?.button_text}
                              </Button>
                            ) : (
                              ""
                            )}
                          </Container>
                          <div
                            style={{
                              height: "1024px",
                            }}
                            className="backDiv"
                          ></div>
                        </Grid>
                        <Grid sm={12} md={6} lg={6} item>
                          {quiz_show_image != "" &&
                          quiz_add_image?.length !== 0 ? (
                            <>
                              <img
                                className={"introImg"}
                                src={quiz_show_image}
                                width="600px"
                                style={{ height: "910px" }}
                              />
                            </>
                          ) : quiz_add_image?.length === 0 &&
                            getdata?.quiz_add_image != 0 ? (
                            getdata?.quiz_add_image && (
                              <>
                                <img
                                  src={`${process.env.REACT_APP_API_URL}/quiz/${getdata?.quiz_add_image}`}
                                  width="600px"
                                  style={{ height: "910px" }}
                                />
                              </>
                            )
                          ) : quiz_add_image?.length === 0 &&
                            quiz_show_image?.length === 0 ? (
                            ""
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default RightSideCard;
