import { Backdrop, Box, Button, CircularProgress, Container } from '@material-ui/core';
import { Typography } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { LOGIN } from '../../context/constatnts';
import { styled } from '@mui/material/styles';


const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));



const Authshopify = () => {

  const { dispatch } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);
  const [authshopdata, setauthshopdata] = useState([])

  // useEffect(() => {


  //   axios.get(`${process.env.REACT_APP_API_URL}/shop/authshopify/${id}`)
  //   .then((res) => {
  //       setauthshopdata(res.data);
  //       const { data: { statusCode, massage, token, loginadmin } } = res
  //       if (statusCode === 200) {
  //         dispatch({
  //           type: LOGIN,
  //           payload: {
  //             token,
  //             massage,
  //             statusCode,
  //             loginadmin,

  //           }
  //         });
  //         toast.success(res?.data?.massage);
  //         navigate("/dashboard");
  //       } else {
  //         toast?.error(res?.data?.massage);
  //         setLoading(false);
  //       }

  //     })
  //     .catch((err) => {
  //       if (err) {
  //         console.log(err);
  //       }
  //     });

  // }, [])

  // navigate("/quizzes/allquiz");

  return (
    <div>
      {/* {id ?
        loading && (
          <div>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}

            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )

        :
     ""
      }

      { authshopdata?.statusCode === 404 ? */}
      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Sorry, page not found!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Sorry, we couldn�t find the page you�re looking for. Perhaps you�ve mistyped the URL? Be sure to check your
            spelling.
          </Typography>

          <Box
            component="img"
            src="/assets/illustrations/illustration_404.svg"
            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />

          {/* <Button className='mt-5' to="/" size="large" variant="contained" component={RouterLink}> */}
          {/* Go to Home
             </Button> */}
        </StyledContent>
      </Container>
      {/* : "" */}
      {/* } */}

    </div>
  )
}

export default Authshopify