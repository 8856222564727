import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import {
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { CButton, CCol, CFormInput, CFormLabel, CRow } from "@coreui/react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useNavigate } from "react-router-dom";
import { FetchContext } from "../../context/FetchContext";
import toast from "react-hot-toast";
import { AuthContext } from "../../context/AuthContext";

const Createquiz = () => {
  const [quizname, setquizname] = useState("");
  const [errorValid, setErrorValid] = useState(false);
  const { authAxios } = useContext(FetchContext);
  const { state } = useContext(AuthContext);
  const navigate = useNavigate();

  const handlesubmit = (e) => {
    if (quizname !== "") {
      e.preventDefault();
      authAxios
        .post(`${process.env.REACT_APP_API_URL}/quiz/create_quiz`, {
          quiz_name: quizname,
          shop_id: state.loginadmin.shop_id,
        })
        .then((response) => {
          setquizname(response.data);
          console.log("response------->", response.data);
          navigate(`/edit/quizzes/Intro_quizpage/${response?.data?.data?._id}`);

          // navigate(`/quizzes/Intro_quizpage/${response?.data?.data?._id}`);
        })
        .catch((err) => {
          if (err) {
            console.log(err);
            toast?.error(err);
          }
        });
      handleClose();
      setErrorValid(false);
    } else {
      setErrorValid(true);
    }
  };

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setErrorValid(false);
  };

  return (
    <div>
      <CRow>
        <Grid container spacing={1}>
          <CCol xs={12} xl={11}>
            <CButton
              className="crete_quiz px-3 mr-6 xs:mr-6 h-12"
              onClick={handleClickOpen}
            >
              Create quiz <ArrowDropDownIcon />
            </CButton>
          </CCol>
        </Grid>
      </CRow>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="dialog_box" id="responsive-dialog-title">
          {"Create quiz"}

          <IconButton
            aria-label="close"
            className="closeButton "
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <CFormLabel
            htmlFor="inputPassword"
            className="col-sm-2 mt-2 dialog_label col-form-label"
          >
            Quiz name
          </CFormLabel>
          <CFormInput
            type="text"
            size="lg"
            className={`${
              errorValid && "form-control is-invalid"
            }  mb-6 mt-2 dialog_input`}
            aria-label="lg input example"
            onChange={(e) => setquizname(e.target.value)}
            text={
              errorValid && (
                <div className="text-danger fs-6">
                  This field can't be blank.
                </div>
              )
            }
          />
        </DialogContent>
        <DialogActions className="dialog_button">
          <CButton
            size="lg"
            className="dialog_create mt-3"
            onClick={(e) => {
              handlesubmit(e);
            }}
            autoFocus
          >
            Create
          </CButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Createquiz;
